import { useEffect, useState } from "react";
import { competitionsService } from "../../services/competitions.service";

export interface competition {
    page_number: number;
    page_size: number;
    page_count: number;
    item_count: number;
    data: [competitionData];
}

export interface competitionData {
    id: string;
    moas_id: number;
    moas_code: string;
    name: string;
    teams: [teams];
}

export interface teams {
    id: 0;
    club_id: 0;
    moas_id: 0;
    name: string;
    opta_id: string;
    short_name: string;
}

export interface UseCompetitions {
    competitions: competitionData[];
    loading: boolean;
    hasMore: boolean;
    error: any;
}

const useCompetitions = (pageNumber: number = 1, pageSize = 30, competitionIDs?: string[]): UseCompetitions => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(null);
    const [competitions, setCompetitions] = useState<competitionData[]>([]);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        const getCompetitions = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await competitionsService.getCompetitions(pageNumber, pageSize, competitionIDs);
                if (response) setCompetitions(response.data);
                if (response?.page_count <= pageNumber) setHasMore(false);
            } catch (error: any) {
                setError(error);
            }
            setLoading(false);
        };

        getCompetitions();
    }, [competitionIDs, pageNumber, pageSize]);

    return { competitions, loading, hasMore, error };
};

export default useCompetitions;
