import * as constants from "../constants/global.constants";

const getOffsetDate = (days: number): Date => {
    const date = new Date();
    const offsetDate = date.getDate() + days;
    date.setDate(offsetDate);

    return date;
};

const getDateAtEndOfDay = (date?: Date) => {
    const currentDate = date || new Date();
    currentDate?.setHours(23, 59, 59, 999);

    return currentDate;
};

const getOffsetFromDate = (date: Date, days: number) => {
    return new Date(date.getTime() - days * constants.DAY_IN_MS);
};

const getDateString = (date: Date) => {
    return (
        "Last Updated: " +
        date.getDate() +
        calculateSuffix(date) +
        " " +
        constants.MONTHS[date.getMonth()] +
        " " +
        date.getFullYear() +
        " - " +
        (date.getHours() < 10 ? "0" : "") +
        date.getHours() +
        ":" +
        (date.getMinutes() < 10 ? "0" : "") +
        date.getMinutes() +
        ":" +
        (date.getSeconds() < 10 ? "0" : "") +
        date.getSeconds()
    );
};

const calculateSuffix = (date: Date) => {
    if (date.getDate() <= 20) {
        return constants.DATE_SUFFIX[Math.min(date.getDate() - 1, 3)];
    } else if (date.getDate() > 20) {
        return constants.DATE_SUFFIX[Math.min(parseInt(date.getDate().toString().substr(-1)) - 1, 3)];
    }
};

export { getOffsetDate, getOffsetFromDate, getDateAtEndOfDay, getDateString };
