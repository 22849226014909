import React, { FC, useState, MouseEvent as ReactMouseEvent, FormEvent } from "react";
import Button, { ButtonProps } from "../button";

import styles from "./dropdown.module.scss";

interface DropdownProps extends ButtonProps {
    suggestions: string[];
    onOpen?: (event: FormEvent<EventTarget>) => void;
    onClose?: (event: FormEvent<EventTarget>) => void;
}

const Dropdown: FC<DropdownProps> = ({ suggestions, onClick, onOpen, onClose, ...props }) => {
    const [focused, setFocused] = useState(false);
    const suggestionClasses = `${styles.suggestions} ${focused ? styles.active : ""}`;

    const handleClick = (event: FormEvent<EventTarget>) => {
        event.stopPropagation();

        setFocused(true);
        if (onOpen) onOpen(event);
    };

    const handleSelection = (event: ReactMouseEvent<HTMLLIElement, MouseEvent>): void => {
        if (onClick) onClick(event);
    };

    const handleExit = (event: FormEvent<EventTarget>): void => {
        setFocused(false);
        if (onClose) onClose(event);
    };

    return (
        <div className={styles.dropdown}>
            <Button onClick={handleClick} onBlur={handleExit} {...props}></Button>
            <ul className={suggestionClasses}>
                {suggestions.map((suggestion, index) => (
                    <li key={index} id={suggestion} onMouseDown={handleSelection}>
                        {suggestion}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Dropdown;
