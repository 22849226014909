import { useState } from "react";
import { toast } from "react-toastify";
import { Role } from "../../store/role/types";
import { authService } from "../../services/auth.services";
import * as constants from "../../constants/admin.constants";

export interface DeleteUser {
    message: "Success." | "Error.";
}

export interface UserDetails {
    username: string;
    password: string;
    customer_id?: number;
    role?: Role;
}

export interface UseDeleteUser {
    deleteUser: (userId: string) => void;
    loading: boolean;
    success: boolean;
    error: any;
}

const useDeleteUser = (): UseDeleteUser => {
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const deleteUser = (userId: string) => {
        const DeleteUserUser = async () => {
            setLoading(true);
            setError(false);
            setSuccess(false);

            try {
                await authService.deleteUser(userId);
                setSuccess(true);
                toast.success(constants.USER_SUCCESSFULLY_DELETED);
            } catch (error: any) {
                setError(error);
                toast.error(constants.ERROR_DELETING_USER);
            }
            setLoading(false);
        };

        DeleteUserUser();
    };

    return { deleteUser, success, loading, error };
};

export default useDeleteUser;
