import { useState } from "react";
import { User } from "../../store/register/types";
import { authService } from "../../services/auth.services";
import { Role, SetPermissions } from "../../store/role/types";
import { competitionPermissionsService } from "../../services/competitionPermissions.service";

export interface UserDetails {
    id: number;
    username?: string;
    customer_id?: number;
    role?: Role;
    has_comms_access?: boolean;
    fan_id?: number | null;
}

export interface UseUpdateUser {
    updateUser: (user: UserDetails, permissions?: SetPermissions[], skipUserUpdate?: boolean) => void;
    user: User | undefined;
    loading: boolean;
    errors: Errors | null;
}

export interface Errors {
    status: number;
    statusText: string;
    data: { errors: string[] };
}

const useUpdateUser = (): UseUpdateUser => {
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState<UseUpdateUser["errors"]>(null);
    const [user, setUser] = useState<User>();

    const updateUser = (userDetails: UserDetails, permissions?: SetPermissions[], skipUserUpdate?: boolean) => {
        const updateUser = async () => {
            setLoading(true);
            setErrors(null);

            try {
                await authService.updateUser({ ...userDetails });
                if (permissions?.length) await competitionPermissionsService.setCompetitionPermissions(permissions);
                const response = await authService.getUser(userDetails.id.toString());

                if (response && !skipUserUpdate) setUser(response);

            } catch (error: any) {
                setErrors(error);
            }

            if (!skipUserUpdate) setLoading(false);
        };

        updateUser();
    };

    return { updateUser, user, loading, errors };
};

export default useUpdateUser;
