import { FC, FormEvent } from "react";
import Icon from "../common/icon";
import Title from "../common/title";
import Button from "../common/button";
import * as constants from "../../constants/fixture.constants";

import styles from "./fileUploadSelector.module.scss";

export interface FileUploadSelectorProps {
    onSelection: (event: FormEvent<EventTarget>) => void;
}

const FileUploadSelector: FC<FileUploadSelectorProps> = ({ onSelection }) => {
    return (
        <div className={styles.container}>
            <Title label={constants.UPLOAD_FILE} classes={`subtitle ${styles.title}`} />
            <div className={styles.items}>
                <div className={styles.item}>
                    <div className={styles.heading}>{constants.UPLOAD_FOOTAGE}</div>
                    <div className={styles.icons}>
                        <Icon
                            icon={{ icon: ["fas", "futbol"], type: "FontAwesomeIcon", size: "3x" }}
                            classes={styles.icon}
                        />
                    </div>
                    <Button
                        id={constants.FOOTAGE}
                        name={constants.GET_STARTED}
                        classes={`btn-green ${styles.button}`}
                        onClick={onSelection}
                    />
                </div>

                <div className={styles.item}>
                    <div className={styles.heading}>{constants.UPLOAD_KMI}</div>
                    <div className={styles.icons}>
                        <Icon
                            icon={{ icon: ["fas", "futbol"], type: "FontAwesomeIcon", size: "3x" }}
                            classes={styles.icon}
                        />
                        <Icon
                            icon={{ icon: ["fas", "plus"], type: "FontAwesomeIcon", size: "1x" }}
                            classes={`${styles.icon} ${styles.plusIcon}`}
                        />
                        <Icon
                            icon={{ icon: ["fas", "file"], type: "FontAwesomeIcon", size: "3x" }}
                            classes={styles.icon}
                        />
                    </div>
                    <Button
                        id={constants.KMI}
                        name={constants.GET_STARTED}
                        classes={`btn-green ${styles.button}`}
                        onClick={onSelection}
                    />
                </div>
            </div>
        </div>
    );
};

export default FileUploadSelector;
