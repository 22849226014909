import http from "./http.services";
import { DeleteFile } from "../utils/hooks/useDeleteFile";
import { Trim as TrimType } from "../utils/hooks/useTrim";
import * as constants from "../constants/resources.constants";
import { file as SignedCookieType } from "../utils/hooks/useSignedCookie";
import { SyncPoints as syncPointsType } from "../utils/hooks/useSync";
import { FileMetaData, FileType, FileUploadType } from "../utils/hooks/useFileUpload";

const getFile = async (fileId: string): Promise<FileType> => {
    const { data: file } = await http.get(constants.FILE.replace("{id}", fileId), {
        withCredentials: true
    });
    return file;
};

const getFileSignedURL = async (fileId: string): Promise<SignedCookieType> => {
    const { data: file } = await http.get(constants.SIGNED_COOKIE.replace("{id}", fileId), {
        withCredentials: true
    });
    return file;
};

const getFileDownloadURL = async (fileId: string): Promise<SignedCookieType> => {
    const { data: file } = await http.get(constants.FILE_DOWNLOAD.replace("{id}", fileId), {
        withCredentials: true
    });
    return file;
};

const getFileDownload = async (url: string): Promise<Blob> => {
    const { data: file } = await http.get(url, {
        withCredentials: true,
        responseType: "blob"
    });
    return file;
};

const getPreviewThumbnails = async (fileId: string): Promise<string> => {
    const { data: file } = await http.get(constants.PREVIEW_THUMBNAILS.replace("{id}", fileId), {
        withCredentials: true
    });
    return file;
};

const getLargePreviewThumbnails = async (fileId: string): Promise<string> => {
    const { data: file } = await http.get(constants.LARGE_PREVIEW_THUMBNAILS.replace("{id}", fileId), {
        withCredentials: true
    });
    return file;
};

const generateUploadLink = async (metadata: FileMetaData): Promise<FileUploadType> => {
    const { data: response } = await http.post(
        constants.FILES,
        {
            ...metadata
        },
        {
            withCredentials: true
        }
    );
    return response;
};

const uploadFile = async (url: string, file: File): Promise<object> => {
    const { data: response } = await http.put(url, file, {
        withCredentials: true,
        headers: {
            "Content-Type": "video/mp4"
        }
    });
    return response;
};

const createClip = async (
    fileId: string,
    start: number,
    duration: number,
    name: string,
    audioTrackId?: number
): Promise<TrimType> => {
    const { data: response } = await http.post(
        constants.CREATECLIP.replace("{id}", fileId),
        {
            start,
            duration,
            name,
            audioTrackId
        },
        { withCredentials: true }
    );
    return response;
};

const createDownload = async (fileId: string, name: string, audioTrackId?: number): Promise<TrimType> => {
    const { data: response } = await http.post(
        constants.CREATE_DOWNLOAD.replace("{id}", fileId),
        {
            name,
            audioTrackId
        },
        { withCredentials: true }
    );
    return response;
};

const setSync = async (fileId: string, syncPoints: syncPointsType): Promise<FileType> => {
    const { data: response } = await http.post(
        constants.SYNC.replace("{id}", fileId.toString()),
        { sync_points: { ...syncPoints } },
        { withCredentials: true }
    );
    return response;
};

const deleteFile = async (fileId: string): Promise<DeleteFile> => {
    const { data: response } = await http.delete(constants.FILE.replace("{id}", fileId), {
        withCredentials: true
    });
    return response;
};

const setUploadProgress = async (fileId: string, progress: any): Promise<FileType> => {
    const { data: response } = await http.post(
        constants.SET_UPLOAD_PROGRESS.replace("{id}", fileId.toString()),
        { progress },
        { withCredentials: true }
    );
    return response;
};

export const filesService = {
    getFileDownloadURL,
    getFileSignedURL,
    getFileDownload,
    getPreviewThumbnails,
    getLargePreviewThumbnails,
    generateUploadLink,
    setUploadProgress,
    uploadFile,
    createClip,
    createDownload,
    setSync,
    getFile,
    deleteFile
};
