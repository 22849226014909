import { ReactNode } from "react";

export const SET_ENABLED = "SET_ENABLED";

interface SetActiveAction {
    type: typeof SET_ENABLED;
    enabled: boolean;
}

export type Action = SetActiveAction;
export type Dispatch = (action: Action) => void;
export type State = { enabled: boolean };
export type ScrollProviderProps = { children: ReactNode };
