import { FC } from "react";
import AutoCompleteTemplate, {
    AutoCompleteProps as AutoCompleteTemplateProps,
    Item as ItemTemplate
} from "@sbgsportssoftware/auto-complete";

export type Item = ItemTemplate;
export type AutoCompleteProps = AutoCompleteTemplateProps;

const AutoComplete: FC<AutoCompleteTemplateProps> = ({ ...props }) => {
    return <AutoCompleteTemplate {...props} />;
};

export default AutoComplete;
