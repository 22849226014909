import { useState } from "react";
import { isEmpty } from "lodash";
import * as constants from "../../constants/fixture.constants";
import { toast } from "react-toastify";
import { filesService } from "../../services/files.service";

export interface UseSync {
    setSync: (fileId: string, syncPoints: SyncPoints) => void;
    syncPoints: SyncPoints;
    loading: boolean;
    success: boolean;
    error: any;
}

export interface SyncPoints {
    FIRST_HALF?: number;
    SECOND_HALF?: number;
    FIRST_OVERTIME?: number;
    SECOND_OVERTIME?: number;
    PENALTY_SHOOTOUT?: number;
    [key: string]: number | undefined;
}

const useSync = (): UseSync => {
    const [syncPoints, setSyncPoints] = useState<SyncPoints>({});
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const setSync = async (fileId: string, syncPoints: SyncPoints) => {
        if (!fileId.length || isEmpty(syncPoints) || loading) {
            return;
        }

        setLoading(true);
        setSuccess(false);
        setError(false);

        try {
            const response = await filesService.setSync(fileId.toString(), syncPoints);
            if (response?.metadata.sync_points) setSyncPoints(response.metadata.sync_points);
            toast.success(constants.SYNC_SUCCESS);
            setSuccess(true);
        } catch (error: any) {
            toast.error(constants.SYNC_ERROR);
            setError(error);
        }
        setLoading(false);
    };

    return { syncPoints, success, loading, error, setSync };
};

export default useSync;
