import React, { FC, useEffect, FormEvent, useState, useMemo } from "react";
import { isEqual } from "lodash";
import { history } from "../../utils/history";
import FixturePlayer from "../fixturePlayer";
import FixtureEditor from "../fixtureEditor";
import * as format from "../../utils/format";
import useTrim from "../../utils/hooks/useTrim";
import useSync from "../../utils/hooks/useSync";
import * as types from "../../store/editor/types";
import FixturePageHeader from "../fixturePageHeader";
import * as playerTypes from "../../store/player/types";
import { usePlayer } from "../../store/player/usePlayer";
import { useEditor } from "../../store/editor/useEditor";
import useDeleteFile from "../../utils/hooks/useDeleteFile";
import * as constants from "../../constants/fixture.constants";
import { withRouter, useParams } from "react-router";
import usePreviousProp from "../../utils/hooks/usePreviousProp";
import usePreviewThumbnails from "../../utils/hooks/usePreviewThumbnails";
import { useFixtureMetadata } from "../../store/fixtureMetadata/useFixtureMetadata";
import useFixture, { UseFixture as UseFixtureType } from "../../utils/hooks/useFixture";
import useFixtureFootage, { UseFixtureFootage as UseFixtureFootageType } from "../../utils/hooks/useFixtureFootage";
import useSignedCookie from "../../utils/hooks/useSignedCookie";

import styles from "./fixtureContent.module.scss";
import useCreateDownload from "../../utils/hooks/useCreateDownload";
import {useMixPanel} from "../../logging_config/Mixpanel";

interface FixtureParams {
    fixtureId: string;
    fileId: string;
}

export interface FixtureContentProps {}

const FixtureContent: FC<FixtureContentProps> = () => {
    const [refresh, setRefresh] = useState(true);
    const { fixtureId, fileId } = useParams<FixtureParams>();

    /* Retrieve Fixture and associated footage */
    const { fixture }: UseFixtureType = useFixture(fixtureId, refresh);
    const { footage }: UseFixtureFootageType = useFixtureFootage(fixtureId, refresh);

    /* Retrieve and set the video source */
    const { file: source } = useSignedCookie(fileId);

    /* Retrieve the video preview thumbnails */
    const { vttFiles } = usePreviewThumbnails(fileId);
    const { editor, tool: editorTool, setEnabled, setTool, loadSyncPoints } = useEditor();
    const { audioTrack, setSource, setThumbnails, setStartTime, setSyncPoints } = usePlayer();
    const { setCompetitionId, setTeams, setDate } = useFixtureMetadata();
    const { success: syncPointsUpdated, setSync } = useSync();
    const { setCreateDownload } = useCreateDownload();
    const { deleteFile } = useDeleteFile();
    const { setTrim } = useTrim();

    const homeTeam = fixture ? fixture.home_team.name : "";
    const awayTeam = fixture ? fixture.away_team.name : "";
    const title = format.match(homeTeam, awayTeam);

    const file = footage.find((file) => file.id.toString() === fileId);
    const syncPoints = useMemo(() => file?.metadata.sync_points || {}, [file?.metadata.sync_points]);
    const startTime = syncPoints?.FIRST_HALF || 0;
    const fileStatus = file?.status;
    const mixpanel = useMixPanel()

    const previousSyncPoints = usePreviousProp(syncPoints);

    useEffect(() => {
        setSource(fileId, source);
    }, [fileId, source, setSource]);

    useEffect(() => {
        setThumbnails(vttFiles);
    }, [vttFiles, setThumbnails]);

    useEffect(() => {
        if (syncPointsUpdated) {
            setRefresh(true);
        }
    }, [syncPointsUpdated]);

    useEffect(() => {
        setStartTime(startTime);
    }, [fileId, startTime, setStartTime]);

    useEffect(() => {
        if (refresh) {
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        if (isEqual(previousSyncPoints, syncPoints)) {
            return;
        }

        if (fileStatus !== "AVAILABLE") {
            setSyncPoints([]);
            return;
        }

        const mappedSyncPoints = Object.entries(syncPoints).map((syncPoint) => {
            return { ...constants.MATCH_SYNC_POINTS.find((x) => x.id === syncPoint[0]), time: syncPoint[1] };
        });

        setSyncPoints(mappedSyncPoints as playerTypes.SyncPoints[]);
    }, [fileId, fileStatus, previousSyncPoints, syncPoints, setSyncPoints]);

    useEffect(() => {
        if (!fixture) return;

        setCompetitionId(fixture.competition.id);
        setTeams(fixture.home_team, fixture.away_team);
        setDate(fixture.date);
    }, [fixture, setCompetitionId, setTeams, setDate]);

    /* Once we have a list of footage, set the default video source and set the URL file Id */
    useEffect(() => {
        if (!file && footage && footage.length > 0) {
            history.replace(`/fixtures/${fixtureId}/file/${footage[0].id}`);
        }
    }, [fileId, file, footage, fixtureId]);

    /* Update the video source */
    const handleSourceChange = (fileId: string) => {
        history.push(`/fixtures/${fixtureId}/file/${fileId}`);
    };

    const HandleMenuSelection = (target: string, fileId: string) => {
        if (target === constants.DOWNLOAD) {
            const subType = footage.find((file) => file.id === fileId)?.sub_type;
            const name = constants.VIDEO_TYPES.find((type) => type.id === subType)?.name || fileId;
            setCreateDownload(fileId.toString(), name);
        }

        if (target === constants.DELETE_FILE) {
            deleteFile(fileId);
            setRefresh(true);
        }
    };

    const handleToolChange = (event: FormEvent<EventTarget>) => {
        const { id } = event.currentTarget as Element;
        setTool(id as types.Tool["type"]);

        if (id === constants.TOOLS.SYNC.id) loadSyncPoints(syncPoints);
    };

    const handleEditorSave = () => {
        if (editorTool.type === "TRIM" && editorTool.value) {
            const { startTime, endTime } = editorTool.value;
            const fileName =
                homeTeam && awayTeam
                    ? format.clipFileName(homeTeam, awayTeam)
                    : format.clipFileNameById(fileId.toString());

            setTrim(fileId, startTime, endTime, fileName, audioTrack?.id);
        } else if (editorTool.type === "SYNC" && editorTool.value) {
            setSync(fileId, editorTool.value);
        }
    };

    const handleBack = () => {
        if (editor.enabled) {
            setEnabled(false);
            return;
        }
        history.goBack();
        mixpanel.track("Header - Go Back")
    };

    const renderFixture = () => {
        return (
            <FixturePlayer
                fileId={fileId}
                footage={footage}
                onToolChange={handleToolChange}
                onSourceChange={handleSourceChange}
                onMenuSelection={HandleMenuSelection}
                officials={fixture?.officials || []}
            />
        );
    };

    const renderEditor = () => {
        return <FixtureEditor onSave={handleEditorSave}></FixtureEditor>;
    };

    const renderPlayer = () => {
        if (editor.enabled) {
            return renderEditor();
        } else {
            return renderFixture();
        }
    };

    const fanIds = useMemo(() => {
        if(fixture && fixture?.officials){
            return fixture?.officials?.map((official) => official.fan_id);
        }
        return []
    }, [fixture])

    return (
        <div className={styles.parentContainer}>
            <div className={styles.childContainer}>
            <FixturePageHeader fixtureId={fixtureId} title={title} footage={footage} onClick={handleBack} fanIds={fanIds}/>
            {renderPlayer()}

            </div>
        </div>
    );
};

export default withRouter(FixtureContent);
