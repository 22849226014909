import { useState } from "react";
import Nav from "../../common/nav";
import { useScroll } from "../../../store/scroll/useScroll";
import CompetitionSummaryFilters from "../../dashboardSummaryFilters";
import CompetitionSummary from "../../dashboardSummary";

import styles from "./dashboardCompetitionPage.module.scss";

const CompetitionPage = () => {
    const [filtersOpen, setFiltersOpen] = useState(false);

    const { setEnabled } = useScroll();

    const handleToggleFiltersOpen = () => {
        setFiltersOpen(!filtersOpen);
        setEnabled(filtersOpen);
    };

    return (
        <div className={styles.parentContainer}>
            <div className={styles.childContainer}>
                <Nav classes={styles.nav} />
                <div className={styles.content}>
                    <CompetitionSummaryFilters
                        onToggleFiltersOpen={handleToggleFiltersOpen}
                        open={filtersOpen}
                    ></CompetitionSummaryFilters>
                    <CompetitionSummary onToggleFiltersOpen={handleToggleFiltersOpen} />
                </div>
            </div>
        </div>
    );
};

export default CompetitionPage;
