import React, { FC, MouseEvent as ReactMouseEvent } from "react";
import { Sort } from "../../store/filters/types";
import { setCamelCase } from "../../utils/string";

import styles from "./competitionsHeader.module.scss";

interface Column {
    id: string;
    name: string;
    sort?: Sort;
}

export interface CompetitionsHeaderProps {
    columns: Column[];
    onClick?: (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const CompetitionsHeader: FC<CompetitionsHeaderProps> = ({ columns, onClick }) => {
    return (
        <div className={styles.competitionsHeaderContainer}>
            {columns.map((column) => (
                <div key={column.id} className={styles[setCamelCase(column.id)]}>
                    <div id={column.id} className={styles.container} onClick={onClick}>
                        <div className={styles.label}>{column.name}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CompetitionsHeader;
