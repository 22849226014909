import * as types from "./types";
import editorReducer from "./reducer";
import { useEditorDispatch, useEditorState } from "./context";
import { SyncPoints } from "../../utils/hooks/useSync";
import * as constansts from "../../constants/fixture.constants";

const useEditor = ({ reducer = editorReducer } = {}) => {
    const dispatch = useEditorDispatch();
    const { editor, tool } = useEditorState();

    const setEnabled = (enabled: boolean) => {
        dispatch({ type: types.SET_EDITOR_ENABLED, enabled: enabled });
    };
    const setLoaded = (loaded: boolean) => {
        dispatch({ type: types.SET_EDITOR_LOADED, loaded: loaded });
    };

    const setTool = (tool: types.Tool["type"]) => {
        if (!editor.enabled) setEnabled(true);

        dispatch({ type: types.SET_TOOL, tool: { type: tool } });
    };

    const clearTool = () => {
        if (editor.enabled) setEnabled(false);

        dispatch({ type: types.SET_TOOL, tool: { type: null } });
    };

    const addSyncPoint = (syncPoint: types.SyncSubType, time: number = 0) => {
        const subType = tool.subType ? [...tool.subType, syncPoint] : [syncPoint];

        dispatch({
            type: types.SET_TOOL,
            tool: { type: "SYNC", subType: subType, value: { ...tool.value, [syncPoint.id.toString()]: time } }
        });
    };

    const updateSyncPoint = (syncPoint: string, time: number) => {
        dispatch({
            type: types.UPDATE_VALUE,
            value: { [syncPoint]: time }
        });
    };

    const loadSyncPoints = (syncPoints: SyncPoints) => {
        const syncTypes: { [key: string]: any } = constansts.TOOLS.SYNC_SUBTYPES;
        const SubType = Object.keys(syncPoints).map((syncPoint) => {
            return {
                id: syncPoint as types.SyncPoints,
                name: syncTypes?.[syncPoint]?.name
            };
        });
        dispatch({
            type: types.SET_TOOL,
            tool: {
                type: "SYNC",
                subType: SubType,
                value: { ...tool.value, ...syncPoints }
            }
        });
    };

    const updateTrimRegion = (time: types.TrimValue) => {
        dispatch({ type: types.UPDATE_VALUE, value: time });
    };

    return {
        editor,
        tool,
        setEnabled,
        setLoaded,
        setTool,
        clearTool,
        addSyncPoint,
        updateSyncPoint,
        loadSyncPoints,
        updateTrimRegion
    };
};

export { useEditor };
