import { ReactNode } from "react";
import { Team } from "../../utils/hooks/useFixtures";

export const SET_COMPETITION_ID = "SET_COMPETITION_ID";
export const SET_TEAMS = "SET_TEAMS";
export const SET_DATE = "SET_DATE";
export const CLEAR_METADATA = "CLEAR_METADATA";

interface SetCompetitionIdAction {
    type: typeof SET_COMPETITION_ID;
    competitionId: string;
}

interface SetTeamsAction {
    type: typeof SET_TEAMS;
    homeTeam: Team;
    awayTeam: Team;
}

interface SetDateAction {
    type: typeof SET_DATE;
    date: string;
}

interface ClearMetadata {
    type: typeof CLEAR_METADATA;
}

export type Action = SetCompetitionIdAction | SetTeamsAction | SetDateAction | ClearMetadata;

export type Dispatch = (action: Action) => void;
export type State = { competitionId?: string; homeTeam?: Team; awayTeam?: Team; date?: string };
export type FixtureMetadataProviderProps = { children: ReactNode };
