import http from "./http.services";
import { CUSTOMERS } from "../constants/resources.constants";
import { customer as customerType } from "../utils/hooks/useCustomers";

const getCustomers = async (pageNumber: number = 1, pageSize = 5): Promise<customerType> => {
    const { data: customers } = await http.get(CUSTOMERS, {
        withCredentials: true,
        params: {
            page_number: pageNumber,
            page_size: pageSize
        }
    });
    return customers;
};

export const customersService = {
    getCustomers
};
