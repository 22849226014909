import React, { createContext, useReducer, useContext } from "react";
import { Dispatch, State, registerProviderProps } from "./types";
import registerReducer from "./reducer";

const RegisterStateContext = createContext<State | undefined>(undefined);
const RegisterDispatchContext = createContext<Dispatch | undefined>(undefined);

const RegisterProvider = ({ children }: registerProviderProps) => {
    const [state, dispatch] = useReducer(registerReducer, { user: null });
    return (
        <RegisterStateContext.Provider value={state}>
            <RegisterDispatchContext.Provider value={dispatch}>{children}</RegisterDispatchContext.Provider>
        </RegisterStateContext.Provider>
    );
};

const useRegisterState = () => {
    const context = useContext(RegisterStateContext);
    if (context === undefined) {
        throw new Error("useRegisterState must be used within a RegisterProvider");
    }
    return context;
};

const useRegisterDispatch = () => {
    const context = useContext(RegisterDispatchContext);
    if (context === undefined) {
        throw new Error("useRegisterDispatch must be used within a RegisterProvider");
    }
    return context;
};

export { RegisterProvider, useRegisterState, useRegisterDispatch };
