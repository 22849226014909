import React, { FC, FormEvent } from "react";
import Button from "../button";
import { ReactComponent as Set } from "../../../assets/images/player/set.svg";
import { SYNC_TOOLS as constants } from "../../../constants/fixture.constants";
import { SyncPoints } from "../../../store/editor/types";

import styles from "./syncButton.module.scss";

export interface SyncButtonProps {
    id: SyncPoints;
    name: string;
    onClick: (id: SyncPoints, name: string, option: string) => void;
}

const SyncButton: FC<SyncButtonProps> = ({ id, name, onClick }) => {
    const handleClick = (event: FormEvent<any>) => {
        const target = event.currentTarget as HTMLSelectElement;
        if (onClick) onClick(id, name, target.id);
    };

    return (
        <div className={`${styles.syncButton}`}>
            <Button
                id={constants.GO_TO_SYNC_POINT}
                tooltip={constants.GO_TO_SYNC_POINT}
                name={name}
                classes={`btn btn-outline-green ${styles.button}`}
                onClick={handleClick}
                rippleColor={"black"}
            ></Button>
            <Button
                id={constants.MOVE_SYNC_POINT}
                tooltip={constants.MOVE_SYNC_POINT}
                icon={{ icon: { icon: <Set />, type: "Svg" } }}
                classes={`btn btn-outline-green ${styles.button}`}
                onClick={handleClick}
                rippleColor={"black"}
            ></Button>
        </div>
    );
};

export default SyncButton;
