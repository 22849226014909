import React, { createContext, useReducer, useContext } from "react";
import { Dispatch, State, PlayerProviderProps } from "./types";
import playerReducer from "./reducer";

const PlayerStateContext = createContext<State | undefined>(undefined);
const PlayerDispatchContext = createContext<Dispatch | undefined>(undefined);

const PlayerProvider = ({ children }: PlayerProviderProps) => {
    const [state, dispatch] = useReducer(playerReducer, {
        ready: false,
        canPlay: false,
        source: { id: "", src: "" }
    });
    return (
        <PlayerStateContext.Provider value={state}>
            <PlayerDispatchContext.Provider value={dispatch}>{children}</PlayerDispatchContext.Provider>
        </PlayerStateContext.Provider>
    );
};

const usePlayerState = () => {
    const context = useContext(PlayerStateContext);
    if (context === undefined) {
        throw new Error("usePlayerState must be used within a PlayerProvider");
    }
    return context;
};

const usePlayerDispatch = () => {
    const context = useContext(PlayerDispatchContext);
    if (context === undefined) {
        throw new Error("usePlayerDispatch must be used within a PlayerProvider");
    }
    return context;
};

export { PlayerProvider, usePlayerState, usePlayerDispatch };
