import React from "react";
import AddUserPage from "../../pages/addUserPage";
import LoginPage from "../../pages/loginPage";
import LogoutPage from "../../pages/logoutPage";
import ProtectedRoute from "../protectedRoute";
import AdminPage from "../../pages/adminPage";
import { history } from "../../../utils/history";
import FixturePage from "../../pages/fixturePage";
import * as access from "../../../store/role/types";
import FixturesPage from "../../pages/fixturesPage";
import EditUserPage from "../../pages/editUserPage";
import { Router, Switch, Route } from "react-router-dom";
import SetPasswordPage from "../../pages/setPasswordPage";
import ForgotPasswordPage from "../../pages/forgotPasswordPage";
import CheckEmailPage from "../../pages/checkEmailPage";
import DashboardPage from "../../pages/dashboardPage";
import DashboardCompetitionPage from "../../pages/dashboardCompetitionPage";

const createRoutes = () => {
    return (
        <Router history={history}>
            <Switch>
                <Route exact path={"/login"} component={LoginPage} />
                <Route exact path={"/log-out"} component={LogoutPage}></Route>
                <Route exact path={["/set-password", "/set-password/:token"]} component={SetPasswordPage} />
                <Route exact path={"/forgot-password"} component={ForgotPasswordPage} />
                <Route exact path={"/check-email"} component={CheckEmailPage} />
                <ProtectedRoute
                    exact={true}
                    path={["/fixtures/:fixtureId", "/fixtures/:fixtureId/file/:fileId"]}
                    component={FixturePage}
                />
                <ProtectedRoute exact={true} path={"/fixtures"} component={FixturesPage} />
                <ProtectedRoute exact={true} path={"/"} redirect={"/fixtures"} component={FixturesPage} />
                <ProtectedRoute exact={true} path={"/admin"} roles={access.admin} component={AdminPage} />
                <ProtectedRoute exact={true} path={"/admin/add-user"} component={AddUserPage} />
                <ProtectedRoute exact={true} path={"/admin/edit-user/:userId"} component={EditUserPage} />
                <ProtectedRoute exact={true} path={"/dashboard"} roles={access.admin} component={DashboardPage} />
                <ProtectedRoute
                    exact={true}
                    path={"/dashboard/:competitionId"}
                    roles={access.admin}
                    component={DashboardCompetitionPage}
                />
            </Switch>
        </Router>
    );
};

export default createRoutes;
