import React, { FC, useState, useRef } from "react";
import User from "../user";
import Img from "../common/img";
import Title from "../common/title";
import Button from "../common/button";
import { history } from "../../utils/history";
import * as constants from "../../constants/admin.constants";
import spinner from "../../assets/images/common/spinner.svg";
import useInfiniteList from "../../utils/hooks/useInfiniteList";
import useUsers, { usersData } from "../../utils/hooks/useUsers";

import styles from "./usersList.module.scss";

export interface UsersListProps {}

const UsersList: FC<UsersListProps> = () => {
    /* User List */
    const pageSize = 20;
    const [pageNumber, setPageNumber] = useState(1);
    const containerRef = useRef<HTMLDivElement>(null);

    /* Deleted User list (used to trigger fetching of updated user list) */
    const [deletedUsers, setDeletedUsers] = useState<string[]>([]);

    const loadNextPage = () => {
        setPageNumber(pageNumber + 1);
    };

    const { users, loading, hasMore, error } = useUsers(pageNumber, pageSize, deletedUsers);

    const { intersectionObserver, topPadding, bottomPadding, firstIndex, currentIndex } = useInfiniteList(
        users,
        pageSize,
        loading,
        hasMore,
        error,
        3.75,
        loadNextPage
    );

    const handleAddUser = () => {
        history.push(`admin/add-user`);
    };

    const handleDeleteUser = (userId: string) => {
        setDeletedUsers([...deletedUsers, userId]);
        setPageNumber(1);
        containerRef.current?.scrollIntoView();
    };

    return (
        <div className={styles.usersListContainer}>
            <div className={styles.usersListHeader}>
                <Title
                    label={constants.ADMIN_TITLE}
                    classes={`page-title ${styles.pageTitle}`}
                    ref={containerRef}
                ></Title>
                <div className={styles.actions}>
                    <Button name={"Add user"} classes={"btn-outline-green"} onClick={handleAddUser}></Button>
                </div>
            </div>

            <div
                className={styles.usersListChildContainer}
                ref={intersectionObserver}
                style={{
                    paddingTop: `${topPadding}rem`,
                    paddingBottom: `${bottomPadding}rem`
                }}
            >
                {users.slice(firstIndex, currentIndex).map((user: usersData) => (
                    <User
                        key={user.id}
                        id={user.id}
                        username={user.username}
                        role={user.role}
                        permissions={user.permissions}
                        onUserDelete={handleDeleteUser}
                    ></User>
                ))}
            </div>
            {hasMore && !error && (
                <div className={styles.spinner}>
                    <Img src={spinner} alt={"Loading Spinner"} classes="logo"></Img>
                </div>
            )}
        </div>
    );
};

export default UsersList;
