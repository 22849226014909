import React, { memo, Fragment } from "react";
import Title from "../common/title";
import FixtureFile from "../fixtureFile";
import { usePlayer } from "../../store/player/usePlayer";
import * as constants from "../../constants/fixture.constants";
import { FixtureFiles as FixtureFilesType } from "../../utils/hooks/useFixtureFootage";
import { IncidentFiles as IncidentFilesType } from "../../utils/hooks/useFixtureIncidents";

import styles from "./fixtureFiles.module.scss";
import { useProgressQueue } from "../../store/progressQueue/useProgressQueue";

export interface FixtureFilesProps {
    files: FixtureFilesType[] | IncidentFilesType[];
    onClick: (id: string) => void;
    onClickOption: (target: string, id: string) => void;
}

const FixtureFiles = memo<FixtureFilesProps>(({ files = [], onClick, onClickOption }) => {
    const { source } = usePlayer();
    const { items } = useProgressQueue();

    const setFileLabel = (file: FixtureFilesType | IncidentFilesType) => {
        if (file.sub_type === "VIDEO_CLIP" && "incident" in file.metadata) {
            return file.metadata.incident || "";
        }

        return constants.VIDEO_TYPES.find((type) => type.id === file.sub_type)?.name || "";
    };

    const setFileProgress = (file: FixtureFilesType | IncidentFilesType) => {
        const item = items.find((item) => item.id === file.id);

        if (item) {
            return item.stats?.progress;
        }

        if (file.status === "UPLOADING") {
            return file.metadata.upload_progress;
        }

        if (file.status === "TRANSCODING") {
            return file.metadata.transcode_progress;
        }
    };

    return (
        <div className={styles.fixtureFiles}>
            <Title label={"Files"} classes={`subtitle ${styles.title}`}></Title>
            {files.length > 0 && (
                <Fragment>
                    {files.map((file) => {
                        return (
                            <FixtureFile
                                key={file.id}
                                id={file.id}
                                label={setFileLabel(file)}
                                state={file.status}
                                uploadedToHudl={file.uploaded_to_hudl}
                                metadata={{
                                    progress: setFileProgress(file)
                                }}
                                selected={file.id.toString() === source.id}
                                onClick={onClick}
                                onClickOption={onClickOption}
                            />
                        );
                    })}
                </Fragment>
            )}
        </div>
    );
});

export default FixtureFiles;
