import React, { FC } from "react";
import { startCase } from "lodash";
import { Angle, State } from "../../utils/hooks/useDashboardFixture";

import styles from "./fixtureAngle.module.scss";

export interface FixtureAngleProps {
    angle: Angle;
    state: State;
    classes?: string;
}

export const DisplayAngle = new Map<string, string>([
    ["VIDEO_BROADCAST",         "Broadcast"],
    ["VIDEO_TACTICAL",          "Tactical"],
    ["VIDEO_PANORAMIC",         "Panoramic"],
    ["VIDEO_EIGHTEEN_YARD_ONE", "18YD1"],
    ["VIDEO_EIGHTEEN_YARD_TWO", "18YD2"],
    ["VIDEO_HIGH_BEHIND_ONE",   "HB1"],
    ["VIDEO_HIGH_BEHIND_TWO",   "HB2"],
    ["AUDIO_COMMS",             "Audio Comms"],
    ["VIDEO_BROADCAST_COMMS",   "Broad Comms"],
]);


const FixtureAngle: FC<FixtureAngleProps> = ({ angle, state, classes }) => {
    const angleName =  DisplayAngle.get(angle.toString())
    const angleState = state.toLowerCase();
    const fixtureStateClasses = `${styles.fixtureState} ${classes || ""}`;
    const angleStateClasses = `${styles.icon} ${styles[angleState]}`;

    return (
        <div className={fixtureStateClasses} title={angleName}>
            <div className={angleStateClasses}></div>
            <div className={styles.label}>{startCase(angleName)}</div>
        </div>
    );
};

export default FixtureAngle;
