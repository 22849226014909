import React, { FC } from "react";

import styles from "./stepper.module.scss";

export interface StepperProps {
    currentStep: number;
    steps: string[];
    classes: string;
}

const Stepper: FC<StepperProps> = ({ currentStep, steps, classes }) => {
    return (
        <div className={`${styles.stepper} ${classes ? classes : ""}`}>
            {steps.map((step, index) => {
                return <Step key={index} step={step} active={index === currentStep}></Step>;
            })}
        </div>
    );
};

export interface StepProps {
    active: boolean;
    step: string;
}

const Step: FC<StepProps> = ({ step, active }) => {
    return (
        <div className={styles.step}>
            <div className={styles.label}>{step}</div>
            <div className={`${styles.icon} ${active ? styles.active : ""}`}></div>
        </div>
    );
};

export default Stepper;
