import * as types from "./types";

const registerReducer = (state: types.State, action: types.Action) => {
    switch (action.type) {
        case types.UPDATE_USER: {
            return { user: action.user };
        }
        default: {
            throw new Error(`Unhandled action type`);
        }
    }
};

export default registerReducer;
