import React, { FC, useEffect, useState, FormEvent } from "react";
import Input from "../common/input";
import Title from "../common/title";
import { omit, isEmpty } from "lodash";
import AddUserNavigation from "../addUserNavigation";
import useRegister from "../../utils/hooks/useRegister";
import { validateInput } from "../../utils/validateInput";
import * as constants from "../../constants/admin.constants";
import { useRegisterDispatch } from "../../store/register/context";

import styles from "./addUserCredentials.module.scss";

interface Credentials {
    email: string;
    [key: string]: string | number;
}

export interface AddUserCredentialsProps {
    onNextPage: () => void;
}

const AddUserCredentials: FC<AddUserCredentialsProps> = ({ onNextPage }) => {
    const [credentials, setCredentials] = useState<Credentials>({ email: "" });
    const [credentialErrors, setCredentialErrors] = useState({});

    const { register, user, error } = useRegister();
    const dispatch = useRegisterDispatch();

    useEffect(() => {
        if (user) {
            dispatch({ type: "UPDATE_USER", user });
            onNextPage();
        }
    }, [dispatch, onNextPage, user]);

    const setData = (name: string, value: string): void => {
        setCredentials({
            ...credentials,
            [name]: value
        });
    };

    const getError = (prop: string): string => {
        const errors: { [index: string]: any } = credentialErrors;
        if (errors[prop]) {
            return errors[prop]["message"];
        }
        if (prop === constants.EMAIL && error) {
            return error?.data?.errors?.[0];
        }
        return "";
    };

    const setError = (prop: string, error: { [index: string]: any }): void => {
        if (error) {
            setCredentialErrors({ ...credentialErrors, [prop]: error[0] });
        } else {
            setCredentialErrors(omit(credentialErrors, [prop]));
        }
    };

    const handleBlur = ({ currentTarget: input }: { currentTarget: any }): void => {
        const { id: name, value } = input;
        const error = validateInput(constants.schema["properties"][name], value);
        setError(name, error);
    };

    const handleChange = ({ currentTarget: input }: { currentTarget: any }) => {
        const { id, value } = input;
        setData(id, value);
    };

    const validateSubmit = (): boolean => {
        /* If there are login errors */
        if (!isEmpty(credentialErrors)) return true;

        /* Or the form is empty */
        const formCredentials = !Object.values(credentials).some((x) => x === 0 || x === "");
        return !formCredentials;
    };

    const handleSubmit = (event: FormEvent<EventTarget>) => {
        event.preventDefault();
        register(credentials.email);
    };

    return (
        <form className={styles.form}>
            <div className={styles.formGroup}>
                <Title label={constants.LOGIN_CREDENTIALS} classes={`heading ${styles.heading}`}></Title>
                <div className={styles.formFields}>
                    <Input
                        id={constants.EMAIL}
                        label={constants.EMAIL}
                        value={credentials.email}
                        classes={styles.inputContainer}
                        autoComplete="on"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={getError(constants.EMAIL)}
                    />
                </div>
            </div>
            <AddUserNavigation disabled={validateSubmit()} onClick={handleSubmit}></AddUserNavigation>
        </form>
    );
};

export default AddUserCredentials;
