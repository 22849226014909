import React, { FC, Fragment, FormEvent } from "react";
import Button from "../button";
import SyncButton from "../syncButton";
import ActionButton from "../actionButton";
import * as string from "../../../utils/string";
import { Tool } from "../../../store/editor/types";
import { SyncPoints, SyncSubType } from "../../../store/editor/types";
import * as constants from "../../../constants/fixture.constants";

import styles from "./editorSyncControls.module.scss";

export interface EditorSyncControlsProps {
    tool: Tool;
    onClick: (id: SyncPoints, name: string, option: string) => void;
    onCancel: (event: FormEvent<EventTarget>) => void;
    onSave: (event: FormEvent<EventTarget>) => void;
}

const EditorSyncControls: FC<EditorSyncControlsProps> = ({ tool, onClick, onCancel, onSave }) => {
    const ButtonBuilder = ({ item: { id, name } }: { item: SyncSubType }) => {
        return (
            <Button
                id={id}
                name={string.removeUnderscores(name)}
                tooltip={string.removeUnderscores(name)}
                onClick={() => onClick(id, name, constants.SYNC_TOOLS.ADD_SYNC_POINT)}
                classes={"btn-outline-green btn-bg-white"}
                disabled={!!(tool.subType && tool.subType.find((tool) => tool.id === id))}
            ></Button>
        );
    };

    const SyncButtonBuilder = ({ item: { id, name } }: { item: SyncSubType }) => {
        return <SyncButton id={id} name={name} onClick={onClick}></SyncButton>;
    };

    return (
        <Fragment>
            <ActionButton
                id={constants.SYNC_TOOLS.ADD_SYNC_POINT}
                name={constants.SYNC_TOOLS.ADD_SYNC_POINT}
                tooltip={constants.SYNC_TOOLS.ADD_SYNC_POINT}
                classes={`btn-outline-green`}
            >
                <ButtonBuilder item={constants.TOOLS.SYNC_SUBTYPES.FIRST_HALF as SyncSubType}></ButtonBuilder>
                <ButtonBuilder item={constants.TOOLS.SYNC_SUBTYPES.SECOND_HALF as SyncSubType}></ButtonBuilder>
                <ButtonBuilder item={constants.TOOLS.SYNC_SUBTYPES.FIRST_OVERTIME as SyncSubType}></ButtonBuilder>
                <ButtonBuilder item={constants.TOOLS.SYNC_SUBTYPES.SECOND_OVERTIME as SyncSubType}></ButtonBuilder>
                <ButtonBuilder item={constants.TOOLS.SYNC_SUBTYPES.PENALTY_SHOOTOUT as SyncSubType}></ButtonBuilder>
            </ActionButton>

            <div className={styles.syncPoints}>
                <div className={styles.controls}>
                    {tool.subType?.map((syncPoint, index) => {
                        return <SyncButtonBuilder key={index} item={syncPoint}></SyncButtonBuilder>;
                    })}
                </div>
            </div>
            <div className={styles.editorSave}>
                <Button
                    name={constants.CANCEL}
                    tooltip={constants.CANCEL}
                    classes={"btn-green"}
                    onClick={onCancel}
                ></Button>
                <Button name={constants.SAVE} tooltip={constants.SAVE} classes={"btn-green"} onClick={onSave}></Button>
            </div>
        </Fragment>
    );
};

export default EditorSyncControls;
