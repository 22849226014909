import { createContext, useReducer, useContext } from "react";
import { Dispatch, State, ScrollProviderProps } from "./types";
import scrollReducer from "./reducer";

const ScrollStateContext = createContext<State | undefined>(undefined);
const ScrollDispatchContext = createContext<Dispatch | undefined>(undefined);

const ScrollProvider = ({ children }: ScrollProviderProps) => {
    const [state, dispatch] = useReducer(scrollReducer, { enabled: true });
    return (
        <ScrollStateContext.Provider value={state}>
            <ScrollDispatchContext.Provider value={dispatch}>{children}</ScrollDispatchContext.Provider>
        </ScrollStateContext.Provider>
    );
};

const useScrollState = () => {
    const context = useContext(ScrollStateContext);
    if (context === undefined) {
        throw new Error("useScrollState must be used within a ScrollProvider");
    }
    return context;
};

const useScrollDispatch = () => {
    const context = useContext(ScrollDispatchContext);
    if (context === undefined) {
        throw new Error("useScrollDispatch must be used within a ScrollProvider");
    }
    return context;
};

export { ScrollProvider, useScrollState, useScrollDispatch };
