import http from "./http.services";
import { COMPETITIONS } from "../constants/resources.constants";
import { competition as competitionsType } from "../utils/hooks/useCompetitions";
import { Competition as competitionType } from "../utils/hooks/useCompetition";
import * as constants from "../constants/resources.constants";

const getCompetitions = async (pageNumber: number = 1, pageSize = 5, competitionIDs?: string[]): Promise<competitionsType> => {
    const { data: competitions } = await http.get(COMPETITIONS, {
        withCredentials: true,
        params: {
            page_number: pageNumber,
            page_size: pageSize,
            ...(!!competitionIDs && competitionIDs.length > 0 && { competition_ids: competitionIDs })
        }
    });
    return competitions;
};

const getCompetition = async (competitionId: string): Promise<competitionType> => {
    const { data: competition } = await http.get(constants.COMPETITION.replace("{id}", competitionId), {
        withCredentials: true
    });
    return competition;
};

export const competitionsService = {
    getCompetition,
    getCompetitions
};
