import * as types from "./types";

const playerReducer = (state: types.State, action: types.Action) => {
    switch (action.type) {
        case "SET_PLAYER_READY": {
            return { ...state, ready: action.ready };
        }
        case "SET_PLAYER_CAN_PLAY": {
            return { ...state, canPlay: action.canPlay };
        }
        case "SET_PLAYER_SOURCE": {
            return { ...state, source: action.source };
        }
        case "SET_PLAYER_AUDIO_TRACK": {
            return { ...state, audioTrack: action.audioTrack };
        }
        case "SET_PLAYER_START_TIME": {
            return { ...state, startTime: action.startTime };
        }
        case "SET_PLAYER_THUMBNAILS": {
            return { ...state, thumbnails: action.thumbnails };
        }
        case "SET_PLAYER_SYNC_POINTS": {
            return { ...state, syncPoints: action.syncPoints };
        }
    }
};

export default playerReducer;
