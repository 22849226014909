import { useState } from "react";
import { history } from "../history";
import { toast } from "react-toastify";
import { authService } from "../../services/auth.services";
import * as constants from "../../constants/login.constants";

export interface ForgotPassword {
    message: "Success." | "Error.";
}

export interface ForgotPasswordDetails {
    username: string;
}

export interface UseForgotPassword {
    forgotPassword: (email: string) => void;
    loading: boolean;
    success: boolean;
    error: any;
}

const useForgotPassword = (): UseForgotPassword => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const forgotPassword = async (email: string) => {
        if (!email || loading) {
            return;
        }

        setLoading(true);
        setSuccess(false);
        setError(false);

        try {
            await authService.forgotPassword({ username: email });
            setSuccess(true);
            toast.success(constants.FORGOT_PASSWORD_SUCCESS);
            history.push("/check-email");
        } catch (error: any) {
            setError(error);
        }
        setLoading(false);
    };

    return { success, loading, error, forgotPassword };
};

export default useForgotPassword;
