import React, { FC } from "react";
import * as constants from "../../../constants/global.constants";
import { Props as ReactModalProps } from "react-modal";
import Icon, { IconProps } from "../icon";
import Button from "../button";
import Title from "../title";
import Modal from "../modal";

import styles from "./confirmationModal.module.scss";

export interface ConfirmationModalProps extends ReactModalProps {
    isOpen: boolean;
    title: string;
    icon?: IconProps["icon"];
    subtitle?: string;
    onCancel: () => void;
    onConfirm: () => void;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
    isOpen,
    title,
    icon,
    subtitle,
    onCancel,
    onConfirm,
    ...rest
}) => {
    return (
        <Modal isOpen={isOpen} className={styles.modal} {...rest}>
            <div className={styles.content}>
                {icon && <Icon icon={icon} classes={styles.icon} />}
                <Title label={title} classes={`subtitle ${styles.title}`}></Title>
                {subtitle && <Title label={subtitle} classes={`subtitle ${styles.subtitle}`}></Title>}
            </div>
            <div className={styles.confirmParentContainer}>
                <div className={styles.confirmChildContainer}>
                    <Button
                        id={constants.CANCEL}
                        name={constants.CANCEL}
                        classes={`btn-outline-green m-0 ${styles.cancelButton}`}
                        onClick={onCancel}
                    ></Button>
                    <Button
                        id={constants.CONFIRM}
                        name={constants.CONFIRM}
                        classes={"btn-green m-0"}
                        onClick={onConfirm}
                    ></Button>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
