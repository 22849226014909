import { useState } from "react";
import { Role } from "../../store/role/types";
import { User } from "../../store/register/types";
import { authService } from "../../services/auth.services";

export interface UserDetails {
    username: string;
    password?: string;
    customer_id?: number;
    role?: Role;
}

export interface UseRegister {
    register: (email: string, password?: string, customerId?: number, role?: Role) => void;
    user: User | undefined;
    loading: boolean;
    error: any;
}

const useRegister = (): UseRegister => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [user, setuser] = useState<User>();

    const register = (email: string, password?: string, customerId?: number, role?: Role) => {
        const registerUser = async () => {
            setLoading(true);
            setError(false);

            try {
                const response = await authService.register({
                    username: email,
                    password,
                    customer_id: customerId,
                    role,
                });
                if (response) setuser(response);
            } catch (error: any) {
                setError(error);
            }
            setLoading(false);
        };

        registerUser();
    };

    return { register, user, loading, error };
};

export default useRegister;
