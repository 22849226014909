import React, { createContext, useReducer, useContext } from "react";
import { Dispatch, State, FixtureMetadataProviderProps } from "./types";
import FixtureMetadataReducer from "./reducer";

const FixtureMetadataStateContext = createContext<State | undefined>(undefined);
const FixtureMetadataDispatchContext = createContext<Dispatch | undefined>(undefined);

const FixtureMetadataProvider = ({ children }: FixtureMetadataProviderProps) => {
    const [state, dispatch] = useReducer(FixtureMetadataReducer, {});
    return (
        <FixtureMetadataStateContext.Provider value={state}>
            <FixtureMetadataDispatchContext.Provider value={dispatch}>
                {children}
            </FixtureMetadataDispatchContext.Provider>
        </FixtureMetadataStateContext.Provider>
    );
};

const useFixtureMetadataState = () => {
    const context = useContext(FixtureMetadataStateContext);
    if (context === undefined) {
        throw new Error("useFixtureMetadataState must be used within a FixtureMetadataProvider");
    }
    return context;
};

const useFixtureMetadataDispatch = () => {
    const context = useContext(FixtureMetadataDispatchContext);
    if (context === undefined) {
        throw new Error("useFixtureMetadataDispatch must be used within a FixtureMetadataProvider");
    }
    return context;
};

export { FixtureMetadataProvider, useFixtureMetadataState, useFixtureMetadataDispatch };
