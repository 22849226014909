import React, { FC, useState, useEffect, FormEvent } from "react";
import Title from "../common/title";
import { Item } from "../common/autoComplete";
import AutoComplete from "../common/autoComplete";
import AddUserNavigation from "../addUserNavigation";
import useUpdateUser from "../../utils/hooks/useUpdateUser";
import * as constants from "../../constants/admin.constants";
import useCustomers from "../../utils/hooks/useCustomers";
import { useRegisterState, useRegisterDispatch } from "../../store/register/context";

import styles from "./addUserCustomer.module.scss";

export interface AddUserCustomerProps {
    onNextPage: () => void;
}

const AddUserCustomer: FC<AddUserCustomerProps> = ({ onNextPage }) => {
    const [customerId, setCustomerId] = useState<number>();

    /* Retrieve User Id */
    const { user: currentUser } = useRegisterState();
    const userId = currentUser?.id;

    /* Fetch customer list */
    const { customers } = useCustomers();

    /* Update user */
    const { user, errors, updateUser } = useUpdateUser();
    const dispatch = useRegisterDispatch();

    useEffect(() => {
        if (user && !errors) {
            dispatch({ type: "UPDATE_USER", user });
            onNextPage();
        }
    }, [dispatch, onNextPage, user, errors]);

    const setError = (): string => {
        if (errors?.data) {
            return errors?.data?.errors[0];
        }
        return "";
    };

    const handleSelect = (selectedItem?: Item | null) => {
        setCustomerId(selectedItem ? Number(selectedItem.id) : undefined);
    };

    const handleSubmit = (event: FormEvent<EventTarget>) => {
        event.preventDefault();
        if (userId && customerId) updateUser({ id: userId, customer_id: customerId });
    };

    return (
        <form className={styles.form}>
            <div className={styles.formGroup}>
                <Title label={constants.CUSTOMER} classes={`heading ${styles.heading}`}></Title>
                <div className={styles.formFields}>
                    <AutoComplete
                        id={constants.CUSTOMER_ID}
                        label={constants.CUSTOMER_ID}
                        items={customers}
                        onSelect={handleSelect}
                        error={setError()}
                    ></AutoComplete>
                </div>
            </div>
            <AddUserNavigation disabled={!customerId} onClick={handleSubmit}></AddUserNavigation>
        </form>
    );
};

export default AddUserCustomer;
