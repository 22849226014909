import { useEffect } from "react";
import { useCompetitionsListFiltersDispatch, useCompetitionsListFiltersState } from "./context";
import * as types from "./types";
import CompetitionFiltersReducer from "./reducer";

const useFilters = ({ reducer = CompetitionFiltersReducer } = {}) => {
    const dispatch = useCompetitionsListFiltersDispatch();
    const { minDate, maxDate, teams, competitions, statuses, dateOrder, nameOrder, competitionOrder, statusesOrder } =
        useCompetitionsListFiltersState();

    const setDates = (dates: Date | Date[] | null | undefined) => {
        if (!dates) {
            dispatch({ type: types.UPDATE_DATES, minDate: undefined, maxDate: undefined });
        } else if (Array.isArray(dates) && dates.length >= 1)
            dispatch({ type: types.UPDATE_DATES, minDate: dates[0], maxDate: dates[1] });
    };

    const setTeams = (teams?: types.Item[]) => {
        dispatch({ type: types.UPDATE_TEAMS, teams });
    };

    const setCompetitions = (competitions?: types.Item[]) => {
        dispatch({ type: types.UPDATE_COMPETITIONS, competitions: competitions });
    };

    const setStatuses = (statuses?: types.Item[]) => {
        dispatch({ type: types.UPDATE_STATUSES, statuses });
    };

    const setClearAllFilters = () => {
        dispatch({ type: types.CLEAR_FILTERS });
    };

    const setClearAllButDateFilters = () => {
        dispatch({ type: types.CLEAR_ALL_BUT_DATE_FILTERS });
    };

    const toggleSort = (x?: types.Sort): types.Sort | undefined => {
        switch (x) {
            case "ASC":
                return "DESC";
            case "DESC":
                return undefined;
            default:
                return "ASC";
        }
    };

    const toggleDatesSort = () => {
        dispatch({ type: types.UPDATE_DATES_ORDER, dateOrder: toggleSort(dateOrder) });
    };

    const toggleTeamsSort = () => {
        dispatch({ type: types.UPDATE_TEAMS_ORDER, nameOrder: toggleSort(nameOrder) });
    };

    const toggleCompetitionsSort = () => {
        dispatch({ type: types.UPDATE_COMPETITIONS_ORDER, competitionOrder: toggleSort(competitionOrder) });
    };

    const toggleStatusSort = () => {
        dispatch({ type: types.UPDATE_STATUSES_ORDER, statusesOrder: toggleSort(statusesOrder) });
    };

    useEffect(() => {
        dispatch({ type: types.CLEAR_ALL_BUT_DATE_FILTERS });
    }, [dispatch]);

    return {
        minDate,
        maxDate,
        teams,
        competitions,
        statuses,
        dateOrder,
        nameOrder,
        competitionOrder,
        statusesOrder,
        setDates,
        setTeams,
        setCompetitions,
        setStatuses,
        setClearAllFilters,
        setClearAllButDateFilters,
        toggleDatesSort,
        toggleTeamsSort,
        toggleCompetitionsSort,
        toggleStatusSort
    };
};

export { useFilters };
