import http from "./http.services";
import { users as usersType } from "../utils/hooks/useUsers";
import * as constants from "../constants/resources.constants";

const getUsers = async (pageNumber: number = 1, pageSize = 20, filters?: object): Promise<usersType> => {
    const { data: users } = await http.get(constants.USERS, {
        withCredentials: true,
        params: {
            page_number: pageNumber,
            page_size: pageSize,
            ...filters
        }
    });
    return users;
};

export const usersService = {
    getUsers
};
