import React, { FC, ReactNode, useState } from "react";
import ReactResizeDetector from "react-resize-detector";

import styles from "./maintainAspectRatio.module.scss";

export interface MaintainAspectRatioProps {
    children: ReactNode;
    ratio: number;
}

const MaintainAspectRatio: FC<MaintainAspectRatioProps> = ({ children, ratio }) => {
    const [aspectRatio, setAspectRatio] = useState({ width: 0, height: 0 });

    const handleResize = (width: number, height: number) => {
        if (width * ratio < height) {
            setAspectRatio({ width, height: width * ratio });
        } else {
            setAspectRatio({ width: height / ratio, height });
        }
    };

    return (
        <ReactResizeDetector handleWidth handleHeight onResize={handleResize}>
            {() => {
                return (
                    <div style={aspectRatio} className={styles.aspectRatio}>
                        {children}
                    </div>
                );
            }}
        </ReactResizeDetector>
    );
};

export default MaintainAspectRatio;
