import * as types from "./types";

const editorReducer = (state: types.State, action: types.Action) => {
    switch (action.type) {
        case "SET_EDITOR_ENABLED": {
            return {
                ...state,
                editor: { ...state.editor, enabled: action.enabled, loaded: false },
                tool: { type: null }
            };
        }

        case "SET_EDITOR_LOADED": {
            return { ...state, editor: { ...state.editor, active: action.loaded } };
        }

        case "SET_TOOL": {
            return { ...state, tool: action.tool };
        }

        case "UPDATE_VALUE": {
            return { ...state, tool: { ...state.tool, value: { ...state.tool?.value, ...action.value } } };
        }
    }
};

export default editorReducer;
