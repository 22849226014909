import * as types from "./types";

const fixtureMetadataReducer = (state: types.State, action: types.Action) => {
    switch (action.type) {
        case "SET_COMPETITION_ID": {
            return { ...state, competitionId: action.competitionId };
        }
        case "SET_TEAMS": {
            return { ...state, homeTeam: action.homeTeam, awayTeam: action.awayTeam };
        }
        case "SET_DATE": {
            return { ...state, date: action.date };
        }
        case "CLEAR_METADATA": {
            return {};
        }
    }
};

export default fixtureMetadataReducer;
