import { useState } from "react";
import { toast } from "react-toastify";
import { filesService } from "../../services/files.service";
import * as constants from "../../constants/admin.constants";

export interface DeleteFile {
    message: "Success." | "Error.";
}

export interface UseDeleteFile {
    deleteFile: (fileId: string) => void;
    loading: boolean;
    success: boolean;
    error: any;
}

const useDeleteFile = (): UseDeleteFile => {
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const deleteFile = (fileId: string) => {
        const deleteFile = async () => {
            setLoading(true);
            setError(false);
            setSuccess(false);

            try {
                await filesService.deleteFile(fileId.toString());
                setSuccess(true);
                toast.success(constants.FILE_SUCCESSFULLY_DELETED);
            } catch (error: any) {
                setError(error);
                toast.error(constants.ERROR_DELETING_FILE);
            }
            setLoading(false);
        };

        deleteFile();
    };

    return { deleteFile, success, loading, error };
};

export default useDeleteFile;
