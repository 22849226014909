import { useState } from "react";
import { toast } from "react-toastify";
import * as constants from "../../constants/fixtures.constants";
import { fixturesService } from "../../services/fixtures.service";

export interface UseNotExpectedFixture {
    setNotExpected: (fixtureId: string) => void;
    setExpected: (fixtureId: string) => void;
    loading: boolean;
    success: boolean;
    error: any;
}

const useNotExpectedFixture = (): UseNotExpectedFixture => {
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const setNotExpected = (fixtureId: string) => {
        const setNotExpected = async () => {
            setLoading(true);
            setError(false);
            setSuccess(false);

            try {
                await fixturesService.setNotExpected(fixtureId);
                setSuccess(true);
                toast.success(constants.FIXTURE_SUCCESSFULLY_MARKED_AS_NOT_EXPECTED);
            } catch (error: any) {
                setError(error);
                toast.error(constants.FIXTURE_FAILED_TO_MARK_AS_NOT_EXPECTED);
            }
            setLoading(false);
        };

        setNotExpected();
    };

    const setExpected = (fixtureId: string) => {
        const setExpected = async () => {
            setLoading(true);
            setError(false);
            setSuccess(false);

            try {
                await fixturesService.setExpected(fixtureId);
                setSuccess(true);
                toast.success(constants.FIXTURE_SUCCESSFULLY_MARKED_AS_EXPECTED);
            } catch (error: any) {
                setError(error);
                toast.error(constants.FIXTURE_FAILED_TO_MARK_AS_EXPECTED);
            }
            setLoading(false);
        };

        setExpected();
    };

    return { setNotExpected, setExpected, success, loading, error };
};

export default useNotExpectedFixture;
