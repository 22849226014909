import React, { FC } from "react";
import { Column } from "../comparisonTable";

import styles from "./comparisonHeader.module.scss";

export interface ComparisonHeaderProps {
    columns: Column[];
}

const ComparisonHeader: FC<ComparisonHeaderProps> = ({ columns }) => {
    return (
        <thead className={styles.tableHead}>
            <tr>
                <th></th>
                {columns.map((column: Column, index: number) => {
                    return (
                        <th id={column.id} key={index}>
                            {column.name}
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};

export default ComparisonHeader;
