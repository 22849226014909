import * as types from "./types";
import { useCallback } from "react";
import PlayerReducer from "./reducer";
import { useFixtureMetadataDispatch, useFixtureMetadataState } from "./context";
import { Team } from "../../utils/hooks/useFixtures";

const useFixtureMetadata = ({ reducer = PlayerReducer } = {}) => {
    const dispatch = useFixtureMetadataDispatch();
    const { competitionId, homeTeam, awayTeam, date } = useFixtureMetadataState();

    const setCompetitionId = useCallback(
        (competitionId: string) => {
            dispatch({ type: types.SET_COMPETITION_ID, competitionId });
        },
        [dispatch]
    );

    const setTeams = useCallback(
        (homeTeam: Team, awayTeam: Team) => {
            dispatch({ type: types.SET_TEAMS, homeTeam, awayTeam });
        },
        [dispatch]
    );

    const setDate = useCallback(
        (date: string) => {
            dispatch({ type: types.SET_DATE, date });
        },
        [dispatch]
    );

    return { setCompetitionId, setTeams, setDate, competitionId, homeTeam, awayTeam, date };
};

export { useFixtureMetadata };
