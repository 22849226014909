import React, { FC, MouseEvent as ReactMouseEvent, useState } from "react";
import Icon from "../common/icon";
import { Sort } from "../../store/filters/types";
import { setCamelCase } from "../../utils/string";

import styles from "./fixtureHeader.module.scss";

interface Column {
    id: string;
    name: string;
    sort?: Sort;
}

export interface FixtureHeaderHeaderProps {
    columns: Column[];
    onClick: (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const FixtureHeader: FC<FixtureHeaderHeaderProps> = ({ columns, onClick }) => {
    const [active, setActive] = useState<string | undefined>();

    const renderIcon = (columnId: string, sort?: Sort) => {
        const icon = sort === "DESC" ? "arrow-down" : "arrow-up";

        return (
            <Icon
                icon={{ icon: ["fas", icon], type: "FontAwesomeIcon", size: "1x" }}
                classes={`${styles.icon} ${active === columnId || sort ? styles.active : ""}`}
            />
        );
    };

    const handleMouseEnter = ({ currentTarget }: { currentTarget: any }) => setActive(currentTarget.id);

    const handleMouseLeave = () => setActive(undefined);

    const handleClick = (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
        setActive(undefined);
        onClick(event);
    };

    return (
        <div className={styles.fixtureHeaderContainer}>
            {columns.map((column) => (
                <div key={column.id} className={styles[setCamelCase(column.id)]} onClick={handleClick}>
                    <div
                        id={column.id}
                        className={styles.container}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onClick={onClick}
                    >
                        <div className={styles.label}>{column.name}</div> {renderIcon(column.id, column.sort)}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FixtureHeader;
