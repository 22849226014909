import { useEffect, useState } from "react";
import { teamService } from "../../services/teams.service";

export interface team {
    id: string;
    name: string;
    img: string[];
    competitions: number[];
}

export interface useTeam {
    teams: team[];
    loading: boolean;
    error: any;
}

const useTeams = (competitionIDs?: string[]): useTeam => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(false);
    const [teams, setTeams] = useState<team[]>([]);

    useEffect(() => {
        const getTeams = async () => {
            setLoading(true);
            setError(false);

            try {
                const response = await teamService.getTeams(competitionIDs);
                if (response) setTeams(response);
            } catch (error: any) {
                setError(error);
            }
            setLoading(false);
        };

        getTeams();
    }, [competitionIDs]);

    return { teams, loading, error };
};

export default useTeams;
