import * as constants from "../constants/global.constants";
import { TEAM } from "../constants/fixture.constants";

const formatDate = (input: string): string => {
    const date = new Date(input);

    const day = date.toLocaleString("default", { day: "numeric" });
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.toLocaleString("default", { year: "numeric" });
    const nth = getNthDate(date.getDate());

    return `${day}${nth} ${month} ${year}`;
};

const formatTime = (input: string): string => {
    return new Date(input).toLocaleTimeString("default", {
        hour: "2-digit",
        minute: "2-digit"
    });
};

const formatDateTime = (input: string) => {
    return `${formatDate(input)} - ${formatTime(input)}`;
};

const getNthDate = (date: number): string => {
    if (date > 3 && date < 21) return constants.NTH.th;
    switch (date % 10) {
        case 1:
            return constants.NTH.st;
        case 2:
            return constants.NTH.nd;
        case 3:
            return constants.NTH.rd;
        default:
            return constants.NTH.th;
    }
};

const formatTeam = (input: string): string => {
    const division = input.match(/\d+$/)?.[0];
    if (division) return input.replace(/FC \d+$/, TEAM[parseInt(division) - 1]);

    return input;
};

const formatMatch = (home: string, away: string): string => {
    return `${formatTeam(home)} vs ${formatTeam(away)}`;
};

const formatMatchFileName = (home: string, away: string): string => {
    return formatMatch(home, away).replace(/ /g, "_");
};

const formatClipFileName = (home: string, away: string): string => {
    return `${formatMatch(home, away).replace(/ /g, "_")}_clip.mp4`;
};

const formatClipFileNameById = (fileId: string): string => {
    return `${fileId}_clip.mp4`;
};

export {
    formatDateTime as dateTime,
    formatDate as date,
    formatTime as time,
    formatTeam as team,
    formatMatch as match,
    formatMatchFileName as matchFileName,
    formatClipFileName as clipFileName,
    formatClipFileNameById as clipFileNameById
};
