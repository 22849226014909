import { useEffect, useState } from "react";
import { competitionFeaturesService } from "../../services/competitionFeatures.service";

export interface CompetitionFeatures {
    id: string;
    competitionId: string;
    supportsKmi: boolean;
}

export interface UseCompetitionFeatures {
    competitionFeatures: CompetitionFeatures | undefined;
    loading: boolean;
    error: any;
}

const useCompetitionFeatures = (competitionId: string): UseCompetitionFeatures => {
    const [competitionFeatures, setCompetition] = useState<CompetitionFeatures>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const getCompetition = async () => {
            setLoading(true);
            setError(false);

            if (!competitionId) return;

            try {
                const response = await competitionFeaturesService.getCompetitionFeatures(competitionId);
                if (response) setCompetition(response);
            } catch (error: any) {
                setError(error);
            }
            setLoading(false);
        };

        getCompetition();
    }, [competitionId]);

    return { competitionFeatures, loading, error };
};

export default useCompetitionFeatures;
