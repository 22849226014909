import http from "./http.services";
import {
    DASHBOARD_COMPETITIONS_ALERTS,
    DASHBOARD_COMPETITION_ALERTS,
    DASHBOARD_FIXTURE_ALERTS
} from "../constants/resources.constants";
import { CompetitionAlertData as CompetitionAlertsType } from "../utils/hooks/useDashboardCompetition";
import { FixtureAlertData as FixtureAlertsType } from "../utils/hooks/useDashboardFixture";

const getCompetitionAlerts = async (filters?: object): Promise<CompetitionAlertsType[]> => {
    const { data: competitions } = await http.get(DASHBOARD_COMPETITIONS_ALERTS, {
        withCredentials: true,
        params: {
            ...filters
        }
    });
    return competitions;
};

const getOneCompetitionAlert = async (competitionId: string, filters?: object): Promise<CompetitionAlertsType[]> => {
    const { data: competitions } = await http.get(DASHBOARD_COMPETITION_ALERTS.replace("{id}", competitionId), {
        withCredentials: true,
        params: {
            ...filters
        }
    });
    return competitions;
};

const getFixtureAlerts = async (
    pageNumber: number = 1,
    pageSize = 10,
    competitionId: string,
    filters?: object
): Promise<FixtureAlertsType> => {
    const { data: fixtures } = await http.get(DASHBOARD_FIXTURE_ALERTS.replace("{id}", competitionId), {
        withCredentials: true,
        params: {
            page_number: pageNumber,
            page_size: pageSize,
            ...filters
        }
    });
    return fixtures;
};

export const dashboardService = {
    getCompetitionAlerts,
    getOneCompetitionAlert,
    getFixtureAlerts
};
