import { useEffect, useState, useCallback } from "react";
import { State as Filters } from "../../store/filters/types";
import { fixturesService } from "../../services/fixtures.service";

export interface fixtures {
    page_number: number;
    page_size: number;
    page_count: number;
    data: [FixturesData];
}

export interface FixturesData {
    id: string;
    moas_id: number;
    competition: Competition;
    home_team: Team;
    away_team: Team;
    date: string;
    status: FixtureStatus;
}

export type FixtureStatus =
    | "WAITING"
    | "EXPECTED"
    | "UPLOADING"
    | "ERROR"
    | "ARCHIVED"
    | "TRANSCODING"
    | "MARKUP"
    | "AVAILABLE"
    | "POSTPONED"
    | "CANCELLED"
    | "NOT_EXPECTED";

export interface Team {
    id: string;
    name: string;
    moas_id: number;
    opta_id: number | undefined;
}

export interface Competition {
    id: string;
    hudl_id: string;
    img_short_name: null;
    name: string;
    opta_id: string;
    short_name: string;
}

export interface UseFixtures {
    fixtures: FixturesData[];
    loading: boolean;
    hasMore: boolean;
    error: any;
    reloadFixtures: () => void;
}

const useFixtures = (pageNumber: number, pageSize: number, filters?: Filters): UseFixtures => {
    const [loading, setLoading] = useState(true);
    const [fixtures, setFixtures] = useState<FixturesData[]>([]);
    const [hasMore, setHasMore] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        setFixtures([]);
    }, [filters]);

    useEffect(() => {
        const getFixtures = async () => {
            setLoading(true);
            setHasMore(true);
            setError(false);

            try {
                const response = await fixturesService.getFixtures(pageNumber, pageSize, {
                    ...(filters?.minDate && { min_date: filters?.minDate?.toISOString() }),
                    ...(filters?.maxDate && { max_date: filters?.maxDate?.toISOString() }),
                    ...(filters?.dateOrder && { date_order: filters?.dateOrder }),
                    ...(filters?.nameOrder && { name_order: filters?.nameOrder }),
                    ...(filters?.statusesOrder && { status_order: filters?.statusesOrder }),
                    ...(filters?.competitionOrder && { competition_order: filters?.competitionOrder }),
                    ...(filters?.teams?.length && { team_id: filters?.teams?.map((team) => team.id) }),
                    ...(filters?.statuses?.length && { status: filters?.statuses?.map((status) => status.id) }),
                    ...(filters?.competitions?.length && {
                        competition_id: filters?.competitions?.map((competition) => competition.id)
                    })
                });
                if (response)
                    setFixtures((fixtures) => {
                        return [...fixtures, ...response.data];
                    });
                if (response?.page_count <= pageNumber) setHasMore(false);
            } catch (error: any) {
                setError(error);
            }
            setLoading(false);
        };
        getFixtures();
    }, [pageNumber, pageSize, filters]);

    const reloadFixtures = useCallback(async () => {
        try {
            const response = await fixturesService.getFixtures(1, pageNumber * pageSize, {
                ...(filters?.minDate && { min_date: filters?.minDate?.toISOString() }),
                ...(filters?.maxDate && { max_date: filters?.maxDate?.toISOString() }),
                ...(filters?.dateOrder && { date_order: filters?.dateOrder }),
                ...(filters?.nameOrder && { name_order: filters?.nameOrder }),
                ...(filters?.statusesOrder && { status_order: filters?.statusesOrder }),
                ...(filters?.competitionOrder && { competition_order: filters?.competitionOrder }),
                ...(filters?.teams?.length && { team_id: filters?.teams?.map((team) => team.id) }),
                ...(filters?.statuses?.length && { status: filters?.statuses?.map((status) => status.id) }),
                ...(filters?.competitions?.length && {
                    competition_id: filters?.competitions?.map((competition) => competition.id)
                })
            });
            if (response) {
                setFixtures(response.data);
            }
        } catch (error: any) {
            setError(error);
        }
    }, [filters, pageNumber, pageSize]);

    return { fixtures, loading, hasMore, error, reloadFixtures };
};

export default useFixtures;
