import { useEffect, useState } from "react";
import { authService } from "../../services/auth.services";
import { User } from "../../store/register/types";

export interface UseUser {
    user: User | undefined;
    loading: boolean;
    error: any;
}

const useUser = (userId: number): UseUser => {
    const [user, setUser] = useState<User>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const getUser = async () => {
            setUser(undefined);
            setLoading(true);
            setError(false);

            try {
                const response = await authService.getUser(userId.toString());
                if (response) setUser(response);
            } catch (error: any) {
                setError(error);
            }
            setLoading(false);
        };

        getUser();
    }, [userId]);

    return { user, loading, error };
};

export default useUser;
