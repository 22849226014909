import {FC, useMemo} from "react";
import Title from "../common/title";
import Button from "../common/button";
import FileUpload from "../fileUpload";
import * as roles from "../../store/role/types";
import * as permissions from "../../store/role/types";
import ProtectedComponent from "../common/protectedComponent";
import * as constants from "../../constants/global.constants";
import useWindowDimensions from "../../utils/hooks/useWindowDimensions";
import { useFixtureMetadata } from "../../store/fixtureMetadata/useFixtureMetadata";
import { FixtureFiles as FixtureFilesType } from "../../utils/hooks/useFixtureFootage";

import styles from "./fixturePageHeader.module.scss";
import {canFanId} from "../../store/role";
import {useLoginState} from "../../store/auth/context";

export interface FixturePageHeaderProps {
    fixtureId: string;
    title: string;
    footage: FixtureFilesType[];
    onClick: () => void;
    fanIds?: number[];
    width?: number;
}

const BrowserFixturePageHeader: FC<FixturePageHeaderProps> = ({title, fixtureId, footage, onClick, fanIds, width}) => {
    const {competitionId = ""} = useFixtureMetadata();
    const auth = useLoginState();

    let fanIdPermission = useMemo(() => {
        if(auth.user) return canFanId(auth.user?.fan_id, fanIds);
    }, [fanIds, auth.user])

    const isMobile = useMemo(() => {
        if (width) return width < 1100; else return false;
    }, [width]);

    return (
        <div className={isMobile ? styles.mobileOuterDiv : styles.header}>
            <Button
                id={constants.BACK}
                name={isMobile ? "" : `${constants.BACK}`}
                onClick={onClick}
                classes={isMobile ? `btn btn-transparent ${styles.mobileButton}` :`btn btn-transparent ${styles.button}`}
                icon={{ icon: { icon: ["fas", "arrow-left"], type: "FontAwesomeIcon", size: "1x" } }}
            ></Button>
            <Title label={title} classes={isMobile? styles.mobileTitle: styles.title}></Title>
            <ProtectedComponent
                roles={roles.uploadRole}
                permissions={{ competitionId, permissions: permissions.upload }}
                fanIdPermission={fanIdPermission}
            >
                <FileUpload
                    id={constants.UPLOAD}
                    name={isMobile ? "": constants.UPLOAD}
                    icon={{
                        icon: { icon: ["fas", "upload"], type: "FontAwesomeIcon", size: "1x" },
                        classes: styles.icon
                    }}
                    classes={isMobile ? `btn btn-transparent ${styles.mobileButton}` :`btn btn-transparent ${styles.button}`}
                    rippleColor={"black"}
                    tooltip={constants.UPLOAD}
                    fixtureId={fixtureId}
                    footage={footage}
                />
            </ProtectedComponent>
        </div>
    );
};

const FixturePlayerHeader: FC<FixturePageHeaderProps> = ({ ...props }) => {
    const { width } = useWindowDimensions();
    return  <BrowserFixturePageHeader {...props} width={width} />
};

export default FixturePlayerHeader;
