import React, { FC, FormEvent } from "react";
import Button from "../common/button";
import * as roles from "../../store/role/types";
import * as permissions from "../../store/role/types";
import ProtectedComponent from "../common/protectedComponent";
import * as constants from "../../constants/fixture.constants";
import { ReactComponent as Trim } from "../../assets/images/player/trim.svg";
import { ReactComponent as Sync } from "../../assets/images/player/sync.svg";
import { useFixtureMetadata } from "../../store/fixtureMetadata/useFixtureMetadata";

import styles from "./fixturePlayerControls.module.scss";

export interface FixturePlayerControlsProps {
    onClick: (event: FormEvent<EventTarget>) => void;

}

const FixturePlayerControls: FC<FixturePlayerControlsProps> = ({ onClick }) => {
    const { competitionId = "" } = useFixtureMetadata();

    return (
        <div className={styles.parentContainer}>
            <div className={styles.childContainer}>
                <Button
                    id={constants.TOOLS.TRIM.id}
                    name={constants.TOOLS.TRIM.name}
                    tooltip={constants.TOOLS.TRIM.name}
                    onClick={onClick}
                    rippleColor={"black"}
                    classes={`btn-outline-green btn-sm ${styles.button}`}
                    icon={{ icon: { icon: <Trim />, type: "Svg" } }}
                ></Button>
                <ProtectedComponent
                    roles={roles.syncRole}
                    permissions={{ competitionId, permissions: permissions.sync }}
                >
                    <Button
                        id={constants.TOOLS.SYNC.id}
                        name={constants.TOOLS.SYNC.name}
                        onClick={onClick}
                        icon={{ icon: { icon: <Sync />, type: "Svg" } }}
                        classes={`btn-outline-green btn-sm ${styles.button}`}
                        tooltip={constants.TOOLS.SYNC.name}
                        rippleColor={"black"}
                    ></Button>
                </ProtectedComponent>
            </div>
        </div>
    );
};

export default FixturePlayerControls;
