import http from "./http.services";
import { team as teamType } from "../utils/hooks/useTeams";
import * as constants from "../constants/resources.constants";
import { team as teamPagedType } from "../utils/hooks/usePagedTeams";

const getPagedTeams = async (pageNumber: number = 1, pageSize = 5): Promise<teamPagedType> => {
    const { data: teams } = await http.get(constants.TEAMS, {
        withCredentials: true,
        params: {
            page_number: pageNumber,
            page_size: pageSize
        }
    });
    return teams;
};

const getTeams = async (competitionIDs?: string[]): Promise<teamType[]> => {
    const { data: teams } = await http.get(constants.FILTERS, {
        withCredentials: true,
        params: {
            ...(!!competitionIDs && competitionIDs.length > 0 && { competition_ids: competitionIDs })
        }
    });
    return teams;
};

export const teamService = {
    getPagedTeams,
    getTeams
};
