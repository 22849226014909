export const DASHBOARD_TITLE = "Dashboard.";

/* Dashboard Columns */
export const DASHBOARD_COLUMNS = [
    { id: "leagueOrder", name: "League" },
    { id: "availableOrder", name: "Available" },
    { id: "expectedOrder", name: "Expected" },
    { id: "notexpectedOrder", name: "Not Expected" },
    { id: "markupOrder", name: "Markup" },
    { id: "errorOrder", name: "Error" }
];


