import React, { FC } from "react";
import Info from "../common/info";
import Title from "../common/title";
import Button from "../common/button";
import { can } from "../../store/role";
import * as roles from "../../store/role/types";
import AutoComplete, { Item } from "../common/autoComplete";
import * as constants from "../../constants/admin.constants";
import useCompetitions from "../../utils/hooks/useCompetitions";

import styles from "./editUserPermissions.module.scss";

export interface EditUserPermissionsProps {
    permissions: roles.Permissions[];
    role: roles.Role;
    onAddRow: () => void;
    onSelectCompetition: (item: Item | null, index: number) => void;
    onSelectPermission: (item: Item | null, index: number) => void;
}

const EditUserPermissions: FC<EditUserPermissionsProps> = ({
    permissions,
    role,
    onAddRow,
    onSelectCompetition,
    onSelectPermission
}) => {
    /* Fetch competitions list */
    const { competitions } = useCompetitions();

    const handleDisabled = (): boolean => {
        if (role && can(role, roles.setPerCompetitionPermissions)) {
            return false;
        }

        return true;
    };

    return (
        <div className={styles.formGroup}>
            <Title label={constants.USER_PERMISSIONS} classes={`heading ${styles.heading}`}></Title>
            <div className={handleDisabled() ? styles.adminPrivileges : ""}>
                {!handleDisabled() &&
                    permissions.map((permission, index) => {
                        return (
                            <div key={index} className={styles.formFields}>
                                <AutoComplete
                                    id={`${constants.COMPETITION}${index}`}
                                    label={constants.COMPETITION}
                                    items={competitions}
                                    classes={styles.inputContainer}
                                    selectedItem={
                                        competitions.find(
                                            (competition: Item) => competition.id === permission.competition_id
                                        ) || null
                                    }
                                    onSelect={(item) => onSelectCompetition(item, index)}
                                    disabled={handleDisabled()}
                                />
                                <AutoComplete
                                    id={`${constants.COMPETITION_ROLE}${index}`}
                                    label={constants.COMPETITION_ROLE}
                                    items={constants.COMPETITION_ROLES}
                                    classes={styles.inputContainer}
                                    selectedItem={
                                        constants.COMPETITION_ROLES.find((role) => role.id === permission.permission) ||
                                        null
                                    }
                                    onSelect={(item) => onSelectPermission(item, index)}
                                    disabled={handleDisabled()}
                                ></AutoComplete>
                            </div>
                        );
                    })}
                <Button
                    name={constants.ADD_PERMISSION}
                    onClick={onAddRow}
                    classes={`btn btn-transparent ${styles.addRow}`}
                    rippleColor={"black"}
                    disabled={handleDisabled()}
                ></Button>
            </div>
            {handleDisabled() && (
                <Info message={constants.ADMIN_PRIVILEGES_INFO} classes={styles.adminPrivilegesInfo}></Info>
            )}
        </div>
    );
};

export default EditUserPermissions;
