export const FIXTURESTITLE = "Fixtures.";
export const EDITOR = "Editor";

export const TEAM = [
    "",
    "(Second Team)",
    "(Third Team)",
    "(Fourth Team)",
    "(Fifth Team)",
    "(Sixth Team)",
    "(Seventh Team)",
    "(Eighth Team)",
    "(Ninth Team)",
    "(Tenth Team)",
    "(Eleventh Team)",
    "(Twelfth Team)",
    "(Thirteenth Team)",
    "(Fourteenth Team)",
    "(Fifteenth Team)",
    "(Sixteenth Team)",
    "(Seventeenth Team)",
    "(Eighteenth Team)",
    "(Nineteenth Team)",
    "(Twentieth Team)"
];

export const VIDEO_TYPES = [
    { id: "VIDEO_BROADCAST", name: "Broadcast Angle" },
    { id: "VIDEO_BROADCAST_COMMS", name: "Broadcast Communications" },
    { id: "AUDIO_COMMS", name: "Audio Communications" },
    { id: "VIDEO_TACTICAL", name: "Tactical Angle" },
    { id: "VIDEO_HIGH_BEHIND_ONE", name: "High Behind One" },
    { id: "VIDEO_HIGH_BEHIND_TWO", name: "High Behind Two" },
    { id: "VIDEO_EIGHTEEN_YARD_ONE", name: "Eighteen Yard Angle One" },
    { id: "VIDEO_EIGHTEEN_YARD_TWO", name: "Eighteen Yard Angle Two" },
    { id: "VIDEO_HIGHLIGHTS", name: "Highlights" },
    { id: "VIDEO_PANORAMIC", name: "Panoramic Angle" },
    { id: "VIDEO_CLIP", name: "Video Clip" },
    { id: "AUDIO_CLIP", name: "Audio Clip" },
];

export const STATUS = {
    WAITING: "Waiting",
    EXPECTED: "Expected",
    UPLOADING: "Uploading",
    TRANSCODING: "Transcoding",
    MARKUP: "Markup",
    ARCHIVED: "Archived",
    AVAILABLE: "Available",
    CANCELLED: "Cancelled",
    POSTPONED: "Postponed",
    ERROR: "Error",
    NOT_EXPECTED: "Not Expected"
};

export const FILE_STATUS = {
    UPLOADING: "Uploading",
    TRANSCODING: "Transcoding",
    MARKUP: "Markup",
    AVAILABLE: "Available",
    ARCHIVED: "Archived",
    ERROR: "Error"
};

/* Upload */
export const UPLOAD = "Upload";

/* Editor Save confirmation */
export const CANCEL = "Cancel";
export const SAVE = "Save";

/* Editor Tools */
export const TOOLS = {
    TRIM: { id: "TRIM", name: "Create Clip" },
    SYNC: { id: "SYNC", name: "Set Match Times" },
    SYNC_SUBTYPES: {
        FIRST_HALF: { id: "FIRST_HALF", name: "First Half" },
        SECOND_HALF: { id: "SECOND_HALF", name: "Second Half" },
        FIRST_OVERTIME: { id: "FIRST_OVERTIME", name: "First Extra Time" },
        SECOND_OVERTIME: { id: "SECOND_OVERTIME", name: "Second Extra Time" },
        PENALTY_SHOOTOUT: { id: "PENALTY_SHOOTOUT", name: "Penalty Shoot Out" }
    }
};

export const SYNC_TOOLS = {
    ADD_SYNC_POINT: "Add Sync Point",
    MOVE_SYNC_POINT: "Move Sync Point",
    GO_TO_SYNC_POINT: "Go to Sync Point"
};

export const SHOW_MORE = "Show More";
export const SHOW_LESS = "Show Less";

/* Video/audio File Upload */
// export const FILE_FORMATS = "video/mp4,video/x-m4v,video/*,audio/mp3,audio/*";
export const FILE_FORMATS = "video/mp4,video/x-m4v,video/*,audio/mp3,audio/wav,audio/m4a,audio/aac,audio/ogg,audio/*";


/* File Upload Message */
export const UPLOAD_SUCCESS = "{file} uploaded successfully";
export const UPLOAD_ERROR = "Error uploading {file}";

/* Sync Messages */
export const SYNC_SUCCESS = "Sync points set successfully";
export const SYNC_ERROR = "Error setting sync points";

/* Trim Messages */
export const TRIM_ERROR = "Error creating clip";
export const CREATING_CLIP = "Creating clip";
export const CREATING_CLIP_NAME = "Creating clip {name}";
export const TRIM_ID = "{id}_trim";

/* Upload File Modal */
export const UPLOAD_FILE = "Upload File";
export const UPLOAD_FILES = "Upload Files";
export const FILE_NAME_ID = "fileName";
export const FILE_NAME = "File Name";
export const FILE_TYPE = "File Type";
export const UPLOAD_FILE_NAME = "Uploading {name}";
export const FILE_WITH_SUB_TYPE_EXISTS = "Warning: {angle} exists and will be overwritten if selected";

const UPLOAD_FILE_ERROR = "Invalid File Name";

export const uploadFileSchema: any = {
    type: "object",
    properties: {
        fileName: {
            type: "string",
            minLength: 1,
            errorMessage: UPLOAD_FILE_ERROR
        }
    }
};

/* Uploading file Modal */
export const UPLOADING = "Uploading";

/* Manage Fixture */
export const MANAGE_FILE = "Manage File";
export const EDIT_FILE = "Delete File";
export const DOWNLOAD = "Download";
export const DELETE_FILE = "Delete File";
export const DELETE_FILE_CONFIRMATION = "Delete {file}";

/* Download File Messages */
export const DOWNLOAD_ERROR = "Error downloading file";
export const DOWNLOADING_FILE = "Downloading File";
export const DOWNLOAD_MEDIA_NAME = "Downloading {name}";
export const CREATING_DOWNLOAD = "Creating download";
export const DOWNLOAD_ID = "{id}_download";

/* Uploaded to Hudl */
export const UPLOADED_TO_HUDL = "Uploaded to Hudl";

/* Upload KMI */
export const FOOTAGE = "Footage";
export const KMI = "KMI";
export const UPLOAD_FOOTAGE = "Upload Footage";
export const UPLOAD_KMI = "Upload Incident";
export const GET_STARTED = "Get Started";

export const XML_FORMAT = "application/xml";

export const CLIP = "Clip";
export const VIDEO = "VIDEO";
export const AUDIO = "AUDIO";
export const XML = "XML";
export const PDF = "PDF";

export const IMG_CLIP = "img_clip";

export const VIDEO_CLIP = "VIDEO_CLIP";

export const KMI_INFO = "Clip and XML need to be named in format MD_{date}_XXX_XXX_INC1_MD.(mp4/xls.xml)";

export const INVALID_FILE_NAME = "Invalid file name";

/* Match Times */
export const MATCH_SYNC_POINTS = [
    {
        id: "FIRST_HALF",
        start: 0,
        duration: 2700
    },
    {
        id: "SECOND_HALF",
        start: 2700,
        duration: 2700
    },
    {
        id: "FIRST_OVERTIME",
        start: 5400,
        duration: 900
    },
    {
        id: "SECOND_OVERTIME",
        start: 6300,
        duration: 900
    },
    {
        id: "PENALTY_SHOOTOUT",
        start: 7200,
        duration: 60000
    }
];
