import React, { FC } from "react";
import Button from "../common/button";
import * as number from "../../utils/number";
import * as constants from "../../constants/global.constants";

import styles from "./file.module.scss";
import Icon, { IconProps } from "@sbgsportssoftware/icon";

export interface FileProps {
    name: string;
    size: number;
    icon?: IconProps["icon"];
    classes?: string;
    onClick?: () => void;
}

const File: FC<FileProps> = ({ name, size, icon, classes, onClick }) => {
    const renderSize = (size: number) => {
        return `${Math.ceil(number.bytesToMegaBytes(size))}mb`;
    };

    return (
        <div className={`${styles.parentContainer} ${classes}`}>
            {icon && <Icon icon={icon} classes={styles.icon} />}
            <div className={styles.items}>
                <div className={styles.fileName}>{name}</div>
                <div className={styles.fileSize}>{renderSize(size)}</div>
            </div>
            <Button
                id={constants.REMOVE}
                tooltip={constants.REMOVE}
                icon={{ icon: { icon: ["fas", "times"], type: "FontAwesomeIcon", size: "1x" } }}
                onClick={onClick}
                classes={`btn btn-transparent ${styles.deleteButton}`}
            ></Button>
        </div>
    );
};

export default File;
