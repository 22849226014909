import React from "react";
import Toast from "../components/common/toast";
import Routing from "../components/common/routing";
import { LoginProvider } from "../store/auth/context";
import { ScrollProvider } from "../store/scroll/context";
import { FiltersProvider } from "../store/filters/context";
import { CompetitionsListFiltersProvider } from "../store/competitionsListFilters/context";
import ProgressQueue from "../components/common/progressQueue";
import PageProgressBar from "../components/common/pageProgressBar";
import { ProgressBarProvider } from "../store/progressBar/context";
import { ProgressQueueProvider } from "../store/progressQueue/context";

import "./app.scss";

const App: React.FC = () => {
    return (
        <LoginProvider>
            <ProgressBarProvider>
                <ProgressQueueProvider>
                    <FiltersProvider>
                        <CompetitionsListFiltersProvider>
                            <ScrollProvider>
                                <div className="App">
                                    <Routing />
                                    <Toast />
                                    <PageProgressBar />
                                    <ProgressQueue />
                                </div>
                            </ScrollProvider>
                        </CompetitionsListFiltersProvider>
                    </FiltersProvider>
                </ProgressQueueProvider>
            </ProgressBarProvider>
        </LoginProvider>
    );
};

export default App;
