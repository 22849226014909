import React, { createContext, useReducer, useContext } from "react";
import { Dispatch, State, CompetitionsListFiltersProviderProps } from "./types";
import { getDateAtEndOfDay, getOffsetDate } from "../../utils/date";
import CompetitionsListFiltersReducer from "./reducer";
import * as types from "./types";

const CompetitionsListFiltersStateContext = createContext<State | undefined>(undefined);
const CompetitionsListFiltersDispatchContext = createContext<Dispatch | undefined>(undefined);

const CompetitionsListFiltersProvider = ({ children }: CompetitionsListFiltersProviderProps) => {
    const [state, dispatch] = useReducer(CompetitionsListFiltersReducer, {
        dateOrder: "DESC",
        minDate: getOffsetDate(-3),
        maxDate: getDateAtEndOfDay(),
        teams: [],
        statuses: [],
        competitions: []
    });
    return (
        <CompetitionsListFiltersStateContext.Provider value={state}>
            <CompetitionsListFiltersDispatchContext.Provider value={dispatch}>
                {children}
            </CompetitionsListFiltersDispatchContext.Provider>
        </CompetitionsListFiltersStateContext.Provider>
    );
};

const useCompetitionsListFiltersState = () => {
    const context = useContext(CompetitionsListFiltersStateContext);
    if (context === undefined) {
        throw new Error("useCompetitionsListFiltersState must be used within a CompetitionsListFiltersProvider");
    }
    CompetitionsListFiltersReducer(context, { type: types.CLEAR_ALL_BUT_DATE_FILTERS });
    return context;
};

const useCompetitionsListFiltersDispatch = () => {
    const context = useContext(CompetitionsListFiltersDispatchContext);
    if (context === undefined) {
        throw new Error("useCompetitionsListFiltersDispatch must be used within a CompetitionsListFiltersProvider");
    }
    return context;
};

export { CompetitionsListFiltersProvider, useCompetitionsListFiltersState, useCompetitionsListFiltersDispatch };
