import {useState} from "react";
import crypto from "crypto";
import Evaporate from "evaporate";
import { toast } from "react-toastify";
import { FileMetaData, FileMetadataProps } from "./useFileUpload";
import { filesService } from "../../services/files.service";
import * as constants from "../../constants/fixture.constants";
import * as resourceConstants from "../../constants/resources.constants";
import { useProgressQueue } from "../../store/progressQueue/useProgressQueue";
import useMultipartFileUploadProgress from "./useSetMultiPartFileUploadProgress";
import {logErrorsSentry} from "../logErrorsSentry";
import {useMixPanel} from "../../logging_config/Mixpanel";

export const evaporateOptions = {
    awsRegion: resourceConstants.AWS_REGION,
    signerUrl: resourceConstants.AWS_SIGNED_URL,
    aws_key: resourceConstants.AWS_KEY,
    aws_url: resourceConstants.AWS_URL,
    sendCanonicalRequestToSignerUrl: true,
    bucket: resourceConstants.AWS_BUCKET,
    computeContentMd5: true,
    logging: false,
    xhrWithCredentials: true,
    cryptoMd5Method: (data: ArrayBuffer): string => crypto.createHash("md5").update(new Buffer(data)).digest("base64"),
    cryptoHexEncodedHash256: (data: any): string => crypto.createHash("sha256").update(new Buffer(data)).digest("hex")
};

export interface UseMultipartFileUpload {
    loading: boolean;
    error: any;
    setMultipartFileUpload: (fixtureId: string, file: File, fileMetadata?: FileMetadataProps) => void;
}

const useMultipartFileUpload = (): UseMultipartFileUpload => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const { addItem, removeItem } = useProgressQueue();
    const { setMultipartFileUploadProgress } = useMultipartFileUploadProgress();
    const mixpanel = useMixPanel()

    const setMultipartFileUpload = async (fixtureId: string, file: File, fileMetadata?: FileMetadataProps) => {
        if (!file) return;

        setLoading(true);
        setError(false);

        const fileMetaDataObject: FileMetaData = {
            fixture_id: Number(fixtureId),
            name: fileMetadata?.name ? fileMetadata?.name : file.name,
            file_type: fileMetadata?.type ? fileMetadata?.type : "VIDEO",
            sub_type: fileMetadata?.subType ? fileMetadata?.subType : "VIDEO_BROADCAST",
            source: fileMetadata?.source
        };

        const response = await filesService.generateUploadLink(fileMetaDataObject);

        addItem({
            id: response.id,
            name: constants.UPLOAD_FILE_NAME.replace("{name}", file.name),
            active: true
        });

        const evaporate = await Evaporate.create(evaporateOptions).then(
            (evaporate) => {
                return evaporate
            },
            (reason) => {
                logErrorsSentry(reason, "MultiUpload - Evaporate Creation Failed")
                return null
            }
        )

        if (!evaporate) {
            return;
        }

        await evaporate.add({
            name: response.upload_destination,
            file: file,
            contentType: file.type,
            progress: (progress: number, stats: any) => {
                setMultipartFileUploadProgress(response.id, progress, stats)
            },
            complete: () => {
                removeItem(response.id);
                toast.success(constants.UPLOAD_SUCCESS.replace("{file}", fileMetaDataObject.name));
                mixpanel.track("File Uploaded Successfully", {file: fileMetaDataObject.name})
            },
            error: (error: any) => {
                logErrorsSentry(error, "MultiUpload - Error");
                toast.error(constants.UPLOAD_ERROR.replace("{file}", fileMetaDataObject.name));
                setError(error);
            },
            paused: (detail) => {
                const message = `MultiUpload - Paused: ${fileMetaDataObject.name}`;
                logErrorsSentry(null, message, "info", detail)
            },
            resumed: (detail) => {
                const message = `MultiUpload - Resumed: ${fileMetaDataObject.name}`;
                logErrorsSentry(null, message, "info", detail)
            },
            cancelled: () => {
                const message = `MultiUpload - Cancelled: ${fileMetaDataObject.name}`;
                logErrorsSentry(null, message, "warning")
            },
            warn: (detail) => {
                const message = `MultiUpload - Warning: ${fileMetaDataObject.name}`;
                logErrorsSentry(null, message, "warning", detail)
            }
        }).then(
            reason =>
                logErrorsSentry(reason, "MultiUpload - File Failed to Upload")
        )
        setLoading(false);
    };

    return { loading, error, setMultipartFileUpload };
};

export default useMultipartFileUpload;
