import React, { useEffect, useRef, useReducer, MouseEvent as ReactMouseEvent, useMemo } from "react";
import useNotExpectedFixture from "../../utils/hooks/useNotExpectedFixture";
import * as types from "./types";
import * as dashboardConstants from "../../constants/dashboard.constants";
import * as fixtureConstants from "../../constants/fixtures.constants";
import { Sort } from "../../store/competitionsListFilters/types";
import { history } from "../../utils/history";
import { withRouter, RouteComponentProps } from "react-router";
import { refineFixturesReducer } from "./reducer";
import Competition from "../competition";
import CompetitionsHeader from "../competitionsHeader";
import FixtureAnglesHeader from "../fixtureAnglesHeader";
import FixtureAngles from "../fixtureAngles";
import Img from "../common/img";
import spinner from "../../assets/images/common/spinner.svg";
import Button from "../common/button";
import * as format from "../../utils/format";
import { getDateString } from "../../utils/date";
import useInfiniteList from "../../utils/hooks/useInfiniteList";
import useWindowDimensions from "../../utils/hooks/useWindowDimensions";
import { useCompetitionsListFiltersState } from "../../store/competitionsListFilters/context";
import { useFilters } from "../../store/competitionsListFilters/useCompetitionsListFilters";
import { useOneCompetitionAlert, useCompetitionAlertsType } from "../../utils/hooks/useDashboardCompetition";
import { useFixtureAlerts, useFixtureAlertsType } from "../../utils/hooks/useDashboardFixture";

import styles from "./dashboardSummary.module.scss";

export interface CompetitionSummaryParams {
    competitionId: string;
}

export interface CompetitionSummaryProps extends RouteComponentProps<CompetitionSummaryParams> {
    onToggleFiltersOpen: () => void;
}

const CompetitionSummary: React.FC<CompetitionSummaryProps> = ({ onToggleFiltersOpen, match: { params } }) => {
    const dashboardContainerRef = useRef<HTMLDivElement>(null);
    const filters = useCompetitionsListFiltersState();
    const isMounted = useRef(false);
    const { setNotExpected, setExpected } = useNotExpectedFixture();
    const { toggleDatesSort, toggleTeamsSort, toggleStatusSort } = useFilters();
    const { width } = useWindowDimensions();
    const competitionId = params.competitionId;

    useEffect(() => {
        if (isMounted.current) {
            dispatchRefineFixtures({
                type: types.SET_FILTERS,
                filters: filters
            });
            dashboardContainerRef.current?.scrollIntoView();
        } else {
            isMounted.current = true;
        }
    }, [filters]);

    const loadNextPage = () => {
        dispatchRefineFixtures({
            type: types.SET_PAGE_NUMBER,
            pageNumber: refineFixtures.pageNumber + 1
        });
    };

    const [refineFixtures, dispatchRefineFixtures] = useReducer(refineFixturesReducer, {
        pageNumber: 1,
        pageSize: 20,
        filters: filters
    });

    const { competitionAlerts, lastUpdated }: useCompetitionAlertsType = useOneCompetitionAlert(
        competitionId,
        refineFixtures.filters
    );

    const { competitionName, fixtures, loading, hasMore, error }: useFixtureAlertsType = useFixtureAlerts(
        refineFixtures.pageNumber,
        refineFixtures.pageSize,
        competitionId,
        refineFixtures.filters
    );

    const { intersectionObserver, topPadding, bottomPadding, firstIndex, currentIndex } = useInfiniteList(
        fixtures,
        refineFixtures.pageSize,
        loading,
        hasMore,
        error,
        width >= 768 ? 3.75 : 9,
        loadNextPage,
        refineFixtures.filters
    );

    const lastUpdatedString = useMemo(() => getDateString(lastUpdated), [lastUpdated]);

    const dashboardColumns = dashboardConstants.DASHBOARD_COLUMNS.map((column) => ({
        ...column,
        sort: (filters[column.id] ? filters[column.id] : undefined) as Sort | undefined
    }));

    const columns = fixtureConstants.FIXTURE_ANGLES_COLUMNS.map((column) => ({
        ...column,
        sort: (filters[column.id] ? filters[column.id] : undefined) as Sort | undefined
    }));

    const handleClick = (id: string) => {
        history.push(`/fixtures/${id}`);
    };

    const handleDropdownSelection = (id: string, selection: string) => {
        if (selection === fixtureConstants.SET_FIXTURE_EXPECTED) setExpected(id);
        else if (selection === fixtureConstants.SET_FIXTURE_NOT_EXPECTED) setNotExpected(id);
    };

    const handleSort = (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
        const { id } = event.currentTarget;
        switch (id) {
            case "dateOrder":
                toggleDatesSort();
                break;
            case "nameOrder":
                toggleTeamsSort();
                break;
            case "statusesOrder":
                toggleStatusSort();
                break;
            default:
                break;
        }
    };
    
    return (
        <div className={styles.competitionSummaryContainer}>
            <div className={styles.dashboardTitle} ref={dashboardContainerRef}>
                {dashboardConstants.DASHBOARD_TITLE}
            </div>
            <div className={styles.lastUpdated}>{lastUpdatedString}</div>
            <div className={styles.filterControls}>
                <Button
                    id={fixtureConstants.FILTER}
                    tooltip={fixtureConstants.FILTER}
                    icon={{ icon: { icon: ["fas", "filter"], type: "FontAwesomeIcon", size: "1x" } }}
                    onClick={onToggleFiltersOpen}
                    classes={`btn-transparent ${styles.filterButton}`}
                />
            </div>
            <CompetitionsHeader columns={dashboardColumns} />
            {competitionAlerts.map((competition) => (
                <Competition
                    key={competition.CompetitionId}
                    CompetitionId={competition.CompetitionId}
                    CompetitionName={competition.CompetitionName}
                    Status={competition.Status}
                    disabled={true}
                />
            ))}
            <div className={styles.fixturesList}>
                <FixtureAnglesHeader columns={columns} onClick={handleSort}></FixtureAnglesHeader>
                <div
                    ref={intersectionObserver}
                    style={{
                        paddingTop: `${topPadding}rem`,
                        paddingBottom: `${bottomPadding}rem`
                    }}
                >
                    {fixtures.slice(firstIndex, currentIndex).map((fixture) => (
                        <FixtureAngles
                            key={fixture.id}
                            id={fixture.id.toString()}
                            result={"VS"}
                            homeTeam={format.team(fixture.home_team)}
                            awayTeam={format.team(fixture.away_team)}
                            competition={competitionName}
                            competitionId={competitionId}
                            shortDate={format.date(fixture.date)}
                            date={format.dateTime(fixture.date)}
                            state={fixture.status}
                            files={fixture.files}
                            onClick={handleClick}
                            onDropdownSelection={handleDropdownSelection}
                        ></FixtureAngles>
                    ))}
                </div>
            </div>
            {!fixtures.length && loading && (
                <div className={styles.spinner}>
                    <Img src={spinner} alt={"Loading Spinner"} classes="logo"></Img>
                </div>
            )}
        </div>
    );
};

export default withRouter(CompetitionSummary);
