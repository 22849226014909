import React, { FC, MouseEvent as ReactMouseEvent } from "react";
import * as constants from "../../../constants/global.constants";
import logo from "../../../assets/images/common/logo.svg";
import Img from "../img";

import styles from "./logo.module.scss";

export interface LogoProps {
    id: string;
    classes?: string;
    onClick: (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const Logo: FC<LogoProps> = ({ id, onClick, classes }) => {
    return (
        <a
            id={id}
            href={`/${id.toLocaleLowerCase()}`}
            onClick={onClick}
            className={`${styles.logoContainer} ${classes ? classes : ""}`}
        >
            <Img src={logo} alt={constants.SBG_HUB} classes={styles.logo}></Img>
            <div className={styles.label}>{constants.SBG_HUB}</div>
        </a>
    );
};

export default Logo;
