import * as types from "./types";

const loginReducer = (state: types.State, action: types.Action) => {
    switch (action.type) {
        case types.LOGIN_REQUEST:
            return {
                loading: false
            };
        case types.LOGIN_SUCCESS:
            return {
                loading: false,
                user: action.user
            };
        case types.LOGIN_FAILURE:
            return {
                loading: false,
                error: action.error
            };
        case types.LOG_OUT_REQUEST:
            return {
                loading: true
            };
        case types.LOG_OUT_FAILURE:
            return {
                loading: false,
                logOutError: action.logOutError
            };
        case types.LOG_OUT_SUCCESS:
            return {
                loading: false,
                user: action.user
            };
        case types.USER_REQUEST:
            return {
                loading: true
            };
        case types.USER_SUCCESS:
            return {
                loading: false,
                user: action.user
            };
        case types.USER_FAILURE:
            return {
                loading: false,
                userError: action.userError
            };
        case types.USERS_RENEW_REQUEST:
            return {
                loading: true
            };
        case types.USERS_RENEW_SUCCESS:
            return {
                loading: false,
                user: action.user
            };
        case types.USERS_RENEW_FAILURE:
            return {
                loading: false,
                renewError: action.renewError
            };
        default: {
            throw new Error(`Unhandled action type`);
        }
    }
};

export default loginReducer;
