import { useCallback } from "react";
import * as types from "./types";
import PlayerReducer from "./reducer";
import { usePlayerDispatch, usePlayerState } from "./context";

const usePlayer = ({ reducer = PlayerReducer } = {}) => {
    const dispatch = usePlayerDispatch();
    const { ready, source, thumbnails, startTime, syncPoints, audioTrack } = usePlayerState();

    const setReady = (ready: boolean) => {
        dispatch({ type: types.SET_PLAYER_READY, ready: ready });
    };

    const setCanPlay = (canPlay: boolean) => {
        dispatch({ type: types.SET_PLAYER_CAN_PLAY, canPlay: canPlay });
    };

    const setSource = useCallback(
        (sourceId, source: string) => {
            dispatch({ type: types.SET_PLAYER_SOURCE, source: { id: sourceId, src: source } });
        },
        [dispatch]
    );

    const setAudioTrack = useCallback(
        (id) => {
            dispatch({ type: types.SET_PLAYER_AUDIO_TRACK, audioTrack: { id } });
        },
        [dispatch]
    );

    const setThumbnails = useCallback(
        (thumbnails: string[]) => {
            dispatch({ type: types.SET_PLAYER_THUMBNAILS, thumbnails: thumbnails });
        },
        [dispatch]
    );

    const setStartTime = useCallback(
        (startTime: number) => {
            dispatch({ type: types.SET_PLAYER_START_TIME, startTime: startTime });
        },
        [dispatch]
    );

    const setSyncPoints = useCallback(
        (syncPoints: types.SyncPoints[]) => {
            dispatch({ type: types.SET_PLAYER_SYNC_POINTS, syncPoints: syncPoints });
        },
        [dispatch]
    );

    return {
        ready,
        source,
        thumbnails,
        startTime,
        syncPoints,
        audioTrack,
        setReady,
        setCanPlay,
        setSource,
        setAudioTrack,
        setThumbnails,
        setStartTime,
        setSyncPoints
    };
};

export { usePlayer };
