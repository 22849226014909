import React, { FC, FormEvent } from "react";
import Player from "../common/player";
import FixtureFiles from "../fixtureFiles";
import FixturePlayerControls from "../fixturePlayerControls";
import { FixtureFiles as FixtureFilesType } from "../../utils/hooks/useFixtureFootage";

import styles from "./fixturePlayer.module.scss";
import {Official} from "../../utils/hooks/useFixture";

export interface FixturePlayerProps {
    fileId: string;
    footage: FixtureFilesType[];
    onSourceChange: (id: string) => void;
    onToolChange: (event: FormEvent<EventTarget>) => void;
    onMenuSelection: (target: string, id: string) => void;
    officials: Official[]
}

const FixturePlayer: FC<FixturePlayerProps> = ({ footage, onToolChange, onSourceChange, onMenuSelection, officials }) => {
    return (
        <div className={styles.parentContainer}>
            <div className={styles.childContainer}>
                <div className={styles.content}>
                    <Player id={"player"}/>
                    <FixturePlayerControls onClick={onToolChange}/>
                    <div className={styles.bottomContent}>
                        <div className={styles.officials}>
                            {officials.map((official, index) => (
                                <div className={styles.official} key={index}>
                                    <div
                                        className={styles.position}>{convertOfficialToFriendlyName(official.position)}</div>
                                    <div
                                        className={styles.name}>{official.first_name} {official.last_name}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <FixtureFiles files={footage} onClick={onSourceChange}
                          onClickOption={onMenuSelection}></FixtureFiles>
        </div>
    );
};

function convertOfficialToFriendlyName(position: string): string {
    // Replace underscores with spaces
    let friendlyName = position.replace(/_/g, ' ');

    // Convert to title case
    friendlyName = friendlyName.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');

    return friendlyName;
}

export default FixturePlayer;
