import React, { createContext, useReducer, useContext } from "react";
import { Dispatch, State, FiltersProviderProps } from "./types";
import { getDateAtEndOfDay } from "../../utils/date";
import filtersReducer from "./reducer";

const FiltersStateContext = createContext<State | undefined>(undefined);
const FiltersDispatchContext = createContext<Dispatch | undefined>(undefined);

const FiltersProvider = ({ children }: FiltersProviderProps) => {
    const [state, dispatch] = useReducer(filtersReducer, {
        dateOrder: "DESC",
        maxDate: getDateAtEndOfDay(),
        teams: [],
        statuses: [],
        competitions: []
    });
    return (
        <FiltersStateContext.Provider value={state}>
            <FiltersDispatchContext.Provider value={dispatch}>{children}</FiltersDispatchContext.Provider>
        </FiltersStateContext.Provider>
    );
};

const useFiltersState = () => {
    const context = useContext(FiltersStateContext);
    if (context === undefined) {
        throw new Error("useFiltersState must be used within a FiltersProvider");
    }
    return context;
};

const useFiltersDispatch = () => {
    const context = useContext(FiltersDispatchContext);
    if (context === undefined) {
        throw new Error("useFiltersDispatch must be used within a FiltersProvider");
    }
    return context;
};

export { FiltersProvider, useFiltersState, useFiltersDispatch };
