import { FC } from "react";
import Nav from "../../common/nav";
import UsersList from "../../usersList";

import styles from "./adminPage.module.scss";

export interface AdminPageProps {}

const AdminPage: FC<AdminPageProps> = () => {
    return (
        <div className={styles.parentContainer}>
            <div className={styles.childContainer}>
                <Nav classes={styles.nav} />
                <UsersList></UsersList>
            </div>
        </div>
    );
};

export default AdminPage;
