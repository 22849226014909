import React, { FC, FormEvent } from "react";
import * as constants from "../../constants/admin.constants";
import Button from "../common/button";

import styles from "./addUserNavigation.module.scss";

export interface AddUserNavigationProps {
    disabled?: boolean;
    onClick: (event: FormEvent<EventTarget>) => void;
}

const AddUserNavigation: FC<AddUserNavigationProps> = ({ disabled, onClick }) => {
    return (
        <div className={styles.navigationContainer}>
            <div className={styles.navigation}>
                <Button
                    name={constants.NEXT_PAGE}
                    type={"submit"}
                    classes={"btn-green"}
                    disabled={disabled}
                    onClick={onClick}
                ></Button>
            </div>
        </div>
    );
};

export default AddUserNavigation;
