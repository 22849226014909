import React, { FC } from "react";
import Player from "../common/player";

import styles from "./fixtureEditor.module.scss";

export interface FixtureEditorProps {
    onCancel?: () => void;
    onSave?: () => void;
}

const FixtureEditor: FC<FixtureEditorProps> = ({ onCancel, onSave }) => {
    return (
        <div className={styles.parentContainer}>
            <div className={styles.childContainer}>
                <div className={styles.editorContainer}>
                    <Player id={"player"} onCancel={onCancel} onSave={onSave}></Player>
                </div>
            </div>
        </div>
    );
};

export default FixtureEditor;
