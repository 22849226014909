import { useEffect, useState } from "react";
import { filesService } from "../../services/files.service";

export interface file {
    location: string;
}

export interface UseSignedCookie {
    file: string;
    loading: boolean;
    error: any;
}

const useSignedCookie = (fileId: string): UseSignedCookie => {
    const [file, setFile] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const getFile = async () => {
            if (!fileId || parseInt(fileId) <= 0) {
                return;
            }

            setLoading(true);
            setError(false);

            try {
                const response = await filesService.getFileSignedURL(fileId);
                if (response) setFile(response.location);
            } catch (error: any) {
                setError(error);
            }
            setLoading(false);
        };

        getFile();
    }, [fileId]);

    return { file, loading, error };
};

export default useSignedCookie;
