import { FC } from "react";
import DateRangePickerTemplate, {
    DateRangepickerProps as DateRangePickerTemplateProps
} from "@sbgsportssoftware/date-range-picker";

export type DateRangePickerProps = DateRangePickerTemplateProps;

const DateRangePicker: FC<DateRangePickerTemplateProps> = ({ ...props }) => {
    return <DateRangePickerTemplate {...props} />;
};

export default DateRangePicker;
