import { FC } from "react";
import Title from "../common/title";
import * as constants from "../../constants/admin.constants";
import AutoComplete, { Item } from "../common/autoComplete";
import { setRefComms } from "../../store/role/types";

import styles from "./editUserRole.module.scss";
import Checkbox from "../common/checkbox";
import ProtectedComponent from "../common/protectedComponent";

export interface EditUserRoleProps {
    role: string;
    hasCommsAccess: boolean;
    onSelect: (item: Item | null) => void;
    onChangeCommsAccess: (hasCommsAccess: boolean) => void;
}

const EditUserRole: FC<EditUserRoleProps> = ({ role, hasCommsAccess, onSelect, onChangeCommsAccess }) => {
    return (
        <div className={styles.formGroup}>
            <Title label={constants.USER_ROLE} classes={`heading ${styles.heading}`}></Title>
            <div className={styles.formFields}>
                <AutoComplete
                    id={constants.USER_ROLE_ID}
                    label={constants.USER_ROLE}
                    items={constants.USER_ROLES}
                    selectedItem={constants.USER_ROLES.find((userRole) => userRole.id === role) || null}
                    classes={styles.inputContainer}
                    onSelect={onSelect}
                ></AutoComplete>
            </div>
            <ProtectedComponent roles={setRefComms}>
                <div className={styles.row}>
                    <div>Can view referee communications</div>
                    <Checkbox checked={hasCommsAccess} onChange={(val) => onChangeCommsAccess(val.target.checked)}/>
                </div>
            </ProtectedComponent>
        </div>
    );
};

export default EditUserRole;
