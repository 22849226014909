import React, { FC, useState } from "react";
import { omit } from "lodash";
import Title from "../common/title";
import Input from "../common/input";
import Button from "../common/button";
import { validateInput } from "../../utils/validateInput";
import * as constants from "../../constants/login.constants";
import * as globalConstants from "../../constants/global.constants";
import useForgotPassword from "../../utils/hooks/useForgotPassword";

import styles from "./forgotPassword.module.scss";

export interface ForgotPasswordProps {}

const ForgotPassword: FC<ForgotPasswordProps> = () => {
    const [credentials, setCredentials] = useState({ email: "" });
    const [ForgotPasswordErrors, setForgotPasswordErrors] = useState({});

    const { error, forgotPassword } = useForgotPassword();

    const setData = (name: string, value: string): void => {
        setCredentials({
            ...credentials,
            [name]: value
        });
    };

    const getError = (prop: string): string => {
        const errors: { [index: string]: any } = ForgotPasswordErrors;

        if (errors[prop]) {
            return errors[prop]["message"];
        }
        if (prop === constants.EMAIL && error) {
            return error?.data?.errors?.[0];
        }
        return "";
    };

    const setError = (prop: string, error: { [index: string]: any }): void => {
        if (error) {
            setForgotPasswordErrors({ ...ForgotPasswordErrors, [prop]: error[0] });
        } else {
            setForgotPasswordErrors(omit(ForgotPasswordErrors, [prop]));
        }
    };

    const setAllErrors = (validationErrors: Array<object>) => {
        const errors: { [index: string]: any } = {};

        if (validationErrors) {
            validationErrors.forEach((error: { [index: string]: any }) => {
                const prop = error["dataPath"].split("/").pop();
                errors[prop] = [error][0];
            });

            setForgotPasswordErrors(errors);
        }
    };

    const handleBlur = ({ currentTarget: input }: { currentTarget: any }): void => {
        const { id: name, value } = input;
        const error = validateInput(constants.forgotPasswordSchema["properties"][name], value);
        setError(name, error);
    };

    const handleChange = ({ currentTarget: input }: { currentTarget: any }) => {
        const { id: name, value } = input;
        setData(name, value);
    };

    const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
        e.preventDefault();
        const errors = validateInput(constants.forgotPasswordSchema, credentials);
        setAllErrors(errors);
        if (errors) return;
        forgotPassword(credentials.email);
    };

    return (
        <form className={styles.container}>
            <div className={styles.title}>
                <Title label={constants.FORGOT_PASSWORD_TITLE} classes={`title float-top ${styles.resetTitle}`}></Title>
            </div>
            <div className={styles.inputGroup}>
                <Input
                    id={constants.EMAIL}
                    label={constants.EMAIL}
                    value={credentials.email}
                    classes={styles.inputContainer}
                    errorClasses={styles.inputError}
                    autoComplete={"on"}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={getError(constants.EMAIL)}
                />
            </div>
            <Button
                name={globalConstants.CONTINUE}
                onClick={handleSubmit}
                type={"submit"}
                classes={`btn-black btn-rounded-sm ${styles.submit}`}
            ></Button>
        </form>
    );
};

export default ForgotPassword;
