import React, { FC, FormEvent, Fragment, useState } from "react";
import Dropdown from "../common/dropdown";
import * as roles from "../../store/role/types";
import * as permissions from "../../store/role/types";
import ConfirmationModal from "../common/confirmationModal";
import ProtectedComponent from "../common/protectedComponent";
import * as constants from "../../constants/fixtures.constants";
import { FixtureStatus as FixtureStatusType } from "../../utils/hooks/useFixtures";

import styles from "./manageFixture.module.scss";

export interface ManageFixtureProps {
    competitionId: string;
    homeTeam: string;
    awayTeam: string;
    state: FixtureStatusType;
    classes: string;
    onOpen: (event: FormEvent<EventTarget>) => void;
    onClose: (event: FormEvent<EventTarget>) => void;
    onSelect: (selection: string) => void;
}

const ManageFixture: FC<ManageFixtureProps> = ({
    competitionId,
    homeTeam,
    awayTeam,
    state,
    classes,
    onOpen,
    onClose,
    onSelect
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selection, setSelection] = useState("");

    const title =
        state === "NOT_EXPECTED" ? constants.CONFIRMATION_SET_AS_EXPECTED : constants.CONFIRMATION_SET_AS_NOT_EXPECTED;
    const subtitle =
        state === "NOT_EXPECTED"
            ? constants.CONFIRMATION_SET_AS_EXPECTED_SUBTITLE.replace("{match}", `${homeTeam} vs ${awayTeam}`)
            : constants.CONFIRMATION_SET_AS_NOT_EXPECTED_SUBTITLE.replace("{match}", `${homeTeam} vs ${awayTeam}`);

    const handleShowModal = (event: FormEvent<EventTarget>) => {
        const { id: target } = event.currentTarget as Element;
        setSelection(target);
        setIsOpen(true);
    };

    const handleHideModal = () => setIsOpen(false);

    const handleConfirmModeal = () => {
        setIsOpen(false);
        onSelect(selection);
    };

    return (
        <Fragment>
            <ProtectedComponent
                roles={roles.manageFixtureRole}
                permissions={{ competitionId: competitionId || "", permissions: permissions.manageFixture }}
            >
                <div className={classes}>
                    <Dropdown
                        id={constants.MANAGE_FIXTURE}
                        icon={{ icon: { icon: ["fas", "ellipsis-v"], type: "FontAwesomeIcon", size: "1x" } }}
                        classes={`btn btn-circle-sm icon btn-white ${styles.button}`}
                        rippleColor={"black"}
                        onOpen={onOpen}
                        onClose={onClose}
                        onClick={handleShowModal}
                        tooltip={constants.MANAGE_FIXTURE}
                        suggestions={[
                            state === "NOT_EXPECTED"
                                ? constants.SET_FIXTURE_EXPECTED
                                : constants.SET_FIXTURE_NOT_EXPECTED
                        ]}
                    />
                </div>
            </ProtectedComponent>
            <ConfirmationModal
                title={title}
                subtitle={subtitle}
                icon={{ icon: ["fas", "exchange-alt"], type: "FontAwesomeIcon", size: "2x" }}
                isOpen={isOpen}
                onCancel={handleHideModal}
                onConfirm={handleConfirmModeal}
                onRequestClose={handleHideModal}
            ></ConfirmationModal>
        </Fragment>
    );
};

export default ManageFixture;
