import { useCallback, useEffect } from "react";
import * as types from "./types";
import * as constants from "../../constants/global.constants";
import progressQueueReducer from "./reducer";
import { useProgressQueueDispatch, useProgressQueueState } from "./context";

const useProgressQueue = ({ reducer = progressQueueReducer } = {}) => {
    const dispatch = useProgressQueueDispatch();
    const { items } = useProgressQueueState();

    const exitWarning = (event: BeforeUnloadEvent) => {
        event.returnValue = constants.EXIT_WARNING;
        return constants.EXIT_WARNING;
    };

    /* If items are still in progress, warn the user before exiting the page */
    useEffect(() => {
        if (items.length) {
            window.addEventListener("beforeunload", exitWarning);
        }

        return () => {
            window.removeEventListener("beforeunload", exitWarning);
        };
    }, [items]);

    const addItem = useCallback(
        (item: types.Item) => {
            dispatch({ type: types.ADD_ITEM, item });
        },
        [dispatch]
    );

    const removeItem = useCallback(
        (id: types.Item["id"]) => {
            dispatch({ type: types.REMOVE_ITEM, id });
        },
        [dispatch]
    );

    const clearQueue = useCallback(() => {
        dispatch({ type: types.CLEAR_QUEUE });
    }, [dispatch]);

    const setStats = useCallback(
        (id: types.Item["id"], stats: types.Item["stats"]) => {
            dispatch({ type: types.SET_STATS, id, stats });
        },
        [dispatch]
    );

    return { items, addItem, removeItem, clearQueue, setStats };
};

export { useProgressQueue };
