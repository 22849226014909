import { camelCase, kebabCase } from "lodash";
import * as constants from "../constants/global.constants";

const getNumberFromString = (string: string): Number => {
    const number = /\d+$/;
    return Number(string.match(number)?.[0]);
};

const removeNumberFromString = (string: string): string => {
    const number = /\d+$/;
    return string.replace(number, "");
};

const removeFileExtensionFromString = (string: string): string => {
    return string.replace(/\.[^/.]+$/, "");
};

const removeUnderscores = (string: string): string => {
    return string.replace(/_/g, " ");
};

const setCamelCase = (string: string): string => {
    return camelCase(string);
};

const setKebabCase = (string: string): string => {
    return kebabCase(string);
};

const formatTime = (seconds: number): string => {
    if (seconds < 0) return constants.CALCULATING_TIME_LEFT;

    if (seconds < 60) return constants.SECONDS_LEFT.replace("{seconds}", Math.round(seconds).toString());
    if (seconds < 3600) return constants.MINUTES_LEFT.replace("{minutes}", Math.round(seconds / 60).toString());

    return constants.HOURS_LEFT.replace("{hours}", Math.round(seconds / 3600).toString());
};

const formatPercentage = (percentage: number = 0): string => {
    return `${Math.floor(percentage)}%`;
};

export {
    getNumberFromString,
    removeNumberFromString,
    removeFileExtensionFromString,
    removeUnderscores,
    setCamelCase,
    setKebabCase,
    formatTime,
    formatPercentage
};
