import React, { FC } from "react";
import Nav from "../../common/nav";
import EditUser from "../../editUser";
import Container from "../../common/container";
import { RegisterProvider } from "../../../store/register/context";

import styles from "./editUserPage.module.scss";

export interface EditUserPageProps {}

const EditUserPage: FC<EditUserPageProps> = () => {
    return (
        <Container>
            <Nav classes={styles.nav} />
            <RegisterProvider>
                <EditUser></EditUser>
            </RegisterProvider>
        </Container>
    );
};

export default EditUserPage;
