import React, { FC } from "react";
import { Role } from "../../store/role/types";

import styles from "./userRole.module.scss";

export interface UserRoleProps {
    role: Role;
}

const UserRole: FC<UserRoleProps> = ({ role }) => {
    const classes = `${styles.userRole} ${styles[role.toLocaleLowerCase()]}`;

    return <div className={classes}>{role}</div>;
};

export default UserRole;
