import React, {FC, FormEvent, useMemo} from "react";
import Input from "../common/input";
import Title from "../common/title";
import { omit } from "lodash";
import { validateInput } from "../../utils/validateInput";
import * as constants from "../../constants/admin.constants";
import defaultStyles from "./userFanId.module.scss"

export interface EditUserFanIdProps {
    fanId: string;
    onChange: (event: FormEvent<HTMLInputElement>) => void;
    fanIdErrors: { [index: string]: any };
    setFanIdErrors: ( error: { [index: string]: any }) => void;
    styles?: any;
}

const UserFanId: FC<EditUserFanIdProps> = ({ fanId, onChange, fanIdErrors, styles, setFanIdErrors}) => {
    const getError = (prop: string): string => {
        const errors: { [index: string]: any } = fanIdErrors;
        if (errors[prop]) {
            return errors[prop]["message"];
        }
        return "";
    };

    const setError = (prop: string, error: { [index: string]: any }): void => {
        if (error) {
            setFanIdErrors({ ...fanIdErrors, [prop]: error[0] });
        } else {
            setFanIdErrors(omit(fanIdErrors, [prop]));
        }
    };

    const handleBlur = ({ currentTarget: input }: { currentTarget: any }): void => {
        let { id: name, value } = input;
        value = Number(value);
        if (isNaN(value)) {
            value = "";
        }
        const error = validateInput(constants.schema["properties"][name], value);
        setError(name, error);
    };

    const styleToUse = useMemo(() => {
        return styles ?? defaultStyles;
    }, [styles]);


    return (
        <div className={styleToUse.formGroup}>
            <Title label={constants.FAN_ID} classes={`heading ${styleToUse.heading}`}></Title>
            <div className={styleToUse.formFields}>
                <Input
                    id={constants.FAN_ID_ID}
                    label={constants.FAN_ID}
                    value={fanId ?? ""}
                    classes={styleToUse.inputContainer}
                    autoComplete={"on"}
                    onBlur={handleBlur}
                    onChange={onChange}
                    error={getError(constants.FAN_ID_ID)}
                />
            </div>
        </div>
    );
};

export default UserFanId;
