import { FC, ReactNode } from "react";

import styles from "./container.module.scss";

export interface ContainerProps {
    children: ReactNode;
}

const Container: FC<ContainerProps> = ({ children }) => {
    return (
        <div className={styles.parentContainer}>
            <div className={styles.childContainer}>{children}</div>
        </div>
    );
};

export default Container;
