import React, { FC, ChangeEvent, MouseEvent as ReactMouseEvent } from "react";

import "./checkbox.scss";

export interface CheckboxProps {
    id?: string;
    label?: string;
    classes?: string;
    checked?: boolean;
    onClick?: (event: ReactMouseEvent<HTMLInputElement, MouseEvent>) => void;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox: FC<CheckboxProps> = ({ id, label, classes, checked, onClick, onChange }) => {
    return (
        <div className="checkbox-container">
            <input
                id={id}
                type="checkbox"
                data-testid="checkbox"
                checked={checked}
                className={classes}
                onChange={onChange}
                onClick={onClick}
            ></input>

            <label htmlFor={label}>{label}</label>
        </div>
    );
};

Checkbox.defaultProps = {
    classes: "checkbox"
};

export default Checkbox;
