import React, {useEffect, useCallback, useRef, useReducer, useMemo, useState} from "react";
import * as constants from "../../constants/dashboard.constants";
import * as fixturesConstants from "../../constants/fixtures.constants";
import * as types from "./types";
import { refineCompetitionsListReducer } from "./reducer";
import Button from "../common/button";
import Competition from "../competition";
import CompetitionsHeader from "../competitionsHeader";
import { Sort } from "../../store/filters/types";
import Img from "../common/img";
import spinner from "../../assets/images/common/spinner.svg";
import { useCompetitionsListFiltersState } from "../../store/competitionsListFilters/context";
import { useCompetitionAlertsType } from "../../utils/hooks/useDashboardCompetition";
import { useCompetitionAlerts } from "../../utils/hooks/useDashboardCompetitions";
import { getDateString } from "../../utils/date";

import styles from "./dashboard.module.scss";
import { DashboardExport } from "../dashboardSummary/dashboardExport";

export interface DashboardProps {
    onToggleFiltersOpen: () => void;
}

const Dashboard: React.FC<DashboardProps> = ({ onToggleFiltersOpen }) => {
    const filters = useCompetitionsListFiltersState();
    const isMounted = useRef(false);
    const [exporting, setExporting] = useState(false)

    useEffect(() => {
        if (isMounted.current) {
            dispatchRefineCompetitionsList({
                type: types.SET_FILTERS,
                filters: filters
            });
        } else {
            isMounted.current = true;
        }
    }, [filters]);

    const [refineCompetitionsList, dispatchRefineCompetitionsList] = useReducer(refineCompetitionsListReducer, {
        filters: filters
    });

    const { competitionAlerts, lastUpdated }: useCompetitionAlertsType = useCompetitionAlerts(
        refineCompetitionsList.filters
    );

    const columns = constants.DASHBOARD_COLUMNS.map((column) => ({
        ...column,
        sort: (filters[column.id] ? filters[column.id] : undefined) as Sort | undefined
    }));

    const lastUpdatedString = useMemo(() => getDateString(lastUpdated), [lastUpdated]);

    const handleExport = useCallback(async() => {
        setExporting(true)
        await DashboardExport(filters.minDate, filters.maxDate)
        setExporting(false)
    }, [filters.minDate, filters.maxDate])

    return (
        <div className={styles.dashboardContainer}>
            <div className={styles.dashboardTitle}>{constants.DASHBOARD_TITLE}</div>
            <div className={styles.lastUpdated}>{lastUpdatedString}</div>
            <div className={styles.filterControls}>
                <div className={styles.exportContainer}>
                {exporting ?
                    <Img src={spinner} alt={"Loading Spinner"} classes="logo"></Img> :
                    <Button
                        id={fixturesConstants.EXPORT}
                        tooltip={fixturesConstants.EXPORT}
                        icon={{ icon: { icon: ["fas", "file-export"], type: "FontAwesomeIcon", size: "2x" } }}
                        onClick={handleExport}
                        classes={`btn-transparent ${styles.exportButton}`}
                    />
                }
            </div>
                <Button
                    id={fixturesConstants.FILTER}
                    tooltip={fixturesConstants.FILTER}
                    icon={{ icon: { icon: ["fas", "filter"], type: "FontAwesomeIcon", size: "1x" } }}
                    onClick={onToggleFiltersOpen}
                    classes={`btn-transparent ${styles.filterButton}`}
                />
            </div>
            <div className={styles.competitionsList}>
                <CompetitionsHeader columns={columns} />
                {competitionAlerts.map((competition) => (
                    <Competition
                        key={competition.CompetitionId}
                        CompetitionId={competition.CompetitionId}
                        CompetitionName={competition.CompetitionName}
                        Status={competition.Status}
                    />
                ))}
                {!competitionAlerts.length && (
                    <div className={styles.spinner}>
                        <Img src={spinner} alt={"Loading Spinner"} classes="logo"></Img>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Dashboard;
