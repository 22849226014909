import { FC, useState } from "react";
import Title from "../common/title";
import Button from "../common/button";
import Img from "../common/img";
import { IconProps } from "../common/icon";
import { Item } from "../../store/filters/types";
import AutoComplete from "../common/autoComplete";
import useTeams, { team } from "../../utils/hooks/useTeams";
import DateRangePicker from "../common/dateRangePicker";
import { useFilters } from "../../store/filters/useFilters";
import useCompetitions from "../../utils/hooks/useCompetitions";
import * as constants from "../../constants/fixtures.constants";
import useWindowDimensions from "../../utils/hooks/useWindowDimensions";
import filtersBackground from "../../assets/images/fixtures/filtersBackground.svg";

import styles from "./filters.module.scss";

export interface FiltersProps {
    open: boolean;
    onToggleFiltersOpen: () => void;
}

interface BrowserFiltersProps {}

interface MobileFiltersProps {
    open: boolean;
    onToggleFiltersOpen: () => void;
}

const BrowserFilters: FC<BrowserFiltersProps> = () => {
    const [collapsed, setCollapsed] = useState(false);

    const handleClick = () => setCollapsed(!collapsed);

    const renderIcon = (): IconProps => {
        return {
            icon: {
                icon: collapsed ? ["fas", "chevron-right"] : ["fas", "chevron-left"],
                type: "FontAwesomeIcon"
            }
        };
    };

    return (
        <div className={`${styles.parentContainer} ${collapsed ? styles.collapsed : ""}`}>
            <div className={styles.childContainer}>
                <FiltersTitle></FiltersTitle>
                <FiltersContent></FiltersContent>
            </div>
            <div className={styles.collapseBtn}>
                <Img src={filtersBackground} alt={constants.COLLAPSE_FILTERS}></Img>
                <Button
                    tooltip={collapsed ? constants.EXPAND : constants.COLLAPSE}
                    icon={renderIcon()}
                    classes={"btn-green btn-circle-md"}
                    onClick={handleClick}
                ></Button>
            </div>
        </div>
    );
};

const MobileFilters: FC<MobileFiltersProps> = ({ open, onToggleFiltersOpen }) => {
    const { setClearAllFilters } = useFilters();

    const handleClearAllFilters = () => {
        setClearAllFilters();
        onToggleFiltersOpen();
    };

    const handleApplyFilters = () => onToggleFiltersOpen();

    return (
        <div className={`${styles.overlay} ${open ? styles.open : ""}`}>
            <div className={styles.parentContainer}>
                <div className={styles.childContainer}>
                    <FiltersTitle></FiltersTitle>
                    <FiltersContent></FiltersContent>
                    <div className={styles.confirmation}>
                        <Button
                            name={constants.CLEAR_ALL}
                            classes={`btn-green btn-outline-green ${styles.button}`}
                            onClick={handleClearAllFilters}
                        ></Button>
                        <Button
                            name={constants.DONE}
                            classes={`btn-green btn-green ${styles.button}`}
                            onClick={handleApplyFilters}
                        ></Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const FiltersTitle = () => {
    return <Title label={constants.FILTERS} classes={`subtitle ${styles.subtitle}`}></Title>;
};

const FiltersContent = () => {
    const [competitionFilterIDs, setCompetitionFilterIDs] = useState<string[]>([]);
    const [teamFilterIDs, setTeamFilterIDs] = useState<string[]>([]);
    const [filterIDs, setFilterIDs] = useState<string[]>([]);
    
    const { minDate, maxDate, teams, competitions, statuses, setDates, setTeams, setCompetitions, setStatuses } =
        useFilters();
    const { competitions: competitionList } = useCompetitions(1, 50, teamFilterIDs);
    const { teams: teamList } = useTeams(filterIDs);

    const handleSelectTeams = (items: Item[]) => {
        setTeams(items);
        const ids: string[] = [];
        (items as team[]).forEach(({ competitions }: team) => {
            ids.push(...competitions.map(y => (String(y))))
        });
        setTeamFilterIDs(ids);
        setFilterIDs([...new Set<string>([...competitionFilterIDs, ...ids])]);
    }

    const handleSelectCompetitions = (items: Item[]) => {
        setCompetitions(items);
        const ids = items?.map(x => (String(x.id))) ?? [];
        setCompetitionFilterIDs(ids);
        setFilterIDs([...new Set<string>([...teamFilterIDs, ...ids])]);
    }

    return (
        <div className={styles.parentContent}>
            <div className={styles.childContent}>
                <DateRangePicker
                    id={constants.MATCH_DATES}
                    label={constants.MATCH_DATES}
                    dates={minDate && maxDate ? [minDate, maxDate] : undefined}
                    onChange={setDates}
                ></DateRangePicker>
                <AutoComplete
                    id={constants.TEAMS}
                    label={constants.TEAMS}
                    items={teamList}
                    multiple={true}
                    selectedItems={teams}
                    onSelectItems={(items) => handleSelectTeams(items as Item[])}
                />
                <AutoComplete
                    id={constants.COMPETITIONS}
                    label={constants.COMPETITIONS}
                    items={competitionList}
                    multiple={true}
                    selectedItems={competitions}
                    onSelectItems={(items) => handleSelectCompetitions(items as Item[])}
                />
                <AutoComplete
                    id={constants.STATES}
                    label={constants.STATES}
                    items={constants.STATUS}
                    multiple={true}
                    selectedItems={statuses}
                    onSelectItems={(items) => setStatuses(items as Item[])}
                />
            </div>
        </div>
    );
};

const Filters: FC<FiltersProps> = ({ open, onToggleFiltersOpen }) => {
    const { width } = useWindowDimensions();

    return width >= 992 ? <BrowserFilters /> : <MobileFilters open={open} onToggleFiltersOpen={onToggleFiltersOpen} />;
};

export default Filters;
