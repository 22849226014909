import * as constants from "./resources.constants";
import Plyr from "@sbgsportssoftware/plyr";

export const SOURCE_TYPE = "video/mp4";
export const type = "video";

export const SOURCE: Plyr.Source = {
    src: "",
    type: SOURCE_TYPE
};

export const controls = [
    "rewind",
    "play",
    "fast-forward",
    "frame-rewind",
    "frame-forward",
    "progress",
    "current-time",
    "duration",
    "mute",
    "volume",
    "settings",
    "pip",
    "airplay",
    "audio-track-selector",
    "fullscreen"
];

export const limitedControls = [
    "play",
    "progress",
    "current-time",
    "duration",
    "settings",
    "pip",
    "airplay",
    "fullscreen"
];

export const options: Plyr.Options = {
    seekTime: 1,
    debug: false,
    matchTime: true,
    tooltips: {
        controls: true
    },
    editor: {
        maxZoom: 32
    },
    previewThumbnails: {
        enableScrubbing: false
    },
    speed: {
        selected: 1,
        options: [0.5, 1, 2, 4, 8, 16]
    },
    trim: {
        closeEditor: false,
        maxTrimLength: 300 /* 5 Minutes */
    },
    hls: {
        debug: false,
        xhrSetup: (xhr: XMLHttpRequest) => {
            xhr.withCredentials = true;
            xhr.setRequestHeader("Access-Control-Allow-Headers", "Content-Type, Accept, X-Requested-With");
            xhr.setRequestHeader("Access-Control-Allow-Origin", window.location.hostname);
            xhr.setRequestHeader("Access-Control-Allow-Credentials", "true");
        }
    },
    iconUrl: constants.ICONS,
    blankUrl: constants.BLANK_VIDEO
};
