import * as types from "./types";

export const refineFixturesReducer = (state: types.State, action: types.Action) => {
    switch (action.type) {
        case "SET_PAGE_NUMBER": {
            return { ...state, pageNumber: action.pageNumber };
        }
        case "SET_FILTERS": {
            return { ...state, pageNumber: 1, filters: action.filters };
        }
    }
};
