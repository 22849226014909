import React, { FC, useState, useEffect, FormEvent } from "react";
import Error from "../common/error";
import Title from "../common/title";
import AddUserNavigation from "../addUserNavigation";
import { usersData } from "../../utils/hooks/useUsers";
import ComparisonTable from "../common/comparisonTable";
import { ADMIN_PERMISSIONS } from "../../store/role/types";
import useUpdateUser from "../../utils/hooks/useUpdateUser";
import * as constants from "../../constants/admin.constants";
import { useRegisterState, useRegisterDispatch } from "../../store/register/context";

import styles from "./addUserRole.module.scss";

export interface AddUserRoleProps {
    onNextPage: () => void;
}

const AddUserRole: FC<AddUserRoleProps> = ({ onNextPage }) => {
    const [role, setRole] = useState<usersData["role"]>();

    /* Retrieve User Id */
    const { user: currentUser } = useRegisterState();
    const userId = currentUser?.id;

    /* Update user */
    const { user, errors, updateUser } = useUpdateUser();
    const dispatch = useRegisterDispatch();

    useEffect(() => {
        if (user && !errors) {
            dispatch({ type: "UPDATE_USER", user });
            onNextPage();
        }
    }, [dispatch, onNextPage, user, errors]);

    const setError = (): string => {
        if (errors?.data) {
            return errors?.data?.errors[0];
        }
        return "";
    };

    const getUserRoles = () => {
        return constants.USER_ROLES.map((role) => {
            return role.permissions;
        });
    };

    const handleChange = (event: FormEvent<EventTarget>) => {
        const { id } = event.currentTarget as Element;
        setRole(id as usersData["role"]);
    };

    const handleSubmit = (event: FormEvent<EventTarget>) => {
        event.preventDefault();

        if (userId) updateUser({ id: userId, role: role });
    };

    return (
        <form className={styles.form}>
            <div className={styles.formGroup}>
                <Title label={constants.USER_ROLE} classes={`heading ${styles.heading}`}></Title>
                <ComparisonTable
                    columns={constants.USER_ROLES}
                    attributes={ADMIN_PERMISSIONS}
                    data={getUserRoles()}
                    onChange={handleChange}
                ></ComparisonTable>
                <Error error={setError()} classes={styles.error}></Error>
            </div>
            <AddUserNavigation disabled={!role} onClick={handleSubmit}></AddUserNavigation>
        </form>
    );
};

export default AddUserRole;
