import { FC, useEffect, useRef, Fragment } from "react";
import Plyr from "@sbgsportssoftware/plyr";
import Editor from "../editor";
import { isBrowser } from "react-device-detect";
import { AudioTrack } from "../../../store/player/types";
import MaintainAspectRatio from "../maintainAspectRatio";
import ConditionalContainer from "../conditionalContainer";
import { useEditor } from "../../../store/editor/useEditor";
import { usePlayer } from "../../../store/player/usePlayer";
import * as constants from "../../../constants/player.constants";
import usePreviousProp from "../../../utils/hooks/usePreviousProp";

import "./player.scss";

export interface PlayerProps {
    id: string;
    options?: Plyr.Options;
    onCancel?: () => void;
    onSave?: () => void;
}

const Player: FC<PlayerProps> = ({ id, options, onCancel, onSave, ...listeners }) => {
    /* Create a reference to the video player */
    let playerRef = useRef<Plyr>();

    /* Retrieve video editor changes */
    const { setReady, setCanPlay, setAudioTrack, source, thumbnails, startTime, syncPoints } = usePlayer();
    const { editor, tool: editorTool, setLoaded, updateSyncPoint, updateTrimRegion } = useEditor();

    const previousSyncPoints = usePreviousProp(editorTool.value);

    /* Create video player and set its source */
    useEffect(() => {
        /* Set player options */
        const playerOptions = options
            ? options
            : {
                  ...constants.options,
                  controls: isBrowser ? constants.controls : constants.limitedControls,
                  editor: {
                      ...constants.options.editor,
                      target: `#${id}Editor`
                  },
                  previewThumbnails: {
                      enabled: !!thumbnails?.length,
                      src: thumbnails,
                      ...constants.options.previewThumbnails
                  },
                  keyboard: {
                      global: editor.enabled
                  }
              };

        playerRef.current = new Plyr(`#${id}`, playerOptions);
        playerRef.current.source = {
            type: constants.type,
            syncPoints: syncPoints,
            sources: [{ ...constants.SOURCE, src: `${source.src}#t=${startTime}` }]
        };

        if (editor.enabled) {
            playerRef.current.editor.enter();
        } else {
            playerRef.current.editor.exit();
        }

        return () => {
            playerRef.current?.destroy();
        };
    }, [id, source, thumbnails, options, editor.enabled, startTime, syncPoints]);

    /* Update video editor: using seperate hook so we don't redraw the player for editor changes) */
    useEffect(() => {
        const { current: player } = playerRef;

        if (!player) return;

        if (editorTool.type === "TRIM") {
            player.trim.enter();
        } else {
            player.trim.exit();
        }

        //TODO: Combine subtype and value
        if (editorTool.type === "SYNC" && editorTool.subType?.length && editorTool.value) {
            Object.entries(editorTool.value).forEach(([syncPoint, value]) => {
                if (!previousSyncPoints || previousSyncPoints[syncPoint] === undefined) {
                    const name = editorTool.subType?.find((x) => x.id === syncPoint)?.name || syncPoint;
                    player.markers.addMarker(syncPoint, name, value);
                }
            });
        } else {
            player.markers.removeMarkers();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editor.enabled, editorTool.type, editorTool.subType]);

    /* Setup event listeners */
    useEffect(() => {
        if (!playerRef.current) return;

        playerRef.current.on("ready", () => setReady(true));

        playerRef.current.on("canplay", () => setCanPlay(true));

        playerRef.current.on("destroyed", () => {
            setReady(false);
            setCanPlay(false);
        });

        (playerRef.current as any).hls.on("hlsAudioTrackSwitched", (_: string, { id: audioTrack }: AudioTrack) => {
            setAudioTrack(audioTrack);
        });

        playerRef.current.on("editorloaded", () => setLoaded(true));

        playerRef.current.on("markeradded", ({ detail }: any) => {
            const { id, time } = detail;
            updateSyncPoint(id, time);
        });

        playerRef.current.on("markerchange", ({ detail }: any) => {
            const { id, time } = detail;
            updateSyncPoint(id, time);
        });

        playerRef.current.on("trimchange", ({ detail }: any) => {
            const { startTime, endTime } = detail;
            updateTrimRegion({ startTime, endTime });
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, source, thumbnails]);

    return (
        <Fragment>
            <ConditionalContainer
                condition={editor.enabled}
                wrapper={(children) => <div className="playerContainer">{children}</div>}
            >
                <MaintainAspectRatio ratio={9 / 16}>
                    <video id={id}></video>
                </MaintainAspectRatio>
            </ConditionalContainer>
            <Editor
                id={`${id}Editor`}
                sourceId={source.id}
                playerRef={playerRef}
                onCancel={onCancel}
                onSave={onSave}
            ></Editor>
        </Fragment>
    );
};

export default Player;
