import React, { FC } from "react";
import Img from "../../common/img";
import ForgotPassword from "../../forgotPassword";
import logo from "../../../assets/images/common/logo.svg";

import styles from "./forgotPasswordPage.module.scss";

export interface ForgotPasswordPageProps {}

const ForgotPasswordPage: FC<ForgotPasswordPageProps> = () => {
    return (
        <div className={styles.parentContainer}>
            <div className={styles.childContainer}>
                <Img src={logo} alt={"Hub Logo"} classes={styles.logo}></Img>
                <ForgotPassword></ForgotPassword>
            </div>
        </div>
    );
};

export default ForgotPasswordPage;
