import React, { FC } from "react";
import AutoComplete, { AutoCompleteProps } from "../autoComplete";

export interface SelectProps extends AutoCompleteProps {}

const Select: FC<SelectProps> = ({ ...props }) => {
    return <AutoComplete readOnly={true} {...props}></AutoComplete>;
};

export default Select;
