import { State as Filters } from "../../store/filters/types";
import { useEffect, useState } from "react";
import { dashboardService } from "../../services/dashboard.service";
import { FixtureStatus } from "./useFixtures";

export interface File {
    sub_type: Angle;
    status:   State;
}

export type Angle =
    | "VIDEO_BROADCAST"
    | "VIDEO_TACTICAL"
    | "VIDEO_PANORAMIC"
    | "VIDEO_EIGHTEEN_YARD_ONE"
    | "VIDEO_EIGHTEEN_YARD_TWO"
    | "VIDEO_HIGH_BEHIND_ONE"
    | "VIDEO_HIGH_BEHIND_ONE"
    | "AUDIO_COMMS"
    | "VIDEO_BROADCAST_COMMS";

export type State =
   | "WAITING"
   | "EXPECTED"
   | "UPLOADING"
   | "TRANSCODING"
   | "MARKUP"
   | "ARCHIVED"
   | "AVAILABLE"
   | "CANCELLED"
   | "POSTPONED"
   | "ERROR"
   | "NOT_EXPECTED";

export interface FixtureData {
    id: string;
    date: string;
    home_team: string;
    away_team: string;
    files: File[];
    status: FixtureStatus;
}

export interface FixtureAlertData {
    competition_id: string;
    competition_name: string;
    page_number: number;
    page_size: number;
    page_count: number;
    fixtures: FixtureData[];
}

export interface useFixtureAlertsType {
    competitionId: string;
    competitionName: string;
    fixtures: FixtureData[];
    lastUpdated: Date;
    loading: boolean;
    hasMore: boolean;
    error: any;
}

export const useFixtureAlerts = (
    pageNumber: number,
    pageSize: number,
    competitionId: string,
    filters?: Filters
): useFixtureAlertsType => {
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [error, setError] = useState(null);
    const [competitionName, setCompetitionName] = useState<string>("");
    const [fixtures, setFixtures] = useState<FixtureData[]>([]);
    const [lastUpdated, setLastUpdated] = useState<Date>(new Date());

    useEffect(() => {
        setCompetitionName("");
        setFixtures([]);
        setLastUpdated(new Date());
    }, [filters]);

    useEffect(() => {
        const getFixtureAlerts = async () => {
            setLoading(true);
            setHasMore(true);
            setError(null);

            try {
                const response = await dashboardService.getFixtureAlerts(pageNumber, pageSize, competitionId, {
                    ...(filters?.minDate && { min_date: filters?.minDate?.toISOString() }),
                    ...(filters?.maxDate && { max_date: filters?.maxDate?.toISOString() }),
                    ...(filters?.dateOrder && { date_order: filters?.dateOrder }),
                    ...(filters?.nameOrder && { name_order: filters?.nameOrder }),
                    ...(filters?.statusesOrder && { status_order: filters?.statusesOrder }),
                    ...(filters?.competitionOrder && { competition_order: filters?.competitionOrder }),
                    ...(filters?.teams?.length && { team_id: filters?.teams?.map((team) => team.id) }),
                    ...(filters?.statuses?.length && { status: filters?.statuses?.map((status) => status.id) }),
                    ...(filters?.competitions?.length && {
                        competition_id: filters?.competitions?.map((competition) => competition.id)
                    })
                });
                if (response) {
                    setCompetitionName(response.competition_name);
                    setFixtures((fixtures) => {
                        return [...fixtures, ...response.fixtures];
                    });
                }
                if (response?.page_count <= pageNumber) setHasMore(false);
            } catch (error: any) {
                setError(error);
            }
            setLoading(false);
        };

        getFixtureAlerts();
    }, [competitionId, filters, pageNumber, pageSize]);

    return { competitionId, competitionName, fixtures, lastUpdated, loading, hasMore, error };
};
