import { useEffect, useState } from "react";
import { fixturesService } from "../../services/fixtures.service";

export interface fixture {
    id: string;
    moas_id: string;
    date: string;
    competition: competition;
    home_team: Team;
    away_team: Team;
    officials: Official[]
}

interface competition {
    id: string;
    name: string;
    short_name: string;
    opta_id: number | undefined;
}

interface Team {
    id: string;
    name: string;
    moas_id: number;
    opta_id: number | undefined;
}

export interface Official {
    fan_id: number;
    first_name: string;
    id: number;
    last_name: string;
    position: string;
}

export interface UseFixture {
    fixture: fixture | undefined;
    loading: boolean;
    error: any;
}

const useFixture = (fixtureId: string, refresh?: boolean): UseFixture => {
    const [fixture, setFixture] = useState<fixture>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const getFixture = async () => {
            if (refresh === false) return;

            setLoading(true);
            setError(false);

            try {
                const response = await fixturesService.getFixture(fixtureId);
                if (response) setFixture(response);
            } catch (error: any) {
                setError(error);
            }
            setLoading(false);
        };

        getFixture();
    }, [fixtureId, refresh]);

    return { fixture, loading, error };
};

export default useFixture;
