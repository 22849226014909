import React, { FC, useState } from "react";
import { omit } from "lodash";
import Title from "../common/title";
import Input from "../common/input";
import Button from "../common/button";
import { validateInput } from "../../utils/validateInput";
import * as constants from "../../constants/login.constants";
import useSetPassword from "../../utils/hooks/useSetPassword";
import { withRouter, RouteComponentProps } from "react-router-dom";

import styles from "./setPassword.module.scss";

interface SetPasswordParams {
    token: string;
}

export interface SetPasswordProps extends RouteComponentProps<SetPasswordParams> {}

const SetPassword: FC<SetPasswordProps> = ({
    match: {
        params: { token }
    }
}) => {
    const [credentials, setCredentials] = useState({ password: "" });
    const [SetPasswordErrors, setSetPasswordErrors] = useState({});

    const { error, setPassword } = useSetPassword();

    const setData = (name: string, value: string): void => {
        setCredentials({
            ...credentials,
            [name]: value
        });
    };

    const getError = (prop: string): string => {
        const errors: { [index: string]: any } = SetPasswordErrors;

        if (errors[prop]) {
            return errors[prop]["message"];
        }
        if (prop === constants.PASSWORD && error) {
            return error?.data?.errors?.[0];
        }
        return "";
    };

    const setError = (prop: string, error: { [index: string]: any }): void => {
        if (error) {
            setSetPasswordErrors({ ...SetPasswordErrors, [prop]: error[0] });
        } else {
            setSetPasswordErrors(omit(SetPasswordErrors, [prop]));
        }
    };

    const setAllErrors = (validationErrors: Array<object>) => {
        const errors: { [index: string]: any } = {};

        if (validationErrors) {
            validationErrors.forEach((error: { [index: string]: any }) => {
                const prop = error["dataPath"].split("/").pop();
                errors[prop] = [error][0];
            });

            setSetPasswordErrors(errors);
        }
    };

    const handleBlur = ({ currentTarget: input }: { currentTarget: any }): void => {
        const { id: name, value } = input;
        const error = validateInput(constants.setPasswordSchema["properties"][name], value);
        setError(name, error);
    };

    const handleChange = ({ currentTarget: input }: { currentTarget: any }) => {
        const { id: name, value } = input;
        setData(name, value);
    };

    const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
        e.preventDefault();
        const errors = validateInput(constants.setPasswordSchema, credentials);
        setAllErrors(errors);
        if (errors) return;
        setPassword(token, credentials.password);
    };

    return (
        <form className={styles.container}>
            <div className={styles.title}>
                <Title label={constants.SET_TITLE} classes={`title float-top ${styles.setTitle}`}></Title>
                <Title label={constants.PASSWORD_TITLE} classes={`title float-top ${styles.passwordTitle}`}></Title>
            </div>
            <div className={styles.inputGroup}>
                <Input
                    id={constants.PASSWORD}
                    label={constants.PASSWORD}
                    type={constants.PASSWORD}
                    value={credentials.password}
                    classes={styles.inputContainer}
                    errorClasses={styles.inputError}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={getError(constants.PASSWORD)}
                />
            </div>
            <Button
                id={constants.SET_PASSWORD}
                name={constants.SET_PASSWORD}
                type={"submit"}
                onClick={handleSubmit}
                classes={`btn-black btn-rounded-sm ${styles.submit}`}
            ></Button>
        </form>
    );
};

export default withRouter(SetPassword);
