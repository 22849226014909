import { useState } from "react";
import { history } from "../history";
import { authService } from "../../services/auth.services";
import { toast } from "react-toastify";
import * as constants from "../../constants/login.constants";

export interface SetPassword {
    message: "Success." | "Error.";
}

export interface SetPasswordDetails {
    token: string;
    password: string;
}

export interface UseSetPassword {
    setPassword: (password: string, token: string) => void;
    loading: boolean;
    success: boolean;
    error: any;
}

const useSetPassword = (): UseSetPassword => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const setPassword = async (token: string, password: string) => {
        if (!password || loading) {
            return;
        }

        setLoading(true);
        setSuccess(false);
        setError(false);

        try {
            await authService.setPassword({ token, password });
            setSuccess(true);
            toast.success(constants.PASSWORD_RESET_SUCCESS);
            history.push("/login");
        } catch (error: any) {
            setError(error);
        }
        setLoading(false);
    };

    return { success, loading, error, setPassword };
};

export default useSetPassword;
