import { useCallback } from "react";
import * as types from "./types";
import loginReducer from "./reducer";
import { history } from "../../utils/history";
import { useLocation } from "react-router-dom";
import { authService } from "../../services/auth.services";
import { useLoginDispatch, useLoginState } from "./context";
import {useMixPanel} from "../../logging_config/Mixpanel";
import {cleanEmail, HashString} from "../../services/string.services";

const useLogin = ({ reducer = loginReducer } = {}) => {
    const dispatch = useLoginDispatch();
    const auth = useLoginState();
    let location = useLocation();
    const mixpanel = useMixPanel()

    const login = useCallback(
        async (email: string, password: string) => {
            const request = (): types.Action => {
                return { type: types.LOGIN_REQUEST };
            };

            const success = (user: types.User): types.Action => {
                return { type: types.LOGIN_SUCCESS, user: user };
            };

            const failure = (error: types.Error): types.Action => {
                return { type: types.LOGIN_FAILURE, error };
            };

            if (auth.loading) {
                return;
            }

            dispatch(request());
            authService
                .login(email, password)
                .then((user) => {
                    dispatch(success(user));
                    const pathName = (location.state as any)?.from || "/fixtures";
                    history.replace(pathName);
                    mixpanel.identify(HashString(email).toString());
                    mixpanel.people.set({
                        $name: cleanEmail(email),
                        $email: email,
                    });
                    mixpanel.track("Login Successful", {email: email, user: user})
                })
                .catch((error) => {
                    dispatch(failure(error))
                    mixpanel.track("Login Failed", {error: error})
                });
        },
        [auth.loading, dispatch, location.state, mixpanel]
    );

    const logout = useCallback(async () => {
        const request = (): types.Action => {
            return { type: types.LOG_OUT_REQUEST };
        };

        const success = (): types.Action => {
            return { type: types.LOG_OUT_SUCCESS, user: undefined };
        };

        const failure = (logOutError: types.Error): types.Action => {
            return { type: types.LOG_OUT_FAILURE, logOutError };
        };

        if (auth.loading) {
            return;
        }

        dispatch(request());
        await authService
            .logout()
            .then(() => {
                dispatch(success());
                history.replace("/login");
                mixpanel.track("Logout Successful")
            })
            .catch((error) => dispatch(failure(error)));
    }, [auth.loading, dispatch, mixpanel]);

    const renew = useCallback(() => {
        const request = (): types.Action => {
            return { type: types.USERS_RENEW_REQUEST };
        };

        const success = (user: types.User): types.Action => {
            return { type: types.USERS_RENEW_SUCCESS, user };
        };

        const failure = (error: types.Error): types.Action => {
            return { type: types.USERS_RENEW_FAILURE, renewError: error };
        };

        dispatch(request());

        authService
            .renew()
            .then((user) => {
                dispatch(success(user));
            })
            .catch((error) => {
                dispatch(failure(error));
                logout();
            });
    }, [dispatch, logout]);

    const getUser = useCallback(async () => {
        const request = (): types.Action => {
            return { type: types.USER_REQUEST };
        };

        const success = (user: types.User): types.Action => {
            return { type: types.USER_SUCCESS, user };
        };

        const failure = (error: types.Error): types.Action => {
            renew();
            return { type: types.USER_FAILURE, userError: error };
        };

        if (auth.loading) {
            return;
        }

        dispatch(request());

        authService
            .getUserToken()
            .then((user) => dispatch(success(user)))
            .catch((error) => dispatch(failure(error)));
    }, [auth.loading, dispatch, renew]);

    return { auth, login, logout, getUser, renew };
};

export { useLogin };
