import * as types from "./types";

const progressQueueReducer = (state: types.State, action: types.Action) => {
    switch (action.type) {
        case "ADD_ITEM": {
            return { ...state, items: [...state.items, action.item] };
        }
        case "REMOVE_ITEM": {
            return { ...state, items: [...state.items.filter((item) => item.id !== action.id)] };
        }
        case "CLEAR_QUEUE": {
            return { ...state, items: [] };
        }
        case "SET_STATS": {
            return {
                ...state,
                items: [...state.items.map((item) => (item.id === action.id ? { ...item, stats: action.stats } : item))]
            };
        }
        case "SET_ACTIVE": {
            return {
                ...state,
                items: [
                    ...state.items.map((item) => (item.id === action.id ? { ...item, active: action.active } : item))
                ]
            };
        }
        default: {
            throw new Error("Unhandled action type");
        }
    }
};

export default progressQueueReducer;
