import * as types from "./types";

const progressBarReducer = (state: types.State, action: types.Action) => {
    switch (action.type) {
        case "SET_ENABLED": {
            return { ...state, enabled: action.enabled };
        }
        default: {
            throw new Error("Unhandled action type");
        }
    }
};

export default progressBarReducer;
