import * as types from "./types";

const competitionsListFiltersReducer = (state: types.State, action: types.Action) => {
    switch (action.type) {
        case "UPDATE_DATES": {
            return { ...state, minDate: action.minDate, maxDate: action.maxDate };
        }
        case "UPDATE_TEAMS": {
            return { ...state, teams: action.teams };
        }
        case "UPDATE_COMPETITIONS": {
            return { ...state, competitions: action.competitions };
        }
        case "UPDATE_STATUSES": {
            return { ...state, statuses: action.statuses };
        }
        case "UPDATE_DATES_ORDER": {
            return { ...state, dateOrder: action.dateOrder };
        }
        case "UPDATE_TEAMS_ORDER": {
            return { ...state, nameOrder: action.nameOrder };
        }
        case "UPDATE_COMPETITIONS_ORDER": {
            return { ...state, competitionOrder: action.competitionOrder };
        }
        case "UPDATE_STATUSES_ORDER": {
            return { ...state, statusesOrder: action.statusesOrder };
        }
        case "CLEAR_FILTERS": {
            return {};
        }
        case "CLEAR_ALL_BUT_DATE_FILTERS": {
            return { minDate: state.minDate, maxDate: state.maxDate };
        }
        default: {
            throw new Error(`Unhandled action type`);
        }
    }
};

export default competitionsListFiltersReducer;
