import React, { useState, memo, FormEvent } from "react";
import FixtureAngle from "../fixtureAngle";
import ManageFixture from "../manageFixture";
import useWindowDimensions from "../../utils/hooks/useWindowDimensions";
import { FixtureStatus as FixtureStatusType } from "../../utils/hooks/useFixtures";
import { Angle, File } from "../../utils/hooks/useDashboardFixture";

import styles from "./fixtureAngles.module.scss";

export interface FixtureAnglesProps {
    id: string;
    result: string;
    homeTeam: string;
    awayTeam: string;
    competition: string;
    state: FixtureStatusType;
    files: File[];
    date: string;
    shortDate: string;
    competitionId: string;
    onClick: (id: string, cid: string) => void;
    onDropdownSelection: (id: string, selection: string) => void;
}

const MobileFixture = memo<FixtureAnglesProps>(
    ({ id, result, homeTeam, awayTeam, competition, date, shortDate, competitionId, files, onClick }) => {
        const handleClick = (event: FormEvent<EventTarget>) => {
            event.preventDefault();
            onClick(id, competitionId);
        };

        return (
            <a href={`/fixtures/${id}`} className={styles.fixtureContainer} onClick={handleClick}>
                <div className={styles.date} title={date}>
                    <div>{shortDate}</div>
                </div>
                <div className={styles.fixture}>
                    <div className={styles.team}>{homeTeam}</div>
                    <div className={styles.result}>{result}</div>
                    <div className={styles.team}>{awayTeam}</div>
                </div>
                <div className={styles.competition}>{competition}</div>
                <div className={styles.fixtureStateContainer}>
                    {files.map((file) => (
                        <FixtureAngle key={file.sub_type} classes={styles.fixtureState} angle={file.sub_type as Angle} state={file.status} />
                    ))}
                </div>
            </a>
        );
    }
);

const BrowserFixture = memo<FixtureAnglesProps>(
    ({
        id,
        result,
        homeTeam,
        awayTeam,
        competition,
        date,
        shortDate,
        state,
        files,
        competitionId,
        onClick,
        onDropdownSelection
    }) => {
        const [active, setActive] = useState(false);

        const fixtureContainerClasses = `${styles.fixtureContainer} ${active ? styles.active : ""}`;

        const handleOpen = () => setActive(true);

        const handleClose = () => setActive(false);

        const handleClick = (event: FormEvent<EventTarget>) => {
            event.preventDefault();
            onClick(id, competitionId);
        };

        const handleSelect = (selection: string) => {
            onDropdownSelection(id, selection);
        };

        return (
            <div className={fixtureContainerClasses}>
                <a href={`/fixtures/${id}`} className={styles.childContainer} onClick={handleClick}>
                    <div className={styles.date} title={date}>
                        <div>{shortDate}</div>
                        <div className={styles.competition}>{competition}</div>
                    </div>
                    <div className={styles.fixture}>
                        <div className={styles.team}>{homeTeam}</div>
                        <div className={styles.result}>{result}</div>
                        <div className={styles.team}>{awayTeam}</div>
                    </div>
                    <div className={styles.fixtureStateContainer}>
                        {files.map((file) => (
                            <FixtureAngle
                                key={file.sub_type}
                                classes={styles.fixtureState}
                                angle={file.sub_type as Angle}
                                state={file.status}
                            />
                        ))}
                    </div>
                </a>
                <ManageFixture
                    competitionId={competitionId}
                    homeTeam={homeTeam}
                    awayTeam={awayTeam}
                    state={state}
                    classes={styles.dropdown}
                    onSelect={handleSelect}
                    onOpen={handleOpen}
                    onClose={handleClose}
                />
            </div>
        );
    }
);

const Fixture = memo<FixtureAnglesProps>(({ ...props }) => {
    const { width } = useWindowDimensions();

    return width >= 992 ? <BrowserFixture {...props} /> : <MobileFixture {...props} />;
});

export default Fixture;
