import { useState } from "react";
import * as download from "../download";
import { filesService } from "../../services/files.service";
import * as constants from "../../constants/fixture.constants";
import { FileType } from "./useFileUpload";
import { toast } from "react-toastify";
import { useProgressQueue } from "../../store/progressQueue/useProgressQueue";

export type Trim = FileType & {
    metadata: {
        clip_from: number;
        filename: string;
    };
};

export interface UseTrim {
    loading: boolean;
    error: any;
    setTrim: (fileId: string, startTime: number, endTime: number, name: string, audioTrackId?: number) => void;
}

const useTrim = (): UseTrim => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const { addItem, removeItem } = useProgressQueue();
    let transcodingInterval: ReturnType<typeof setTimeout>;

    const setTrim = async (fileId: string, startTime: number, endTime: number, name: string, audioTrackId?: number) => {
        const trimId = constants.TRIM_ID.replace("{id}", fileId.toString());
        const duration = endTime - startTime;

        if (!fileId.length || duration === 0) {
            return;
        }

        setLoading(true);
        addItem({
            id: trimId,
            name: constants.CREATING_CLIP_NAME.replace("{name}", name),
            active: true
        });
        setError(false);

        try {
            /* Create clip */
            const response = await filesService.createClip(fileId.toString(), startTime, duration, name, audioTrackId);
            /* Download clip once ready */
            transcodingInterval = setInterval(async () => {
                const file = await filesService.getFile(response.id.toString());
                if (file.status === "ERROR") {
                    clearInterval(transcodingInterval);
                    toast.error(constants.TRIM_ERROR);
                    setLoading(false);
                    removeItem(trimId);
                }
                if (file.status === "AVAILABLE") {
                    clearInterval(transcodingInterval);
                    const { location: Url } = await filesService.getFileSignedURL(file.id.toString());
                    download.downloadUrl(Url, name);
                    setLoading(false);
                    removeItem(trimId);
                }
            }, 3000);
        } catch (error: any) {
            setError(error);
            toast.error(constants.TRIM_ERROR);
            setLoading(false);
            removeItem(trimId);
        }
    };

    return { loading, error, setTrim };
};

export default useTrim;
