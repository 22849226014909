import { State as Filters } from "../../store/filters/types";
import { useEffect, useState } from "react";
import { dashboardService } from "../../services/dashboard.service";
import { CompetitionAlertData, useCompetitionAlertsType } from "./useDashboardCompetition";

export const useCompetitionAlerts = (filters?: Filters): useCompetitionAlertsType => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [competitionAlerts, setCompetitionAlerts] = useState<CompetitionAlertData[]>([]);
    const [lastUpdated, setLastUpdated] = useState<Date>(new Date());

    useEffect(() => {
        setCompetitionAlerts([]);
    }, [filters]);

    useEffect(() => {
        const getCompetitionAlerts = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await dashboardService.getCompetitionAlerts({
                    ...(filters?.minDate && { min_date: filters?.minDate?.toISOString() }),
                    ...(filters?.maxDate && { max_date: filters?.maxDate?.toISOString() }),
                    ...(filters?.dateOrder && { date_order: filters?.dateOrder }),
                    ...(filters?.nameOrder && { name_order: filters?.nameOrder }),
                    ...(filters?.statusesOrder && { status_order: filters?.statusesOrder }),
                    ...(filters?.competitionOrder && { competition_order: filters?.competitionOrder }),
                    ...(filters?.teams?.length && { team_id: filters?.teams?.map((team) => team.id) }),
                    ...(filters?.statuses?.length && { status: filters?.statuses?.map((status) => status.id) }),
                    ...(filters?.competitions?.length && {
                        competition_id: filters?.competitions?.map((competition) => competition.id)
                    })
                });
                if (response) {
                    setCompetitionAlerts(response || []);
                    setLastUpdated(new Date());
                }
            } catch (error: any) {
                setError(error);
            }
            setLoading(false);
        };

        getCompetitionAlerts();
    }, [filters]);

    return { competitionAlerts, lastUpdated, loading, error };
};
