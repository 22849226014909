import React, { FC, FormEvent } from "react";
import Input from "../common/input";
import Title from "../common/title";
import { omit } from "lodash";
import { validateInput } from "../../utils/validateInput";
import * as constants from "../../constants/admin.constants";

import styles from "./editUserCredentials.module.scss";

interface Credentials {
    email: string;
    [key: string]: string | number;
}

export interface EditUserCredentialsProps {
    credentials: Credentials;
    onChange: (event: FormEvent<HTMLInputElement>) => void;
    credentialErrors: { [index: string]: any };
    setCredentialErrors: (error: { [index: string]: any }) => void;
}

const EditUserCredentials: FC<EditUserCredentialsProps> = (
    { credentials, onChange, credentialErrors, setCredentialErrors }
) => {
    const getError = (prop: string): string => {
        const errors: { [index: string]: any } = credentialErrors;
        if (errors[prop]) {
            return errors[prop]["message"];
        }
        return "";
    };

    const setError = (prop: string, error: { [index: string]: any }): void => {
        if (error) {
            setCredentialErrors({ ...credentialErrors, [prop]: error[0] });
        } else {
            setCredentialErrors(omit(credentialErrors, [prop]));
        }
    };

    const handleBlur = ({ currentTarget: input }: { currentTarget: any }): void => {
        const { id: name, value } = input;
        const error = validateInput(constants.schema["properties"][name], value);
        setError(name, error);
    };

    return (
        <div className={styles.formGroup}>
            <Title label={constants.LOGIN_CREDENTIALS} classes={`heading ${styles.heading}`}></Title>
            <div className={styles.formFields}>
                <Input
                    id={constants.EMAIL}
                    label={constants.EMAIL}
                    value={credentials.email}
                    classes={styles.inputContainer}
                    autoComplete={"on"}
                    onBlur={handleBlur}
                    onChange={onChange}
                    error={getError(constants.EMAIL)}
                />
            </div>
        </div>
    );
};

export default EditUserCredentials;
