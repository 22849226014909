import React, { FC } from "react";
import CheckEmail from "../../checkEmail";

import styles from "./checkEmailPage.module.scss";

export interface CheckEmailPageProps {}

const CheckEmailPage: FC<CheckEmailPageProps> = () => {
    return (
        <div className={styles.parentContainer}>
            <div className={styles.childContainer}>
                <CheckEmail></CheckEmail>
            </div>
        </div>
    );
};

export default CheckEmailPage;
