import { useState, useEffect } from "react";
import { fixturesService } from "../../services/fixtures.service";

export type Angles =
| "VIDEO_BROADCAST"
| "VIDEO_TACTICAL"
| "VIDEO_HIGH_BEHIND_ONE"
| "VIDEO_HIGH_BEHIND_TWO"
| "VIDEO_EIGHTEEN_YARD_ONE"
| "VIDEO_EIGHTEEN_YARD_TWO"
| "VIDEO_HIGHLIGHTS"
| "VIDEO_PANORAMIC"
| "VIDEO_CLIP"
| "VIDEO_BROADCAST_COMMS"
| "AUDIO_COMMS"
| "AUDIO_CLIP";

export interface UseFixtureAngles {
    loading: boolean;
    error: any;
    angles: Angles[];
}

const useFixtureAngles = (fixtureId: string): UseFixtureAngles => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [angles, setAngles] = useState<Angles[]>([]);

    useEffect(() => {
        const getAngles = async () => {
            try {
                const response = await fixturesService.getFixtureAngles(fixtureId);
                setAngles(response);
            } catch (error: any) {
                setError(error);
                setLoading(false);
            }
            setLoading(false);
        };

        getAngles();
    }, [fixtureId]);

    return { loading, error, angles };
};

export default useFixtureAngles;
