import React, { FC } from "react";
import Img from "../../common/img";
import SetPassword from "../../setPassword";
import logo from "../../../assets/images/common/logo.svg";

import styles from "./setPasswordPage.module.scss";

export interface SetPasswordPageProps {}

const SetPasswordPage: FC<SetPasswordPageProps> = () => {
    return (
        <div className={styles.parentContainer}>
            <div className={styles.childContainer}>
                <Img src={logo} alt={"Hub Logo"} classes={styles.logo}></Img>
                <SetPassword></SetPassword>
            </div>
        </div>
    );
};

export default SetPasswordPage;
