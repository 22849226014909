import React, {FC, useEffect, useState, FormEvent, useCallback} from "react";
import {isEmpty, remove} from "lodash";
import Info from "../common/info";
import Title from "../common/title";
import Error from "../common/error";
import Button from "../common/button";
import Select from "../common/select";
import { can } from "../../store/role";
import * as roles from "../../store/role/types";
import AutoComplete, { Item } from "../common/autoComplete";
import AddUserNavigation from "../addUserNavigation";
import * as constants from "../../constants/admin.constants";
import useCompetitions from "../../utils/hooks/useCompetitions";
import { useRegisterState } from "../../store/register/context";
import useCompetitionPermissions from "../../utils/hooks/useCompetitionPermissions";

import styles from "./addUserPermissions.module.scss";
import UserFanId from "../userFanId/userFanId";
import useUpdateUser from "../../utils/hooks/useUpdateUser";

export interface AddUserPermissionsProps {
    onNextPage: () => void;
}

const AddUserPermissions: FC<AddUserPermissionsProps> = ({ onNextPage }) => {
    const [permissions, setPermissions] = useState<roles.Permissions[]>([
        { competition_id: "", competition_name: "", permission: ""}
    ]);
    const{ updateUser } = useUpdateUser();

    const [fanId, setFanId] = useState("");
    const [fanIdErrors, setFanIdErrors] = useState({});

    /* Retrieve User Id */
    const { user: currentUser } = useRegisterState();
    const userId = currentUser?.id;
    const userRole = currentUser?.role;

    /* Fetch competitions list */
    const { competitions } = useCompetitions();

    /* Update user permissions*/
    const { user, errors, setCompetitionPermissions } = useCompetitionPermissions();

    useEffect(() => {
        if (user && !errors) {
            onNextPage();
        }
    }, [onNextPage, user, errors]);

    const setError = useCallback((): string => {
        if (errors?.data) {
            return errors?.data?.errors[0];
        }
        return "";
    }, [errors]);

    const handleDisabled = useCallback((): boolean => {
        return !(userRole && can(userRole, roles.setPerCompetitionPermissions));
    }, [userRole]);

    const handleSelectCompetition = useCallback((item: Item | null, index: number) => {
        let permissionsClone = [...permissions];
        permissionsClone[index].competition_id = (item?.id as string) || "";
        permissionsClone[index].competition_name = item?.name || "";
        setPermissions(permissionsClone);
    }, [permissions]);

    const handleSelectRole = useCallback((item: Item | null, index: number) => {
        let permissionsClone = [...permissions];
        permissionsClone[index].permission = item?.id as roles.Permission;
        setPermissions(permissionsClone);
    }, [permissions]);

    const handleSubmit = useCallback((event: FormEvent<EventTarget>) => {
        event.preventDefault();

        if (!userId) return;

        let fanIdToUse = fanId === "" || isNaN(Number(fanId)) ? null : Number(fanId);

        if (fanIdToUse && userId) {
            updateUser({
                id: userId,
                fan_id: fanIdToUse
            }, undefined, true);
        }

        /* Append user Id to the permissions */
        let permissionsWithUserId: roles.SetPermissions[] = permissions.map((permission) => {
            return { user_id: userId, competition_id: permission.competition_id, permission: permission.permission };
        });

        /* Remove empty rows */
        remove(permissionsWithUserId, (permission) => !permission.competition_id);


        if (permissionsWithUserId.length) {
            /* Update users permissions */
            setCompetitionPermissions(permissionsWithUserId);
        } else {
            /* Skip step */
            onNextPage();
        }
    }, [fanId, onNextPage, permissions, setCompetitionPermissions, updateUser, userId])

    const handleAddRow = useCallback(() => {
        setPermissions([...permissions, { competition_id: "", competition_name: "", permission: ""}]);
    }, [permissions]);

    const handleFanIdChange = useCallback((event: FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLSelectElement;
        setFanId(target.value);
    }, [])

    const validateSubmit = useCallback(() => {
        return fanIdErrors && !isEmpty(fanIdErrors);
    }, [fanIdErrors])

    return (
        <form className={styles.form}>
            <div className={styles.formGroup}>
                <Title label={constants.USER_PERMISSIONS} classes={`heading ${styles.heading}`}></Title>
                <div className={handleDisabled() ? styles.adminPrivileges : ""}>
                    {!handleDisabled() &&
                        permissions.map((_, index) => {
                            return (
                                <div key={index} className={styles.formFields}>
                                    <AutoComplete
                                        id={`${constants.COMPETITION}${index}`}
                                        label={constants.COMPETITION}
                                        items={competitions}
                                        classes={styles.formField}
                                        onSelect={(item) => handleSelectCompetition(item, index)}
                                        disabled={handleDisabled()}
                                    ></AutoComplete>
                                    <Select
                                        id={`${constants.COMPETITION_ROLE}${index}`}
                                        label={constants.COMPETITION_ROLE}
                                        items={constants.COMPETITION_ROLES}
                                        onSelect={(item) => handleSelectRole(item, index)}
                                        disabled={handleDisabled()}
                                    ></Select>
                                </div>
                            );
                        })}
                    <Button
                        name={constants.ADD_PERMISSION}
                        onClick={handleAddRow}
                        classes={`btn btn-transparent ${styles.addRow}`}
                        rippleColor={"black"}
                        disabled={handleDisabled()}
                    ></Button>
                </div>
                {handleDisabled() && (
                    <Info message={constants.ADMIN_PRIVILEGES_INFO} classes={styles.adminPrivilegesInfo}></Info>
                )}
                <Error error={setError()} classes={styles.error}></Error>
            </div>
            <UserFanId
                fanId={fanId}
                onChange={handleFanIdChange}
                fanIdErrors={fanIdErrors}
                setFanIdErrors={setFanIdErrors}
                styles={styles}
            >
            </UserFanId>
            <AddUserNavigation disabled={validateSubmit()} onClick={handleSubmit}></AddUserNavigation>
        </form>
    );
};

export default AddUserPermissions;
