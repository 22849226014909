export const CUSTOMER_USER_PERMISSIONS = ["STREAM", "DOWNLOAD"];
export const CUSTOMER_ADMIN_PERMISSIONS = [...CUSTOMER_USER_PERMISSIONS, "ADD_USER", "EDIT_USER_PERMISSIONS"];
export const SUPPORT_PERMISSIONS = [...CUSTOMER_ADMIN_PERMISSIONS, "EDIT_USER_ROLE"];
export const ADMIN_PERMISSIONS = [...SUPPORT_PERMISSIONS, "EDIT_SUPPORT_OR_ADMIN"];

export type Role = "CUSTOMER_USER" | "CUSTOMER_ADMIN" | "SUPPORT" | "ADMIN";
export type Permission = "READER" | "PUBLISHER" | "COMPETITION_ADMIN";

export interface Permissions {
    competition_id: string;
    competition_name: string;
    permission: Permission | "";
}

export interface SetPermissions {
    user_id: number;
    competition_id: string;
    permission: Permission | "";
}

export interface AllowedPermissions {
    competitionId: string;
    permissions: Permission[];
}

/* Admin Page */
export const admin: Role[] = ["CUSTOMER_ADMIN", "SUPPORT", "ADMIN"];

/* Add User */
export const setCredentials: Role[] = ["CUSTOMER_ADMIN", "SUPPORT", "ADMIN"];
export const setCustomer: Role[] = ["ADMIN"];
export const setRole: Role[] = ["SUPPORT", "ADMIN"];
export const setPermissions: Role[] = ["CUSTOMER_ADMIN", "SUPPORT", "ADMIN"];

/* Roles which support setting of per competition permissions */
export const setPerCompetitionPermissions: Role[] = ["CUSTOMER_USER"];

/* Permissions which support video upload */
export const uploadRole: Role[] = ["CUSTOMER_ADMIN", "SUPPORT", "ADMIN"];
export const upload: Permission[] = ["PUBLISHER", "COMPETITION_ADMIN"];

/* Permissions to sync video */
export const syncRole: Role[] = ["CUSTOMER_ADMIN", "SUPPORT", "ADMIN"];
export const sync: Permission[] = ["PUBLISHER", "COMPETITION_ADMIN"];

/* Permissions which support deletion of files */
export const manageFileRole: Role[] = ["CUSTOMER_ADMIN", "SUPPORT", "ADMIN"];
export const manageFile: Permission[] = ["COMPETITION_ADMIN"];

/* Permissions to manage a fixtue */
export const manageFixtureRole: Role[] = ["CUSTOMER_ADMIN", "SUPPORT", "ADMIN"];
export const manageFixture: Permission[] = ["COMPETITION_ADMIN"];

/* Permissions to allow users to access referee comms */
export const setRefComms: Role[] = ["ADMIN"]
