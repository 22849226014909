/* Login Page */
export const LOGIN_TITLE = "Login.";
export const EMAIL = "email";
export const PASSWORD = "password";

const EMAIL_ERROR = "Invalid email address";
const PASSWORD_ERROR = "Invalid password";

export const loginSchema: any = {
    type: "object",
    properties: {
        email: {
            type: "string",
            format: "email",
            minLength: 4,
            maxLength: 50,
            errorMessage: EMAIL_ERROR
        },
        password: {
            type: "string",
            minLength: 4,
            maxLength: 30,
            errorMessage: PASSWORD_ERROR
        }
    }
};

/* Forgot Password */
export const FORGOT_PASSWORD = "Forgot Password?";
export const FORGOT_PASSWORD_TITLE = "Forgot Password";

export const FORGOT_PASSWORD_SUCCESS = "An email has been sent to your account";

export const CHECK_YOUR_EMAIL = "Check Your Email.";
export const BACK_TO_LOGIN = "Back To Login.";

export const forgotPasswordSchema: any = {
    type: "object",
    properties: {
        email: {
            type: "string",
            format: "email",
            minLength: 4,
            maxLength: 50,
            errorMessage: EMAIL_ERROR
        }
    }
};

/* Set Password Page */
export const SET_TITLE = "Set";

export const PASSWORD_TITLE = "Password.";

export const TOKEN = "token";

export const PASSWORD_RESET_SUCCESS = "Password Successfully updated";

export const SET_PASSWORD = "Set Password";

export const setPasswordSchema: any = {
    type: "object",
    properties: {
        password: {
            type: "string",
            minLength: 4,
            maxLength: 30,
            errorMessage: PASSWORD_ERROR
        }
    }
};
