import React,{ createContext, useContext, ReactNode } from 'react';
import { init, Mixpanel } from 'mixpanel-browser';

const MixPanelContext = createContext({} as Mixpanel);
const useMixPanel = () => useContext(MixPanelContext);

const MixPanelProvider = ({children}: {children : ReactNode}) => {
    const isLocal = process.env.REACT_APP_ENV === 'local';
    const token = process.env.REACT_APP_ENV === 'local' ? "" : process.env.REACT_APP_MIXPANEL_TOKEN;
    const debug =  process.env.REACT_APP_ENV === 'local';
    const instance = init(token!, { test: isLocal, debug: debug }, 'hub-exchange');
    return (
        <MixPanelContext.Provider value={ instance }>
            {children}
        </MixPanelContext.Provider>
    );
};

export { useMixPanel, MixPanelContext };
export default MixPanelProvider;