import {loginSchema} from "../constants/login.constants";
import {
    CUSTOMER_USER_PERMISSIONS,
    CUSTOMER_ADMIN_PERMISSIONS,
    SUPPORT_PERMISSIONS,
    ADMIN_PERMISSIONS
} from "../store/role/types";

/* Admin Title */
export const ADMIN_TITLE = "Admin.";

export const USERNAME = "username";
export const EMAIL = "email";
export const PASSWORD = "password";
export const CUSTOMER_ID = "customer";
export const FAN_ID_ID = "fan_id";
const FAN_ID_ERROR = "Invalid Fan ID";

const fanIdSchema: any = {
    type: "object",
    properties: {
        fan_id: {
            type: "number",
            errorMessage: FAN_ID_ERROR
        }
    }
}

export const schema = {
    type: "object",
    properties: {
        ...loginSchema.properties,
        ...fanIdSchema.properties
    }
};



/* Add User */
export const ADD_USER_TITLE = "Add User.";

export const ADD_USER = "Add User";
export const USER_ROLE_ID = "role";
export const USER_ROLE = "User Role";
export const USER_PERMISSIONS = "User Permissions";
export const FAN_ID = "Fan ID";

export const USER_ROLES = [
    { id: "CUSTOMER_USER", name: "Customer User", permissions: CUSTOMER_USER_PERMISSIONS },
    { id: "CUSTOMER_ADMIN", name: "Customer Admin", permissions: CUSTOMER_ADMIN_PERMISSIONS },
    { id: "SUPPORT", name: "Support", permissions: SUPPORT_PERMISSIONS },
    { id: "ADMIN", name: "Admin", permissions: ADMIN_PERMISSIONS }
];

export const COMPETITION = "Competition";
export const COMPETITION_ROLE = "Competition Role";

export const COMPETITION_ROLES = [
    { id: "COMPETITION_ADMIN", name: "Competition Admin" },
    { id: "PUBLISHER", name: "Publisher" },
    { id: "READER", name: "Reader" }
];

export const CREDENTIALS = "Credentials";
export const CUSTOMER = "Customer";
export const ROLE = "Role";
export const PERMISSIONS = "Permissions";

export const addUserSteps = [CREDENTIALS, CUSTOMER, ROLE, PERMISSIONS];

export const LOGIN_CREDENTIALS = "Login Credentials";

/* User Controls */
export const MANAGE_USER = "Manage User";
export const EDIT_USER = "Edit User";
export const DELETE_USER = "Delete User";

/* Form Navigation */
export const PREV_PAGE = "Prev Page";
export const NEXT_PAGE = "Next Page";

/* Add Row */
export const ADD_PERMISSION = "+ Add Permission";

/* Admin Permissions */
export const ADMIN_PRIVILEGES_INFO =
    "Users with the role of admin automatically have full rights to read, publish and manage competitions";

/* Add User Successful */
export const USER_SUCCESSFULLY_ADDED = "User Successfully Addded";

/* Delete User */
export const USER_SUCCESSFULLY_DELETED = "User Successfully Deleted";
export const ERROR_DELETING_USER = "Error deleting user";

export const DELETE_USER_CONFIRMATION = "Delete {user}";

/* Edit User */
export const EDIT_USER_TITLE = "Edit User.";

export const UPDATE_USER = "Update User.";

/* Edit User Successful */
export const USER_SUCCESSFULLY_UPDATED = "User Successfully Updated";

/* Delete File */
export const FILE_SUCCESSFULLY_DELETED = "File Successfully Deleted";
export const ERROR_DELETING_FILE = "Error deleting file";
