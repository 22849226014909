import { ReactNode } from "react";
import { Role, Permissions } from "../role/types";

export const LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";

export const USER_REQUEST = "USERS_REQUEST";
export const USER_SUCCESS = "USERS_SUCCESS";
export const USER_FAILURE = "USERS_FAILURE";

export const USERS_RENEW_REQUEST = "USERS_RENEW_REQUEST";
export const USERS_RENEW_SUCCESS = "USERS_RENEW_SUCCESS";
export const USERS_RENEW_FAILURE = "USERS_RENEW_FAILURE";

export const LOG_OUT_REQUEST = "USER_LOG_OUT_REQUEST";
export const LOG_OUT_FAILURE = "USER_LOG_OUT_FAILURE";
export const LOG_OUT_SUCCESS = "USER_LOG_OUT_SUCCESS";

export interface Login {
    email: string;
    password: string;
}

export interface Error {
    data: {
        errors: string[];
        status: string;
        statusText: string;
    };
}

export interface User {
    expire: Date;
    role: Role;
    competition_permissions: Permissions[];
    token: string;
    fan_id: number | null;
}

export interface LogOut {
    message: "Success." | "Error.";
}

interface LoginRequestAction {
    type: typeof LOGIN_REQUEST;
}

interface LoginFailureAction {
    type: typeof LOGIN_FAILURE;
    error: Error;
}

interface LoginSuccessAction {
    type: typeof LOGIN_SUCCESS;
    user: User;
}

interface LogOutRequestAction {
    type: typeof LOG_OUT_REQUEST;
}

interface LogOutFailureAction {
    type: typeof LOG_OUT_FAILURE;
    logOutError: Error;
}

interface LogOutSuccessAction {
    type: typeof LOG_OUT_SUCCESS;
    user: undefined;
}

interface UserRequestAction {
    type: typeof USER_REQUEST;
}

interface UserSuccessAction {
    type: typeof USER_SUCCESS;
    user: User;
}

interface UserFailureAction {
    type: typeof USER_FAILURE;
    userError: Error;
}

interface renewRequestAction {
    type: typeof USERS_RENEW_REQUEST;
}

interface renewSuccessAction {
    type: typeof USERS_RENEW_SUCCESS;
    user: User;
}

interface renewFailureAction {
    type: typeof USERS_RENEW_FAILURE;
    renewError: Error;
}

export type Action =
    | LoginRequestAction
    | LoginFailureAction
    | LoginSuccessAction
    | LogOutRequestAction
    | LogOutFailureAction
    | LogOutSuccessAction
    | UserRequestAction
    | UserSuccessAction
    | UserFailureAction
    | renewRequestAction
    | renewSuccessAction
    | renewFailureAction;

export type Dispatch = (action: Action) => void;
export type State = {
    loading: boolean;
    user?: User;
    error?: Error;
    userError?: Error;
    renewError?: Error;
    logOutError?: Error;
};
export type loginProviderProps = { children: ReactNode };
