import React, { FC, ReactNode } from "react";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Img from "../img";

export interface IconProps {
    icon: FontAwesome | Image | Svg;
    id?: string;
    tooltip?: string;
    classes?: string;
}

interface FontAwesome {
    icon: IconProp;
    type: "FontAwesomeIcon";
    size?: SizeProp;
}

interface Image {
    icon: string;
    type: "Image";
}

interface Svg {
    icon: ReactNode;
    type: "Svg";
}

const Icon: FC<IconProps> = ({ id, icon, tooltip, classes }) => {
    const renderIcon = (icon: FontAwesome | Image | Svg) => {
        if (icon.type === "FontAwesomeIcon") {
            return <FontAwesomeIcon icon={icon.icon} size={icon?.size}></FontAwesomeIcon>;
        } else if (icon.type === "Image") {
            return <Img src={icon.icon} alt={tooltip}></Img>;
        } else if (icon.type === "Svg") {
            const { icon: Icon } = icon;
            return Icon;
        }
    };

    return (
        <div id={id} className={classes} title={tooltip}>
            {renderIcon(icon)}
        </div>
    );
};

export default Icon;
