import { useCallback, useEffect } from "react";
import { useScrollDispatch, useScrollState } from "./context";
import progressBarReducer from "./reducer";
import * as types from "./types";

const useScroll = ({ reducer = progressBarReducer } = {}) => {
    const dispatch = useScrollDispatch();
    const { enabled } = useScrollState();

    const setEnabled = useCallback(
        (enabled: boolean) => {
            dispatch({ type: types.SET_ENABLED, enabled: enabled });
        },
        [dispatch]
    );

    useEffect(() => {
        document.body.style.overflow = enabled ? "auto" : "hidden";
    }, [enabled]);

    return { enabled, setEnabled };
};

export { useScroll };
