import React, { FC, useState, ChangeEvent, MouseEvent as ReactMouseEvent } from "react";
import { Column } from "../comparisonTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Checkbox from "../checkbox";
import { startCase } from "lodash";

import styles from "./comparisonBody.module.scss";

export interface ComparisonBodyProps {
    columns: Column[];
    attributes: string[];
    data: string[][];
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ComparisonTableBody: FC<ComparisonBodyProps> = ({ columns, attributes, data, onChange }) => {
    const [selected, setSelected] = useState<string>();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (onChange) onChange(event);
    };

    const handleClick = (event: ReactMouseEvent<HTMLInputElement, MouseEvent>) => {
        const target = event.target as HTMLSelectElement;
        setSelected(target.id);
    };

    const renderContent = () => {
        return attributes.map((attribute: string, index: number) => renderRow(attribute, index));
    };

    const renderRow = (attribute: string, index: number) => {
        return (
            <tr key={index} className={styles.row}>
                {renderAttribute(attribute)}
                {columns.map((column, index) => {
                    return renderCell(attribute, index);
                })}
            </tr>
        );
    };

    const renderAttribute = (attribute: string) => {
        return <td className={styles.attributes}>{startCase(attribute.toLowerCase())}</td>;
    };

    const renderCell = (attribute: string, index: number) => {
        return (
            <td key={index}>
                {data[index].includes(attribute) && (
                    <FontAwesomeIcon icon={["fas", "check"]} className={styles.checkIcon}></FontAwesomeIcon>
                )}
            </td>
        );
    };

    const renderOptions = () => {
        return (
            <tr className={styles.row}>
                <td></td>
                {columns.map((column, index) => rendersOptionsContent(column.id, column.id === selected))}
            </tr>
        );
    };

    const rendersOptionsContent = (id: string, selected: boolean) => {
        return (
            <td key={id}>
                <Checkbox id={id} checked={selected} onClick={handleClick} onChange={handleChange}></Checkbox>
            </td>
        );
    };

    return (
        <tbody>
            {renderContent()}
            {renderOptions()}
        </tbody>
    );
};

export default ComparisonTableBody;
