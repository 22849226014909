import React, { FC, Fragment } from "react";

import styles from "./progressBar.module.scss";

export interface ProgressBarProps {
    active: boolean;
    progress: number;
    classes?: string;
}

const ProgressBar: FC<ProgressBarProps> = ({ active, progress, classes }) => {
    const renderPercentageProgressBar = () => {
        return (
            <Fragment>
                {active && (
                    <div className={`${styles.container} ${classes}`}>
                        <div className={styles.line}></div>
                        <div style={{ width: `${progress}%` }} className={styles.percentage}></div>
                    </div>
                )}
            </Fragment>
        );
    };

    const renderInfiniteProgressBar = () => {
        return (
            <Fragment>
                {active && (
                    <div className={`${styles.container} ${classes}`}>
                        <div className={styles.line}></div>
                        <div className={styles.inc}></div>
                        <div className={styles.dec}></div>
                    </div>
                )}
            </Fragment>
        );
    };

    const renderProgressBar = () => {
        return progress ? renderPercentageProgressBar() : renderInfiniteProgressBar();
    };

    return <Fragment>{renderProgressBar()}</Fragment>;
};

export default ProgressBar;
