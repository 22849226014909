import React, { createContext, useReducer, useContext } from "react";
import { Dispatch, State, loginProviderProps } from "./types";
import loginReducer from "./reducer";

const LoginStateContext = createContext<State | undefined>(undefined);
const LoginDispatchContext = createContext<Dispatch | undefined>(undefined);

const LoginProvider = ({ children }: loginProviderProps) => {
    const [state, dispatch] = useReducer(loginReducer, { loading: false });
    return (
        <LoginStateContext.Provider value={state}>
            <LoginDispatchContext.Provider value={dispatch}>{children}</LoginDispatchContext.Provider>
        </LoginStateContext.Provider>
    );
};

const useLoginState = () => {
    const context = useContext(LoginStateContext);
    if (context === undefined) {
        throw new Error("useLoginState must be used within a LoginProvider");
    }
    return context;
};

const useLoginDispatch = () => {
    const context = useContext(LoginDispatchContext);
    if (context === undefined) {
        throw new Error("useLoginDispatch must be used within a LoginProvider");
    }
    return context;
};

export { LoginProvider, useLoginState, useLoginDispatch };
