import { ReactNode } from "react";
import { SyncPoints as SyncPointsType } from "../editor/types";

export const SET_PLAYER_READY = "SET_PLAYER_READY";
export const SET_PLAYER_CAN_PLAY = "SET_PLAYER_CAN_PLAY";
export const SET_PLAYER_SOURCE = "SET_PLAYER_SOURCE";
export const SET_PLAYER_AUDIO_TRACK = "SET_PLAYER_AUDIO_TRACK";
export const SET_PLAYER_THUMBNAILS = "SET_PLAYER_THUMBNAILS";
export const SET_PLAYER_START_TIME = "SET_PLAYER_START_TIME";
export const SET_PLAYER_SYNC_POINTS = "SET_PLAYER_SYNC_POINTS";

export type SyncPoints = {
    id: SyncPointsType;
    time: number;
    start: number;
    duration: number;
    [key: string]: string | number;
};

export interface Source {
    id: string;
    src: string;
}

export interface AudioTrack {
    id: number;
}

interface SetPlayerReadyAction {
    type: typeof SET_PLAYER_READY;
    ready: boolean;
}

interface SetPlayerCanPlayAction {
    type: typeof SET_PLAYER_CAN_PLAY;
    canPlay: boolean;
}

interface SetPlayerSourceAction {
    type: typeof SET_PLAYER_SOURCE;
    source: Source;
}

interface SetPlayerAudioTrackAction {
    type: typeof SET_PLAYER_AUDIO_TRACK;
    audioTrack: AudioTrack;
}

interface SetPlayerThumbnailsAction {
    type: typeof SET_PLAYER_THUMBNAILS;
    thumbnails: string[];
}

interface SetPlayerStartTimeAction {
    type: typeof SET_PLAYER_START_TIME;
    startTime: number;
}

interface SetPlayerSyncPointsAction {
    type: typeof SET_PLAYER_SYNC_POINTS;
    syncPoints: SyncPoints[];
}

export type Action =
    | SetPlayerReadyAction
    | SetPlayerCanPlayAction
    | SetPlayerSourceAction
    | SetPlayerThumbnailsAction
    | SetPlayerStartTimeAction
    | SetPlayerSyncPointsAction
    | SetPlayerAudioTrackAction;
export type Dispatch = (action: Action) => void;
export type State = {
    ready: boolean;
    canPlay: boolean;
    source: Source;
    audioTrack?: AudioTrack;
    thumbnails?: string[];
    startTime?: number;
    syncPoints?: SyncPoints[];
};
export type PlayerProviderProps = { children: ReactNode };
