import React, { createContext, useReducer, useContext } from "react";
import { Dispatch, State, EditorProviderProps } from "./types";
import EditorReducer from "./reducer";

const EditorStateContext = createContext<State | undefined>(undefined);
const EditorDispatchContext = createContext<Dispatch | undefined>(undefined);

const EditorProvider = ({ children }: EditorProviderProps) => {
    const [state, dispatch] = useReducer(EditorReducer, {
        editor: { enabled: false, active: false },
        tool: { type: null }
    });
    return (
        <EditorStateContext.Provider value={state}>
            <EditorDispatchContext.Provider value={dispatch}>{children}</EditorDispatchContext.Provider>
        </EditorStateContext.Provider>
    );
};

const useEditorState = () => {
    const context = useContext(EditorStateContext);
    if (context === undefined) {
        throw new Error("useEditorState must be used within a EditorProvider");
    }
    return context;
};

const useEditorDispatch = () => {
    const context = useContext(EditorDispatchContext);
    if (context === undefined) {
        throw new Error("useEditorDispatch must be used within a EditorProvider");
    }
    return context;
};

export { EditorProvider, useEditorState, useEditorDispatch };
