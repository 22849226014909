import { useState, useCallback, useRef, useEffect } from "react";
import { isMobile } from "react-device-detect";

export interface UseInfiniteList {
    intersectionObserver: (entries: any) => void;
    topPadding: number;
    bottomPadding: number;
    firstIndex: number;
    currentIndex: number;
}

const useInfiniteList = (
    elements: any[],
    pageSize: number,
    loading: boolean,
    hasMore: boolean,
    error: any,
    elementHeight: number,
    loadNextPage: () => void,
    filters?: object
): UseInfiniteList => {
    const [firstIndex, setFirstIndex] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(pageSize);

    const topSent = useRef<IntersectionObserver | null>();
    const bottomSent = useRef<IntersectionObserver | null>();

    const [topPadding, setTopPadding] = useState(0);
    const [bottomPadding, setBottomPadding] = useState(0);

    let topSentinelPreviousY = useRef<number>(0);
    let topSentinelPreviousRatio = useRef<number>(0);
    let bottomSentinelPreviousY = useRef<number>(0);
    let bottomSentinelPreviousRatio = useRef<number>(0);

    const pagePadding = elementHeight * (pageSize / 2);

    useEffect(() => {
        setCurrentIndex(pageSize);
    }, [filters, pageSize]);

    const getSlidingWindow = useCallback(
        (isScrollDown: boolean) => {
            const increment = pageSize / 2;

            if (isScrollDown) {
                setFirstIndex(firstIndex + increment);
                setCurrentIndex(currentIndex + increment);
            } else {
                setFirstIndex(firstIndex - increment);
                setCurrentIndex(currentIndex - increment);
            }

            if (firstIndex < 0) {
                setFirstIndex(0);
                setCurrentIndex(firstIndex);
            }
        },
        [currentIndex, pageSize, firstIndex]
    );

    const getAllResults = useCallback(() => {
        const increment = pageSize;
        setCurrentIndex(currentIndex + increment);
    }, [currentIndex, pageSize]);

    const adjustPaddings = useCallback(
        (isScrollDown: boolean) => {
            if (isScrollDown) {
                setTopPadding(topPadding + pagePadding);
                if (bottomPadding > 0) setBottomPadding(bottomPadding - pagePadding);
            } else {
                setBottomPadding(bottomPadding + pagePadding);
                if (topPadding > 0) setTopPadding(topPadding - pagePadding);
            }
        },
        [topPadding, bottomPadding, pagePadding]
    );

    const topSentCallback = useCallback(() => {
        return new IntersectionObserver(
            (entries: IntersectionObserverEntry[]) => {
                const currentY = entries[0].boundingClientRect.top;
                const currentRatio = entries[0].intersectionRatio;
                const isIntersecting = entries[0].isIntersecting;

                if (
                    (currentY > topSentinelPreviousY.current &&
                        isIntersecting &&
                        currentRatio >= topSentinelPreviousRatio.current &&
                        firstIndex !== 0) ||
                    (currentY > topPadding && firstIndex !== 0)
                ) {
                    getSlidingWindow(false);
                    adjustPaddings(false);
                }

                topSentinelPreviousY.current = currentY;
                topSentinelPreviousRatio.current = currentRatio;

                topSent.current?.disconnect();
            },
            { threshold: 0.1 }
        );
    }, [firstIndex, topPadding, adjustPaddings, getSlidingWindow]);

    const bottomSentCallback = useCallback(() => {
        return new IntersectionObserver(
            (entries: IntersectionObserverEntry[]) => {
                const currentY = entries[0].boundingClientRect.top;
                const currentRatio = entries[0].intersectionRatio;
                const isIntersecting = entries[0].isIntersecting;

                if (
                    (currentY < bottomSentinelPreviousY.current &&
                        currentRatio > bottomSentinelPreviousRatio.current &&
                        isIntersecting) ||
                    currentY < bottomPadding
                ) {
                    if (isMobile) {
                        getSlidingWindow(true);
                        adjustPaddings(true);
                    } else {
                        getAllResults();
                    }

                    if (currentIndex === elements.length && hasMore && !error) {
                        loadNextPage();
                    }

                    bottomSent.current?.disconnect();
                }

                bottomSentinelPreviousY.current = currentY;
                bottomSentinelPreviousRatio.current = currentRatio;
            },
            { threshold: 0.1 }
        );
    }, [
        elements.length,
        hasMore,
        error,
        currentIndex,
        bottomPadding,
        loadNextPage,
        adjustPaddings,
        getSlidingWindow,
        getAllResults
    ]);

    const intersectionObserver = useCallback(
        (entries) => {
            if (loading) return;

            if (topSent.current) topSent.current.disconnect();
            if (bottomSent.current) bottomSent.current.disconnect();

            topSent.current = topSentCallback();
            bottomSent.current = bottomSentCallback();

            if (entries?.children.length >= pageSize) {
                if (isMobile) topSent.current.observe(entries.firstChild);
                bottomSent.current.observe(entries.children[entries.children.length - 4]);
            }
        },
        [loading, pageSize, topSentCallback, bottomSentCallback]
    );

    return { intersectionObserver, topPadding, bottomPadding, firstIndex, currentIndex };
};

export default useInfiniteList;
