import React, { forwardRef, FormEvent, FocusEvent } from "react";
import Icon, { IconProps } from "../icon";
import Ripple, { RippleProps } from "../ripple";

import "./button.scss";

export interface ButtonProps extends RippleProps {
    id?: string;
    name?: string;
    icon?: IconProps;
    onClick?: (() => void) | ((event: FormEvent<EventTarget>) => void);
    onMouseDown?: (() => void) | ((event: FormEvent<EventTarget>) => void);
    onBlur?: (event: FocusEvent<HTMLButtonElement>) => void;
    type?: "button" | "submit" | "reset";
    classes?: string;
    tooltip?: string;
    disabled?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        { id, name, icon, onClick, onMouseDown, onBlur, type, classes, tooltip, disabled, rippleColor = "white" },
        ref
    ) => {
        return (
            <button
                id={id}
                onClick={onClick}
                onMouseDown={onMouseDown}
                onBlur={onBlur}
                type={type}
                className={`btn ${classes}`}
                title={tooltip}
                disabled={!!disabled}
                ref={ref}
            >
                {icon && (
                    <Icon
                        id={icon.id}
                        icon={icon.icon}
                        tooltip={icon.tooltip}
                        classes={`icon ${icon.classes ? icon.classes : ""} ${name ? "left" : ""}`}
                    ></Icon>
                )}
                {name}
                <Ripple rippleColor={rippleColor}></Ripple>
            </button>
        );
    }
);

Button.defaultProps = {
    type: "button",
    classes: "btn-black"
};

export default Button;
