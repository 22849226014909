import { FC, useState, Fragment, FormEvent, useEffect, useMemo } from "react";
import { omit } from "lodash";
import * as string from "../../utils/string";
import { Item } from "../common/autoComplete";
import FileUploadModal from "../fileUploadModal";
import Button, { ButtonProps } from "../common/button";
import { validateInput } from "../../utils/validateInput";
import { FileType } from "../../utils/hooks/useFileUpload";
import * as constants from "../../constants/fixture.constants";
import useMultipartFileUpload from "../../utils/hooks/useMultiPartFileUpload";
import useFixtureAngles, { Angles } from "../../utils/hooks/useFixtureAngles";
import { useFixtureMetadata } from "../../store/fixtureMetadata/useFixtureMetadata";
import useCompetitionFeatures from "../../utils/hooks/useCompetitionFeatures";
import { FixtureFiles as FixtureFilesType } from "../../utils/hooks/useFixtureFootage";
import {useMixPanel} from "../../logging_config/Mixpanel";
import {useDebounce} from "../../hooks/use-debounce";

export type SelectedFileType = typeof constants.FOOTAGE | typeof constants.KMI;

export interface FileMetaData {
    fileName: string;
    type: FileType["file_type"];
    videoType: FileType["sub_type"];
}

export interface FileUploadProps extends ButtonProps {
    fixtureId: string;
    footage: FixtureFilesType[];
}

const FileUpload: FC<FileUploadProps> = ({ fixtureId, footage, ...rest }) => {
    /* Modal Open */
    const [isOpen, setIsOpen] = useState(false);
    const [selectedFileType, setSelectedFileType] = useState<SelectedFileType>();

    const { competitionId = "" } = useFixtureMetadata();
    const { competitionFeatures } = useCompetitionFeatures(competitionId);

    /* Retrieve available upload angles for a fixture */
    const { angles } = useFixtureAngles(fixtureId);
    const mappedAngles = useMemo(() => {
        return angles ? angles
            .map(angleId => constants.VIDEO_TYPES.find(videoType => videoType.id === angleId))
            .filter((angle): angle is { id: Angles; name: string } => angle !== undefined) : []
    }, [angles]);

    /* Uploaded file and metadata */
    const [file, setFile] = useState<File>();
    const [fileMetaData, setFileMetaData] = useState<FileMetaData>({
        fileName: "",
        type: constants.VIDEO,
        videoType: "VIDEO_BROADCAST"
    });
    const [fileMetaDataErrors, setFileMetaDataErrors] = useState({});
    const mixpanel = useMixPanel();

    /* Set default to be broadcast unless KMI */
    useEffect(() => {
        if (selectedFileType === constants.KMI) {
            setFileMetaData((fileMetaData) => ({ ...fileMetaData, videoType: "VIDEO_CLIP" }));
        } else {
            setFileMetaData((fileMetaData) => ({ ...fileMetaData, videoType: "VIDEO_BROADCAST" }));
        }
    }, [selectedFileType]);

    /* XML File */
    const [xmlFile, setXMLFile] = useState<File>();

    /* Upload file */
    const { loading, setMultipartFileUpload } = useMultipartFileUpload();

    const selectedSubTypeExists = useMemo(() => {
        if (fileMetaData.videoType === "VIDEO_CLIP") {
            return false;
        }
        if (footage.findIndex((file) => file.sub_type === fileMetaData.videoType) === -1) {
            return false;
        }

        return true;
    }, [footage, fileMetaData.videoType]);

    const handleShowModal = () => {
        setIsOpen(true);
    };
    const handleClear = () => {
        setSelectedFileType(undefined);
        setFile(undefined);
        setXMLFile(undefined);
    };

    const handleCancel = () => {
        setIsOpen(false);
        handleClear();
        mixpanel.track("File Upload - handleCancel")
    };

    const handleFileSelection = (file: File) => {
        setFile(file);
        setFileMetaData({ ...fileMetaData, fileName: string.removeFileExtensionFromString(file.name), type: file.type.includes("audio") ? "AUDIO" : "VIDEO", videoType: file.type.includes("audio") ? "AUDIO_COMMS" : "VIDEO_BROADCAST"});
        mixpanel.track("File Upload - handleFileSelection", {file: file, fileMetaData: fileMetaData, fileName: string.removeFileExtensionFromString(file.name)})
    };

    const handleFileRemoval = () => {
        setFile(undefined);
        mixpanel.track("File Upload - handleFileRemoval", {file: file})
    };

    const handleXMLFileSelection = (file: File) => {
        setXMLFile(file);
        mixpanel.track("File Upload - handleXMLFileSelection", {file: file})
    };

    const handleXMLFileRemoval = () => {
        setXMLFile(undefined);
        mixpanel.track("File Upload - handleXMLFileRemoval", {file: xmlFile})
    };

    const debounceMixPanelTracking = useDebounce((value: string) => {
        mixpanel.track("File Upload - handleFileNameChange", {file: file, fileMetaData: fileMetaData, fileName: value})
    }, 1000)

    const handleFileNameChange = ({ currentTarget: input }: { currentTarget: any }) => {
        setFileMetaData({ ...fileMetaData, fileName: input.value });
        debounceMixPanelTracking(input.value)
    };

    const handleFileTypeChange = (item: Item | null) => {
        const type: "VIDEO" | "AUDIO"  = item?.id === "AUDIO_COMMS" ? constants.AUDIO : constants.VIDEO
        const videoType = item?.id as FileMetaData["videoType"]
        setFileMetaData({
            ...fileMetaData,
            videoType: videoType,
            type: type
        });
        mixpanel.track("File Upload - handleFileTypeChange", {file: file, fileMetaData: fileMetaData, videoType: videoType, type: type})
    };

    const handleMetaDataBlur = ({ currentTarget: input }: { currentTarget: any }): void => {
        const { id: name, value } = input;
        const error = validateInput(constants.uploadFileSchema["properties"][name], value);
        setError(name, error);
        mixpanel.track("File Upload - handleMetaDataBlur", {name: name, error: error})
    };

    const setError = (prop: string, error: { [index: string]: any }): void => {
        if (error) {
            setFileMetaDataErrors({ ...fileMetaDataErrors, [prop]: error[0] });
            mixpanel.track("File Upload - setError", {fileMetadata: fileMetaDataErrors, error: error[0]})
        } else {
            setFileMetaDataErrors(omit(fileMetaDataErrors, [prop]));
        }
    };

    const handleUpload = () => {
        if (selectedFileType === constants.KMI) {
            handleKMIUpload();
        } else {
            handleFileUpload();
        }

        setIsOpen(false);
        handleClear();
    };

    const handleFileUpload = () => {
        if (!file) return;

        const { fileName, videoType, type } = fileMetaData;
        setMultipartFileUpload(fixtureId, file, { name: fileName, type: type, subType: videoType });
        mixpanel.track("File Upload - handleFileUpload", {fixtureId: fixtureId, name: fileName, type: type, subType: videoType , file: file})
    };

    const handleKMIUpload = () => {
        if (!file || !xmlFile) return;

        setMultipartFileUpload(fixtureId, file, {
            type: constants.VIDEO,
            subType: constants.VIDEO_CLIP,
            source: constants.IMG_CLIP
        });
        setMultipartFileUpload(fixtureId, xmlFile, {
            type: constants.XML,
            source: constants.IMG_CLIP
        });

        const fileToLog = file ?? xmlFile
        mixpanel.track("File Upload - handleKMIUpload", {fixtureId: fixtureId, type: constants.VIDEO, subType: constants.VIDEO_CLIP, source: constants.IMG_CLIP,  Xtype: constants.XML, file: fileToLog})
    };

    const handleSelection = (event: FormEvent<EventTarget>) => {
        const { id: target } = event.currentTarget as Element;
        setSelectedFileType(target as SelectedFileType);
        mixpanel.track("File Upload - handleSelections", {file: target})
    };

    return (
        <Fragment>
            <Button {...rest} onClick={handleShowModal}></Button>
            <FileUploadModal
                isOpen={isOpen}
                uploading={loading}
                file={file}
                xmlFile={xmlFile}
                angles={mappedAngles}
                selectedFileType={selectedFileType}
                selectedSubTypeExists={selectedSubTypeExists}
                fileMetaData={fileMetaData}
                fileMetaDataErrors={fileMetaDataErrors}
                supportsKMI={competitionFeatures?.supportsKmi || false}
                onFileSelection={handleFileSelection}
                onXMLFileSelection={handleXMLFileSelection}
                onXMLFileRemoval={handleXMLFileRemoval}
                onFileRemoval={handleFileRemoval}
                onFileNameChange={handleFileNameChange}
                onFileTypeChange={handleFileTypeChange}
                onMetaDataBlur={handleMetaDataBlur}
                onCancel={handleCancel}
                onUpload={handleUpload}
                onRequestClose={handleCancel}
                onSelection={handleSelection}
            />
        </Fragment>
    );
};

export default FileUpload;
