import React, { FC, FormEvent, useState, useEffect, Fragment } from "react";
import Icon from "../common/icon";
import Ripple from "../common/ripple";
import Dropdown from "../common/dropdown";
import FixtureState from "../fixtureState";
import * as roles from "../../store/role/types";
import * as permissions from "../../store/role/types";
import ConfirmationModal from "../common/confirmationModal";
import ProtectedComponent from "../common/protectedComponent";
import * as constants from "../../constants/fixture.constants";
import { FixtureStatus as FixtureStatusType } from "../../utils/hooks/useFixtures";
import { useFixtureMetadata } from "../../store/fixtureMetadata/useFixtureMetadata";
import { ReactComponent as UploadedToHudl } from "../../assets/images/fixture/uploadedToHudl.svg";

import styles from "./fixtureFile.module.scss";

export interface Metadata {
    progress?: number;
}

export interface FixtureFileProps {
    id: string;
    label: string;
    state: FixtureStatusType;
    selected: boolean;
    metadata?: Metadata;
    uploadedToHudl?: boolean;
    onClick: (id: string) => void;
    onClickOption: (target: string, id: string) => void;
}

const FixtureFile: FC<FixtureFileProps> = ({
    id,
    label,
    state,
    selected,
    metadata,
    uploadedToHudl,
    onClick,
    onClickOption
}) => {
    const { competitionId } = useFixtureMetadata();

    const [isOpen, setIsOpen] = useState(false);
    const [active, setActive] = useState(false);
    const [target, setTarget] = useState("");

    const fixtureFileClasses = `${styles.fixtureFile} ${selected ? styles.selected : ""} ${
        active ? styles.active : ""
    }`;

    useEffect(() => {
        if (target) {
            handleShowModal();
        } else {
            handleHideModal();
        }
    }, [target]);

    const handleOpen = () => setActive(true);

    const handleClose = () => setActive(false);

    const handleClick = (event: FormEvent<EventTarget>) => {
        const { id: target } = event.currentTarget as Element;

        if (target === constants.DELETE_FILE) {
            setTarget(target);
            return;
        }
        onClickOption(target, id);
    };

    const handleShowModal = () => setIsOpen(true);

    const handleHideModal = () => setIsOpen(false);

    const handleConfirmModal = () => {
        onClickOption(target, id);
        setTarget("");
    };

    return (
        <Fragment>
            <div id={id.toString()} className={fixtureFileClasses} onClick={() => onClick(id)}>
                <div className={styles.fixtureHeading}>
                    <div className={styles.label}>{label}</div>
                    {uploadedToHudl && (
                        <Icon
                            icon={{ icon: <UploadedToHudl />, type: "Svg" }}
                            tooltip={constants.UPLOADED_TO_HUDL}
                            classes={styles.uploadedToHudl}
                        />
                    )}
                </div>
                <div className={styles.fixtureState}>
                    <FixtureState
                        state={state}
                        classes={styles.fixtureState}
                        endAdornment={metadata?.progress ? `(${metadata?.progress}%)` : undefined}
                    />
                </div>
                <ProtectedComponent
                    roles={roles.manageFileRole}
                    permissions={{ competitionId: competitionId || "", permissions: permissions.manageFile }}
                >
                    <div className={styles.dropdown}>
                        <Dropdown
                            id={constants.MANAGE_FILE}
                            icon={{ icon: { icon: ["fas", "ellipsis-v"], type: "FontAwesomeIcon", size: "1x" } }}
                            classes={`btn btn-circle-sm icon btn-white ${styles.button}`}
                            rippleColor={"black"}
                            onClick={handleClick}
                            onOpen={handleOpen}
                            onClose={handleClose}
                            tooltip={constants.MANAGE_FILE}
                            suggestions={[constants.DOWNLOAD, constants.DELETE_FILE]}
                        />
                    </div>
                </ProtectedComponent>
                <div className={styles.rippleContainer}>
                    <Ripple rippleColor="black"></Ripple>
                </div>
            </div>
            <ConfirmationModal
                title={constants.DELETE_FILE_CONFIRMATION.replace("{file}", label)}
                icon={{ icon: ["far", "trash-alt"], type: "FontAwesomeIcon", size: "2x" }}
                isOpen={isOpen}
                onCancel={handleHideModal}
                onConfirm={handleConfirmModal}
                onRequestClose={handleHideModal}
            ></ConfirmationModal>
        </Fragment>
    );
};

export default FixtureFile;
