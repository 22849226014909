import React, { FC } from "react";
import Container from "../../common/container";
import Nav from "../../common/nav";
import AddUser from "../../addUser";
import { RegisterProvider } from "../../../store/register/context";

import styles from "./addUserPage.module.scss";

export interface AdminPageProps {}

const AdminPage: FC<AdminPageProps> = () => {
    return (
        <Container>
            <Nav classes={styles.nav} />
            <RegisterProvider>
                <AddUser></AddUser>
            </RegisterProvider>
        </Container>
    );
};

export default AdminPage;
