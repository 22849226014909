import { useEffect, useState } from "react";
import { customersService } from "../../services/customers.services";

export interface customer {
    page_number: number;
    page_size: number;
    page_count: number;
    data: [customersData];
}

export interface customersData {
    id: number;
    name: string;
}

export interface UseCustomers {
    customers: customersData[];
    loading: boolean;
    hasMore: boolean;
    error: any;
}

const useCustomers = (pageNumber: number = 1): UseCustomers => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [customers, setCustomers] = useState<customersData[]>([]);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        const getCustomers = async () => {
            setLoading(true);
            setError(false);

            try {
                const response = await customersService.getCustomers(pageNumber);
                if (response) setCustomers(response.data);
                if (response?.page_count <= pageNumber) setHasMore(false);
            } catch (error: any) {
                setError(error);
            }
            setLoading(false);
        };

        getCustomers();
    }, [pageNumber]);

    return { customers, loading, hasMore, error };
};

export default useCustomers;
