import http from "./http.services";
import * as constants from "../constants/resources.constants";
import { CompetitionFeatures as competitionFeaturesType } from "../utils/hooks/useCompetitionFeatures";

const getCompetitionFeatures = async (competitionId: string): Promise<competitionFeaturesType> => {
    const { data: competition } = await http.get(constants.COMPETITION_FEATURES.replace("{id}", competitionId), {
        withCredentials: true
    });
    return competition;
};

export const competitionFeaturesService = {
    getCompetitionFeatures
};
