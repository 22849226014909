import { FC, useMemo, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import Title from "../common/title";
import Button from "../common/button";
import Img from "../common/img";
import { IconProps } from "../common/icon";
import AutoComplete from "../common/autoComplete";
import useTeams from "../../utils/hooks/useTeams";
import DateRangePicker from "../common/dateRangePicker";
import * as constants from "../../constants/fixtures.constants";
import { Item } from "../../store/competitionsListFilters/types";
import * as globalConstants from "../../constants/global.constants";
import useWindowDimensions from "../../utils/hooks/useWindowDimensions";
import filtersBackground from "../../assets/images/fixtures/filtersBackground.svg";
import { useFilters } from "../../store/competitionsListFilters/useCompetitionsListFilters";

import styles from "../filters/filters.module.scss";

export interface CompetitionPageParams {
    competitionId: string;
}

export interface FiltersProps extends RouteComponentProps<CompetitionPageParams> {
    open: boolean;
    onToggleFiltersOpen: () => void;
}

interface BrowserFiltersProps extends CompetitionPageParams {}

interface MobileFiltersProps extends CompetitionPageParams {
    open: boolean;
    onToggleFiltersOpen: () => void;
}

const BrowserFilters: FC<BrowserFiltersProps> = ({ competitionId }) => {
    const [collapsed, setCollapsed] = useState(false);

    const handleClick = () => setCollapsed(!collapsed);

    const renderIcon = (): IconProps => {
        return {
            icon: {
                icon: collapsed ? ["fas", "chevron-right"] : ["fas", "chevron-left"],
                type: "FontAwesomeIcon"
            }
        };
    };

    return (
        <div className={`${styles.parentContainer} ${collapsed ? styles.collapsed : ""}`}>
            <div className={styles.childContainer}>
                <FiltersTitle />
                <FiltersContent competitionId={competitionId} />
            </div>
            <div className={styles.collapseBtn}>
                <Img src={filtersBackground} alt={constants.COLLAPSE_FILTERS} />
                <Button
                    tooltip={collapsed ? constants.EXPAND : constants.COLLAPSE}
                    icon={renderIcon()}
                    classes={"btn-green btn-circle-md"}
                    onClick={handleClick}
                />
            </div>
        </div>
    );
};

const MobileFilters: FC<MobileFiltersProps> = ({ open, onToggleFiltersOpen, competitionId }) => {
    const { setClearAllFilters } = useFilters();

    const handleClearAllFilters = () => {
        setClearAllFilters();
        onToggleFiltersOpen();
    };

    const handleApplyFilters = () => onToggleFiltersOpen();

    return (
        <div className={`${styles.overlay} ${open ? styles.open : ""}`}>
            <div className={styles.parentContainer}>
                <div className={styles.childContainer}>
                    <FiltersTitle />
                    <FiltersContent competitionId={competitionId} />
                    <div className={styles.confirmation}>
                        <Button
                            name={constants.CLEAR_ALL}
                            classes={`btn-green btn-outline-green ${styles.button}`}
                            onClick={handleClearAllFilters}
                        />
                        <Button
                            name={constants.DONE}
                            classes={`btn-green btn-green ${styles.button}`}
                            onClick={handleApplyFilters}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const FiltersTitle = () => {
    return <Title label={constants.FILTERS} classes={`subtitle ${styles.subtitle}`}></Title>;
};

const FiltersContent: FC<CompetitionPageParams> = ({ competitionId }) => {
    const { minDate, maxDate, teams, statuses, setDates, setTeams, setStatuses } = useFilters();
    const id = useMemo(() => ([competitionId]), [competitionId])
    const { teams: teamList } = useTeams(id);
    
    return (
        <div className={styles.parentContent}>
            <div className={styles.childContent}>
                <DateRangePicker
                    id={constants.MATCH_DATES}
                    label={constants.MATCH_DATES}
                    dates={
                        minDate && maxDate
                            ? [minDate, maxDate]
                            : [new Date(new Date().getTime() - 3 * globalConstants.DAY_IN_MS), new Date()]
                    }
                    onChange={setDates}
                />
                <AutoComplete
                    id={constants.TEAMS}
                    label={constants.TEAMS}
                    items={teamList}
                    multiple={true}
                    selectedItems={teams}
                    onSelectItems={(items) => setTeams(items as Item[])}
                />
                <AutoComplete
                    id={constants.STATES}
                    label={constants.STATES}
                    items={constants.STATUS}
                    multiple={true}
                    selectedItems={statuses}
                    onSelectItems={(items) => setStatuses(items as Item[])}
                />
            </div>
        </div>
    );
};

const DashboardSummaryFilters: FC<FiltersProps> = ({ open, onToggleFiltersOpen, match: { params } }) => {
    const { width } = useWindowDimensions();
    const competitionId = params.competitionId;
    return width >= 1121 ? <BrowserFilters competitionId={competitionId} /> : <MobileFilters competitionId={competitionId} open={open} onToggleFiltersOpen={onToggleFiltersOpen} />;
};

export default withRouter(DashboardSummaryFilters);
