import { FC } from "react";
import { SelectedFileType } from "../fileUpload";
import * as constants from "../../constants/fixture.constants";
import FileUploadSelector, { FileUploadSelectorProps } from "../fileUploadSelector";
import FileUploadContentKMIDropZone, { FileUploadContentKMIDropZoneProps } from "../fileUploadContentKMIDropZone";
import FileUploadContentDropZone, { FileUploadContentDropZoneProps } from "../fileUploadContentDropZone";

export interface FileUploadContentProps
    extends FileUploadSelectorProps,
        FileUploadContentDropZoneProps,
        FileUploadContentKMIDropZoneProps {
    supportsKMI: boolean;
    selectedFileType: SelectedFileType | undefined;
    selectedSubTypeExists: boolean;
}

const FileUploadContent: FC<FileUploadContentProps> = ({ supportsKMI, selectedFileType, onSelection, ...rest }) => {
    if (supportsKMI && !selectedFileType) {
        return <FileUploadSelector onSelection={onSelection} />;
    }

    if (supportsKMI && selectedFileType === constants.KMI) {
        return <FileUploadContentKMIDropZone {...rest} />;
    }

    return <FileUploadContentDropZone {...rest} />;
};

export default FileUploadContent;
