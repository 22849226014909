import React, { FC, ReactNode } from "react";
import ReactModal, { Props as ReactModalProps } from "react-modal";

import "./modal.scss";

export interface ModalProps extends ReactModalProps {
    children: ReactNode;
}

const Modal: FC<ModalProps> = ({ children, ...props }) => {
    ReactModal.setAppElement("#root");

    return <ReactModal {...props}>{children}</ReactModal>;
};

export default Modal;
