import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import "./assets/icons";
import "./index.scss";

import App from "./app";
import {SentryConfig} from "./logging_config/SentryConfig";
import MixPanelProvider from "./logging_config/Mixpanel";
Sentry.init(SentryConfig);

ReactDOM.render(
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
        <MixPanelProvider>
            <App />
        </MixPanelProvider>
    </Sentry.ErrorBoundary>
    , document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
