import React from "react";
import { AngleStatus } from "../../utils/hooks/useDashboardCompetition";
import Icon from "../common/icon";
import { history } from "../../utils/history";

import styles from "./competition.module.scss";

export interface CompetitionProps {
    CompetitionId: string;
    CompetitionName: string;
    Status?: AngleStatus;
    disabled?: boolean;
}

const Competition: React.FC<CompetitionProps> = ({
    CompetitionId,
    CompetitionName,
    Status,
    disabled
}) => {
    const dashboard = "/dashboard/" + CompetitionId;
    const childContainerClasses = `${styles.childContainer} ${disabled ? styles.disabled : undefined}`;

    const handleClick = () => {
        if (disabled) {
            return;
        }
        history.push({
            pathname: dashboard
        });
    };

    return (
        <div className={styles.competitionContainer}>
            <div className={childContainerClasses} onClick={handleClick}>
                <div className={styles.competition}>{CompetitionName}</div>
                <div className={styles.iconContainer}>
                    <div className={styles.available}>
                        <Icon
                            icon={{ icon: ["fas", "circle"], type: "FontAwesomeIcon", size: "xs" }}
                            classes={styles.icon}
                        />
                        <div className={styles.number}>
                            {Status?.Available || 0}
                        </div>
                    </div>
                    <div className={styles.expected}>
                        <Icon
                            icon={{ icon: ["fas", "circle"], type: "FontAwesomeIcon", size: "xs" }}
                            classes={styles.icon}
                        />
                        <div className={styles.number}>
                            {Status?.Expected || 0}
                        </div>
                    </div>
                    <div className={styles.notExpected}>
                        <Icon
                            icon={{ icon: ["fas", "circle"], type: "FontAwesomeIcon", size: "xs" }}
                            classes={styles.icon}
                        />
                        <div className={styles.number}>
                            {Status?.NotExpected}
                        </div>
                    </div>
                    <div className={styles.markup}>
                        <Icon
                            icon={{ icon: ["fas", "circle"], type: "FontAwesomeIcon", size: "xs" }}
                            classes={styles.icon}
                        />
                        <div className={styles.number}>
                            {Status?.Markup || 0}
                        </div>
                    </div>
                    <div className={styles.error}>
                        <Icon
                            icon={{ icon: ["fas", "circle"], type: "FontAwesomeIcon", size: "xs" }}
                            classes={styles.icon}
                        />
                        <div className={styles.number}>
                            {Status?.Error || 0}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Competition;
