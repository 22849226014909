import React, { FC, FormEvent } from "react";
import Button from "../button";
import * as constants from "../../../constants/fixture.constants";

import styles from "./editorTrimControls.module.scss";

export interface EditorTrimControlsProps {
    onCancel: (event: FormEvent<EventTarget>) => void;
    onSave: (event: FormEvent<EventTarget>) => void;
}

const EditorTrimControls: FC<EditorTrimControlsProps> = ({ onCancel, onSave }) => {
    return (
        <div className={styles.editorSave}>
            <Button name={constants.CANCEL} tooltip={constants.CANCEL} classes={"btn-green"} onClick={onCancel} />
            <Button name={constants.SAVE} tooltip={constants.SAVE} classes={"btn-green"} onClick={onSave} />
        </div>
    );
};

export default EditorTrimControls;
