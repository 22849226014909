import { useEffect, useState } from "react";
import { Role, Permissions } from "../../store/role/types";
import { usersService } from "../../services/users.service";

export interface users {
    page_number: number;
    page_size: number;
    page_count: number;
    data: [usersData];
}

export interface usersData {
    id: string;
    username: string;
    role: Role;
    permissions: Permissions;
}

export interface UseUsers {
    users: usersData[];
    loading: boolean;
    hasMore: boolean;
    error: any;
}

const useUsers = (pageNumber: number, pageSize: number, deletedUsers?: string[], filters?: object): UseUsers => {
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState<usersData[]>([]);
    const [hasMore, setHasMore] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        setUsers([]);
    }, [deletedUsers, filters]);

    useEffect(() => {
        const getUsers = async () => {
            setLoading(true);
            setError(false);

            try {
                const response = await usersService.getUsers(pageNumber, pageSize, filters);
                if (response)
                    setUsers((users) => {
                        return [...users, ...response.data];
                    });
                if (response?.page_count <= pageNumber) setHasMore(false);
            } catch (error: any) {
                setError(error);
            }
            setLoading(false);
        };

        getUsers();
    }, [pageNumber, pageSize, deletedUsers, filters]);

    return { users, loading, hasMore, error };
};

export default useUsers;
