import React, { FC, ReactNode, useRef, useState, useEffect } from "react";
import Button, { ButtonProps } from "../button";

import styles from "./actionButton.module.scss";

export interface ActionButtonProps extends ButtonProps {
    children?: ReactNode;
}

const ActionButton: FC<ActionButtonProps> = ({ children, onClick, ...rest }) => {
    const [isActionsShown, setIsActionsShown] = useState(false);
    const actionsRef = useRef<HTMLDivElement>(null);

    const onclick = (event: React.FormEvent<EventTarget>) => {
        setIsActionsShown(!isActionsShown);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent | TouchEvent): void => {
            const target = event.target as HTMLSelectElement;
            if (actionsRef.current && !actionsRef?.current?.contains(target) && isActionsShown) {
                setIsActionsShown(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("touchstart", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchend", handleClickOutside);
        };
    }, [actionsRef, isActionsShown]);

    return (
        <div className={styles.actionButton} ref={actionsRef} onClick={onclick}>
            {isActionsShown && <div className={styles.actions}>{children}</div>}
            <Button {...rest}></Button>
        </div>
    );
};

export default ActionButton;
