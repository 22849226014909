import React, { FC, ReactNode, Fragment } from "react";

export interface ConditionalContainerProps {
    wrapper: (children: ReactNode) => ReactNode;
    children: ReactNode;
    condition: boolean;
}

const ConditionalContainer: FC<ConditionalContainerProps> = ({ wrapper, children, condition }) => {
    return (
        <Fragment>
            {condition && wrapper(children)} {!condition && children}
        </Fragment>
    );
};

export default ConditionalContainer;
