import { Permissions, AllowedPermissions } from "./types";

const can = (role: string, allowedRoles: string[]): boolean => {
    if (!role || !allowedRoles) return true;
    return allowedRoles.includes(role);
};

const canPermissions = (permissions: Permissions[], allowedPermissions: AllowedPermissions) => {
    const permission = permissions.find((permission) => permission.competition_id === allowedPermissions.competitionId);
    return !!(permission?.permission && allowedPermissions.permissions.includes(permission.permission));
};

const canFanId = (fanId: number | null, allowedFanIds?: number[]) => {
    return fanId != null && allowedFanIds != null && allowedFanIds.includes(fanId);
}

export { can, canPermissions, canFanId };
