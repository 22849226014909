import { useState } from "react";
import * as download from "../download";
import { filesService } from "../../services/files.service";
import * as constants from "../../constants/fixture.constants";
import { toast } from "react-toastify";
import { useProgressQueue } from "../../store/progressQueue/useProgressQueue";

export interface UseCreateDownload {
    loading: boolean;
    error: any;
    setCreateDownload: (fileId: string, name: string, audioTrackId?: number) => void;
}

const useCreateDownload = (): UseCreateDownload => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const { addItem, removeItem } = useProgressQueue();
    let transcodingInterval: ReturnType<typeof setTimeout>;

    const setCreateDownload = async (fileId: string, name: string, audioTrackId: number = 0) => {
        const mediaId = constants.DOWNLOAD_ID.replace("{id}", fileId.toString());

        if (!fileId.length) {
            return;
        }

        setLoading(true);
        addItem({
            id: mediaId,
            name: constants.DOWNLOAD_MEDIA_NAME.replace("{name}", name),
            active: true
        });
        setError(false);

        try {
            /* Create clip */
            const response = await filesService.createDownload(fileId.toString(), name, audioTrackId);
            /* Download video once ready */
            transcodingInterval = setInterval(async () => {
                const file = await filesService.getFile(response.id.toString());
                if (file.status === "ERROR") {
                    clearInterval(transcodingInterval);
                    toast.error(constants.DOWNLOAD_ERROR);
                    setLoading(false);
                    removeItem(mediaId);
                }
                if (file.status === "AVAILABLE") {
                    clearInterval(transcodingInterval);
                    const { location: Url } = await filesService.getFileSignedURL(file.id.toString());
                    download.downloadUrl(Url, name);
                    setLoading(false);
                    removeItem(mediaId);
                }
            }, 3000);
        } catch (error: any) {
            setError(error);
            toast.error(constants.DOWNLOAD_ERROR);
            setLoading(false);
            removeItem(mediaId);
        }
    };

    return { loading, error, setCreateDownload };
};

export default useCreateDownload;
