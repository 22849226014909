export const SET_PAGE_NUMBER = "SET_PAGE_NUMBER";
export const SET_FILTERS = "SET_FILTERS";

interface SetPageNumberAction {
    type: typeof SET_PAGE_NUMBER;
    pageNumber: number;
}

interface SetFiltersAction {
    type: typeof SET_FILTERS;
    filters: {};
}

export type Action = SetPageNumberAction | SetFiltersAction;
export type State = { pageNumber: number; pageSize: number; filters: object };
