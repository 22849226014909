import React, { FC } from "react";
import Title from "../title";
import Button from "../button";
import * as constants from "../../../constants/global.constants";
import * as types from "../../../store/progressQueue/types";

import styles from "./progressQueueHeader.module.scss";

export interface ProgressQueueHeaderProps {
    items: types.Item[];
    collapsed: boolean;
    onCollapse: () => void;
    onClose: () => void;
}

const ProgressQueueHeader: FC<ProgressQueueHeaderProps> = ({ items, collapsed, onCollapse, onClose }) => {
    const CollapseButton = () => {
        return collapsed ? (
            <Button
                id={constants.EXPAND}
                tooltip={constants.EXPAND}
                onClick={onCollapse}
                rippleColor={"black"}
                classes={`btn btn-transparent btn-circle-md ${styles.button}`}
                icon={{ icon: { icon: ["fas", "expand-alt"], type: "FontAwesomeIcon" } }}
            />
        ) : (
            <Button
                id={constants.COLLAPSE}
                tooltip={constants.COLLAPSE}
                onClick={onCollapse}
                rippleColor={"black"}
                classes={`btn btn-transparent btn-circle-md ${styles.button}`}
                icon={{ icon: { icon: ["fas", "compress-alt"], type: "FontAwesomeIcon" } }}
            />
        );
    };

    return (
        <div className={styles.heading}>
            <div className={styles.title}>
                <Title label={constants.PROGRESS_QUEUE} classes={"heading"} />
                <div className={styles.subtitle}>
                    {constants.ITEMS_IN_PROGRESS.replace("{items}", items.length.toString())}
                </div>
            </div>
            <div className={styles.controls}>
                <CollapseButton />
                <Button
                    id={constants.CLOSE}
                    tooltip={constants.CLOSE}
                    onClick={onClose}
                    rippleColor={"black"}
                    classes={`btn btn-transparent btn-circle-md ${styles.button}`}
                    icon={{ icon: { icon: ["fas", "times"], type: "FontAwesomeIcon" } }}
                />
            </div>
        </div>
    );
};

export default ProgressQueueHeader;
