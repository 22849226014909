import http from "./http.services";
import { User } from "../store/register/types";
import { SetPermissions } from "../store/role/types";
import { COMPETITION_PERMISSIONS } from "../constants/resources.constants";

const setCompetitionPermissions = async (permissions: SetPermissions[]): Promise<User> => {
    const { data: response } = await http.post(COMPETITION_PERMISSIONS, { permissions }, { withCredentials: true });
    return response;
};

export const competitionPermissionsService = {
    setCompetitionPermissions
};
