import { useEffect, useState } from "react";
import { dashboardService } from "../../services/dashboard.service";
import { State as Filters } from "../../store/filters/types";

export interface AngleStatus {
    Expected: number;
    NotExpected: number;
    Markup: number;
    Error: number;
    Available: number;
}

export interface CompetitionAlertData {
    CompetitionId: string;
    CompetitionName: string;
    Status?: AngleStatus;
}

export interface useCompetitionAlertsType {
    competitionAlerts: CompetitionAlertData[];
    lastUpdated: Date;
    loading: boolean;
    error: any;
}

export const useOneCompetitionAlert = (
    competitionId: string | undefined,
    filters?: Filters
): useCompetitionAlertsType => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [competitionAlerts, setCompetitionAlerts] = useState<CompetitionAlertData[]>([]);
    const [lastUpdated, setLastUpdated] = useState<Date>(new Date());

    useEffect(() => {
        setCompetitionAlerts([]);
    }, [filters]);

    useEffect(() => {
        const getCompetitionAlerts = async () => {
            setLoading(true);
            setError(null);

            if (!competitionId) return;

            try {
                const response = await dashboardService.getOneCompetitionAlert(competitionId, {
                    ...(filters?.minDate && { min_date: filters?.minDate?.toISOString() }),
                    ...(filters?.maxDate && { max_date: filters?.maxDate?.toISOString() }),
                    ...(filters?.dateOrder && { date_order: filters?.dateOrder }),
                    ...(filters?.nameOrder && { name_order: filters?.nameOrder }),
                    ...(filters?.statusesOrder && { status_order: filters?.statusesOrder }),
                    ...(filters?.teams?.length && { team_id: filters?.teams?.map((team) => team.id) }),
                    ...(filters?.statuses?.length && { status: filters?.statuses?.map((status) => status.id) })
                });
                if (response) {
                    setCompetitionAlerts(response || []);
                    setLastUpdated(new Date());
                }
            } catch (error: any) {
                setError(error);
            }
            setLoading(false);
        };

        getCompetitionAlerts();
    }, [filters, competitionId]);

    return { competitionAlerts, lastUpdated, loading, error };
};
