import { ReactNode } from "react";
import { SyncPoints as SyncPointsValue } from "../../utils/hooks/useSync";

export const SET_EDITOR_ENABLED = "SET_EDITOR_ENABLED";
export const SET_EDITOR_LOADED = "SET_EDITOR_LOADED";
export const SET_TOOL = "SET_TOOL";
export const UPDATE_VALUE = "UPDATE_VALUE";

/* Editor Type */
export type Editor = { enabled: boolean; active: boolean };

/* Tool Subtypes */
export const trimType = "TRIM";
export const syncType = "SYNC";
export type SyncPoints = "FIRST_HALF" | "SECOND_HALF" | "FIRST_OVERTIME" | "SECOND_OVERTIME" | "PENALTY_SHOOTOUT";
export type SyncSubType = { id: SyncPoints; name: string };

/* Tool Value Types */
export type TrimValue = { startTime: number; endTime: number };
export type syncValue = SyncPointsValue;

/* Tool Types */
type Trim = { type: typeof trimType; subType?: null; value?: TrimValue };
type Sync = { type: typeof syncType; subType?: SyncSubType[]; value?: syncValue };
type None = { type: null; subType?: null; value?: any };

/* Possible tool Types */
export type Tool = Trim | Sync | None;

interface SetEditorEnabledAction {
    type: typeof SET_EDITOR_ENABLED;
    enabled: boolean;
}

interface SetEditorLoadedAction {
    type: typeof SET_EDITOR_LOADED;
    loaded: boolean;
}

interface SetEditorToolAction {
    type: typeof SET_TOOL;
    tool: Tool;
}

interface SetEditorToolValueAction {
    type: typeof UPDATE_VALUE;
    value: object;
}

export type Action = SetEditorEnabledAction | SetEditorLoadedAction | SetEditorToolAction | SetEditorToolValueAction;
export type Dispatch = (action: Action) => void;
export type State = { editor: Editor; tool: Tool };
export type EditorProviderProps = { children: ReactNode };
