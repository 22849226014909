import React, { FC } from "react";
import Modal from "../common/modal";
import { Props as ReactModalProps } from "react-modal";
import FileUploadContent, { FileUploadContentProps } from "../fileUploadContent";

import styles from "./fileUploadModal.module.scss";

export interface FileUploadModalProps extends ReactModalProps, FileUploadContentProps {
    uploading: boolean;
}

const FileUploadModal: FC<FileUploadModalProps> = ({ isOpen, uploading, ...rest }) => {
    return (
        <Modal isOpen={isOpen} className={styles.modal} {...rest}>
            <FileUploadContent {...rest} />
        </Modal>
    );
};

export default FileUploadModal;
