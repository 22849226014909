import React, { FC, useEffect } from "react";
import { EditorProvider } from "../../../store/editor/context";
import FixtureContent from "../../fixtureContent";
import { PlayerProvider } from "../../../store/player/context";
import { FixtureMetadataProvider } from "../../../store/fixtureMetadata/context";

import styles from "./fixturePage.module.scss";
import {useMixPanel} from "../../../logging_config/Mixpanel";

export interface FixturesProps {}

const FixturePage: FC<FixturesProps> = () => {
    const mixpanel = useMixPanel()

    useEffect(() => {
        window.addEventListener("unload", () => {
            mixpanel.track("Fixture Page - Reloaded")
        });
        return () => {
            window.removeEventListener("beforeunload", () => {});
        };
    }, [mixpanel]);

    return (
        <div className={styles.fixturePageParentContainer}>
            <div className={styles.fixturePageChildContainer}>
                    <FixtureMetadataProvider>
                        <PlayerProvider>
                            <EditorProvider>
                                <FixtureContent />
                            </EditorProvider>
                        </PlayerProvider>
                    </FixtureMetadataProvider>
            </div>
        </div>
    );
};

export default FixturePage;
