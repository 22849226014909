import { useState } from "react";
import { throttle } from "lodash";
import { filesService } from "../../services/files.service";
import { useProgressQueue } from "../../store/progressQueue/useProgressQueue";

export interface UseMultipartFileUploadProgress {
    loading: boolean;
    error: any;
    setMultipartFileUploadProgress: (fileId: string, progress: number, stats: any) => void;
}

const useMultipartFileUploadProgress = (): UseMultipartFileUploadProgress => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const { setStats } = useProgressQueue();

    const setMultipartFileUploadProgressRequest = throttle(async (fileId: string, progress: number) => {
        if (loading) return;

        setLoading(true);
        setError(false);

        try {
            await filesService.setUploadProgress(fileId, progress);
        } catch (error: any) {
            setError(error);
        }
        setLoading(false);
    }, 10000);

    const setMultipartFileUploadProgress = (fileId: string, progress: number, updatedStats: any) => {
        setStats(fileId, { progress: Math.round(progress * 100), secondsLeft: updatedStats.secondsLeft });
        setMultipartFileUploadProgressRequest(fileId, Math.round(progress * 100));
    };

    return { loading, error, setMultipartFileUploadProgress };
};

export default useMultipartFileUploadProgress;
