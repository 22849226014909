import React, { FC } from "react";
import { useSpring, animated } from "react-spring";

import styles from "./error.module.scss";

export interface ErrorProps {
    error?: string;
    htmlFor?: string;
    classes?: string;
}

const Error: FC<ErrorProps> = ({ error, htmlFor, classes }) => {
    const bounceRange = {
        range: [0, 0.16, 0.32, 0.48, 0.66, 0.82, 1],
        output: [0, 10, 0, -10, 0, 10, 0]
    };

    const opacityRange = {
        range: [0, 0.2],
        output: [0, 1]
    };

    const { x } = useSpring({
        x: error ? 1 : 0,
        config: { mass: 1, tension: 160, friction: 30 }
    });

    return (
        <animated.label
            htmlFor={htmlFor}
            className={`${styles.error} ${classes ? classes : ""}`}
            style={{
                opacity: x.to(opacityRange) as any,
                marginLeft: x.to(bounceRange) as any
            }}
        >
            {error}
        </animated.label>
    );
};

export default Error;
