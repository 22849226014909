import React, { FC, ChangeEvent } from "react";
import ComparisonHeader from "../comparisonHeader";
import ComparisonBody from "../comparisonBody";

import styles from "./comparisonTable.module.scss";

export interface ComparisonTableProps {
    data: string[][];
    columns: Column[];
    attributes: string[];
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export interface Column {
    id: string;
    name: string;
}

const ComparisonTable: FC<ComparisonTableProps> = ({ columns, attributes, data, onChange }) => {
    return (
        <table className={styles.comparisonTable}>
            <ComparisonHeader columns={columns}></ComparisonHeader>
            <ComparisonBody columns={columns} attributes={attributes} data={data} onChange={onChange}></ComparisonBody>
        </table>
    );
};

export default ComparisonTable;
