import React, { FC } from "react";

export interface ImgProps {
    src: string;
    alt?: string;
    classes?: string;
    height?: string;
    width?: string;
}

const Img: FC<ImgProps> = ({ src, alt, classes, ...rest }) => {
    return <img src={src} alt={alt} className={classes} {...rest}></img>;
};

export default Img;
