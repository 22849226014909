import React, { FC } from "react";
import Icon from "../common/icon";
import Title from "../common/title";
import Button from "../common/button";
import { history } from "../../utils/history";
import * as constants from "../../constants/login.constants";

import styles from "./checkEmail.module.scss";

export interface CheckEmailProps {}

const CheckEmail: FC<CheckEmailProps> = () => {
    const handleClick = () => history.push("/login");

    return (
        <div className={styles.checkEmail}>
            <div className={styles.checkEmailContent}>
                <Icon
                    icon={{ icon: ["fas", "envelope-open-text"], type: "FontAwesomeIcon", size: "5x" }}
                    classes={styles.icon}
                />
                <Title label={constants.CHECK_YOUR_EMAIL} classes={"subtitle"} />
            </div>
            <Button
                name={constants.BACK_TO_LOGIN}
                classes={`btn-black btn-rounded-sm ${styles.backToLogin}`}
                onClick={handleClick}
            />
        </div>
    );
};

export default CheckEmail;
