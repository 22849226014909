import React, {FC, ReactNode, Fragment, useCallback} from "react";
import { useLogin } from "../../../store/auth/useLogin";
import { Role, AllowedPermissions } from "../../../store/role/types";
import {can, canPermissions} from "../../../store/role";

export interface ProtectedComponentProps {
    roles?: Role[];
    permissions?: AllowedPermissions;
    children: ReactNode;
    fanIdPermission?: boolean;
}

const ProtectedComponent: FC<ProtectedComponentProps> = ({ roles, permissions, children,  fanIdPermission }) => {
    const { auth } = useLogin();
    const hasAccess = useCallback((): boolean => {
        if (!auth.user) return false;
        if(fanIdPermission) return fanIdPermission;
        if (auth.user?.role && roles && can(auth.user?.role, roles)) return true;
        return !!(auth.user?.competition_permissions &&
            permissions &&
            canPermissions(auth.user?.competition_permissions, permissions));

    }, [auth, roles, permissions, fanIdPermission])

    return <Fragment>{hasAccess() && children}</Fragment>;
};

export default ProtectedComponent;
