import React, { FC, Fragment, FormEvent } from "react";
import { Tool } from "../../../store/editor/types";
import EditorSyncControls from "../editorSyncControls";
import EditorTrimControls from "../editorTrimControls";
import { SyncPoints } from "../../../store/editor/types";

export interface EditorControlsProps {
    tool: Tool;
    onToolSelection: (id: SyncPoints, name: string, option: string) => void;
    onCancel: (event: FormEvent<EventTarget>) => void;
    onSave: (event: FormEvent<EventTarget>) => void;
}

const EditorControls: FC<EditorControlsProps> = ({ tool, onToolSelection, onCancel, onSave }) => {
    const renderControls = () => {
        switch (tool.type) {
            case "TRIM":
                return <EditorTrimControls onCancel={onCancel} onSave={onSave} />;
            case "SYNC":
                return <EditorSyncControls tool={tool} onClick={onToolSelection} onCancel={onCancel} onSave={onSave} />;
        }
    };

    return <Fragment>{renderControls()}</Fragment>;
};

export default EditorControls;
