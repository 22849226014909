import { ReactNode } from "react";
import * as roles from "../role/types";

export const UPDATE_USER = "UPDATE_USER";

export interface User {
    id: number;
    customer_id: number;
    username: string;
    role: roles.Role;
    permissions: roles.Permissions[];
    hasCommsAccess: boolean;
    fan_id: number;
}

interface UpdateUserAction {
    type: typeof UPDATE_USER;
    user: User | null;
}

export type Action = UpdateUserAction;
export type Dispatch = (action: Action) => void;
export type State = { user: User | null };
export type registerProviderProps = { children: ReactNode };
