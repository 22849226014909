import { FC, FormEvent, Fragment, useMemo } from "react";
import { useFixtureMetadata } from "../../store/fixtureMetadata/useFixtureMetadata";
import * as constants from "../../constants/fixture.constants";
import DropZone from "../common/dropZone";
import Button from "../common/button";
import Title from "../common/title";
import Info from "../common/info";
import File from "../file";

import styles from "./fileUploadContentKMIDropZone.module.scss";

export interface FileUploadContentKMIDropZoneProps {
    file: File | undefined;
    xmlFile: File | undefined;
    onFileSelection: (file: File) => void;
    onXMLFileSelection: (file: File) => void;
    onFileRemoval: () => void;
    onXMLFileRemoval: () => void;
    onMetaDataBlur: (event: FormEvent) => void;
    onCancel: () => void;
    onUpload: () => void;
}

const FileUploadContentKMIDropZone: FC<FileUploadContentKMIDropZoneProps> = ({
    file,
    xmlFile,
    onFileSelection,
    onXMLFileSelection,
    onFileRemoval,
    onXMLFileRemoval,
    onCancel,
    onUpload
}) => {
    const { date } = useFixtureMetadata();

    const handleDisabled = (): boolean => {
        return !file || !xmlFile;
    };

    const getMessage = useMemo((): string => {
        const convertedDate = new Date(date || "");
        const year = convertedDate.getFullYear().toString().substr(2, 2);
        const month = ("0" + convertedDate.getMonth()).slice(-2);
        const day = ("0" + convertedDate.getDay()).slice(-2);
        const formattedDate = `${year}_${month}_${day}`;
        return constants.KMI_INFO.replace("{date}", formattedDate || "");
    }, [date]);

    return (
        <Fragment>
            <div className={styles.container}>
                <Title label={constants.UPLOAD_FILES} classes={`subtitle ${styles.title}`} />
                <Info message={getMessage} classes={styles.info} />
                <div className={styles.content}>
                    <div className={styles.item}>
                        <div className={styles.heading}>{constants.CLIP}</div>
                        {!file && (
                            <DropZone
                                onFileSelection={onFileSelection}
                                fileType={constants.FILE_FORMATS}
                                classes={styles.dropZone}
                            />
                        )}
                        {file && (
                            <div className={styles.file}>
                                <File name={file.name} size={file.size} onClick={onFileRemoval} />
                            </div>
                        )}
                    </div>

                    <div className={styles.item}>
                        <div className={styles.heading}>{constants.XML}</div>
                        {!xmlFile && (
                            <DropZone
                                onFileSelection={onXMLFileSelection}
                                fileType={constants.XML_FORMAT}
                                classes={styles.dropZone}
                            />
                        )}
                        {xmlFile && (
                            <div className={styles.file}>
                                <File name={xmlFile.name} size={xmlFile.size} onClick={onXMLFileRemoval} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <Button
                    id={constants.CANCEL}
                    name={constants.CANCEL}
                    classes={`btn-outline-green m-0 ${styles.cancelButton}`}
                    onClick={onCancel}
                />
                <Button
                    id={constants.UPLOAD}
                    name={constants.UPLOAD}
                    classes={"btn-green m-0"}
                    disabled={handleDisabled()}
                    onClick={onUpload}
                />
            </div>
        </Fragment>
    );
};

export default FileUploadContentKMIDropZone;
