import React, { FC, useReducer, useRef, useEffect, MouseEvent as ReactMouseEvent } from "react";
import Title from "../common/title";
import Fixture from "../fixture";
import * as types from "./types";
import Img from "../common/img";
import Button from "../common/button";
import FixtureHeader from "../fixtureHeader";
import * as format from "../../utils/format";
import { history } from "../../utils/history";
import { Sort } from "../../store/filters/types";
import { refineFixturesReducer } from "./reducer";
import { useFilters } from "../../store/filters/useFilters";
import spinner from "../../assets/images/common/spinner.svg";
import { useFiltersState } from "../../store/filters/context";
import * as constants from "../../constants/fixtures.constants";
import useInfiniteList from "../../utils/hooks/useInfiniteList";
import useWindowDimensions from "../../utils/hooks/useWindowDimensions";
import useNotExpectedFixture from "../../utils/hooks/useNotExpectedFixture";
import useFixtures, { FixturesData, UseFixtures as UseFixturesType } from "../../utils/hooks/useFixtures";

import styles from "./fixtureList.module.scss";

export interface FixtureListProps {
    onToggleFiltersOpen: () => void;
}

const FixtureList: FC<FixtureListProps> = ({ onToggleFiltersOpen }) => {
    const fixturesContainerRef = useRef<HTMLDivElement>(null);
    const isMounted = useRef(false);
    const filters = useFiltersState();
    const { width } = useWindowDimensions();
    const { setNotExpected, setExpected, success } = useNotExpectedFixture();
    const { toggleDatesSort, toggleTeamsSort, toggleCompetitionsSort, toggleStatusSort } = useFilters();

    useEffect(() => {
        if (isMounted.current) {
            dispatchRefineFixtures({
                type: types.SET_FILTERS,
                filters: filters
            });
            fixturesContainerRef.current?.scrollIntoView();
        } else {
            isMounted.current = true;
        }
    }, [filters]);

    const loadNextPage = () => {
        dispatchRefineFixtures({
            type: types.SET_PAGE_NUMBER,
            pageNumber: refineFixtures.pageNumber + 1
        });
    };

    const [refineFixtures, dispatchRefineFixtures] = useReducer(refineFixturesReducer, {
        pageNumber: 1,
        pageSize: 40,
        filters: filters
    });

    const { fixtures, loading, hasMore, error, reloadFixtures }: UseFixturesType = useFixtures(
        refineFixtures.pageNumber,
        refineFixtures.pageSize,
        refineFixtures.filters
    );

    const { intersectionObserver, topPadding, bottomPadding, firstIndex, currentIndex } = useInfiniteList(
        fixtures,
        refineFixtures.pageSize,
        loading,
        hasMore,
        error,
        width >= 768 ? 3.75 : 9,
        loadNextPage,
        refineFixtures.filters
    );

    const columns = constants.FIXTURE_COLUMNS.map((column) => ({
        ...column,
        sort: (filters[column.id] ? filters[column.id] : undefined) as Sort | undefined
    }));

    useEffect(() => {
        if (success) reloadFixtures();
    }, [success, reloadFixtures]);

    const handleClick = (id: string) => {
        history.push(`/fixtures/${id}`);
    };

    const handleDropdownSelection = (id: string, selection: string) => {
        if (selection === constants.SET_FIXTURE_EXPECTED) setExpected(id);
        else if (selection === constants.SET_FIXTURE_NOT_EXPECTED) setNotExpected(id);
    };

    const handleSort = (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
        const { id } = event.currentTarget;
        switch (id) {
            case "dateOrder":
                toggleDatesSort();
                break;
            case "nameOrder":
                toggleTeamsSort();
                break;
            case "competitionOrder":
                toggleCompetitionsSort();
                break;
            case "statusesOrder":
                toggleStatusSort();
                break;
            default:
                break;
        }
    };

    return (
        <div className={styles.fixturesContainer}>
            <Title label={constants.FIXTURES_TITLE} classes={"page-title"} ref={fixturesContainerRef}></Title>
            <div className={styles.filterControls}>
                <Button
                    id={constants.FILTER}
                    tooltip={constants.FILTER}
                    icon={{ icon: { icon: ["fas", "filter"], type: "FontAwesomeIcon", size: "1x" } }}
                    onClick={onToggleFiltersOpen}
                    classes={`btn-transparent ${styles.filterButton}`}
                ></Button>
            </div>
            <div className={styles.fixturesList}>
                <FixtureHeader columns={columns} onClick={handleSort}></FixtureHeader>
                <div
                    ref={intersectionObserver}
                    style={{
                        paddingTop: `${topPadding}rem`,
                        paddingBottom: `${bottomPadding}rem`
                    }}
                >
                    {fixtures.slice(firstIndex, currentIndex).map((fixture: FixturesData) => (
                        <Fixture
                            key={fixture.id}
                            id={fixture.id.toString()}
                            result={"VS"}
                            homeTeam={format.team(fixture.home_team.name)}
                            awayTeam={format.team(fixture.away_team.name)}
                            competition={fixture.competition.name}
                            competitionId={fixture.competition.id}
                            shortDate={format.date(fixture.date)}
                            date={format.dateTime(fixture.date)}
                            state={fixture.status}
                            onClick={handleClick}
                            onDropdownSelection={handleDropdownSelection}
                        ></Fixture>
                    ))}
                </div>
            </div>
            {hasMore && !error && (
                <div className={styles.spinner}>
                    <Img src={spinner} alt={"Loading Spinner"} classes="logo"></Img>
                </div>
            )}
        </div>
    );
};

export default FixtureList;
