export const FIXTURES_TITLE = "Fixtures.";

/* Filters */
export const FILTER = "Filter";
export const FILTERS = "Filters";
export const MATCH_DATES = "Match Dates";
export const TEAMS = "Teams";
export const COMPETITIONS = "Competitions";
export const STATES = "States";
export const EXPORT = "Export";

export const EXPAND = "Expand";
export const COLLAPSE = "Collapse";
export const COLLAPSE_FILTERS = "Collapse Filters";

/* Mobile Filter Controls */
export const CLEAR_ALL = "Clear All";
export const DONE = "Done";

/* Manage Fixture */
export const MANAGE_FIXTURE = "Manage Fixture";
export const SET_FIXTURE_EXPECTED = "Set as expected";
export const SET_FIXTURE_NOT_EXPECTED = "Set as not expected";
export const FIXTURE_SUCCESSFULLY_MARKED_AS_NOT_EXPECTED = "Fixture successfully set to not expected";
export const FIXTURE_FAILED_TO_MARK_AS_NOT_EXPECTED = "Failed to set fixture to not expected";
export const FIXTURE_SUCCESSFULLY_MARKED_AS_EXPECTED = "Fixture successfully set to expected";
export const FIXTURE_FAILED_TO_MARK_AS_EXPECTED = "Failed to set fixture to expected";
export const CONFIRMATION_SET_AS_EXPECTED = "Set to expected?";
export const CONFIRMATION_SET_AS_NOT_EXPECTED = "Set to not expected?";
export const CONFIRMATION_SET_AS_EXPECTED_SUBTITLE = "Set {match} to expected?";
export const CONFIRMATION_SET_AS_NOT_EXPECTED_SUBTITLE = "Set {match} to not expected?";

export const STATUS = [
    { id: "WAITING", name: "Waiting" },
    { id: "EXPECTED", name: "Expected" },
    { id: "UPLOADING", name: "Uploading" },
    { id: "TRANSCODING", name: "Transcoding" },
    { id: "AVAILABLE", name: "Available" },
    { id: "MARKUP", name: "Markup" },
    { id: "ARCHIVED", name: "Archived" },
    { id: "CANCELLED", name: "Cancelled" },
    { id: "POSTPONED", name: "Postponed" },
    { id: "NOT_EXPECTED", name: "Not Expected" },
    { id: "ERROR", name: "Error" }
];

/* Fixture Columns */
export const FIXTURE_COLUMNS = [
    { id: "dateOrder", name: "Date" },
    { id: "nameOrder", name: "Fixture" },
    { id: "statusesOrder", name: "State" }
];

/* Dashboard Fixture Angles Columns */
export const FIXTURE_ANGLES_COLUMNS = [
    { id: "dateOrder", name: "Date" },
    { id: "nameOrder", name: "Fixture" },
    { id: "anglesOrder", name: "Angles" }
];
