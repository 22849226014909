import React, { FC, useState, useEffect } from "react";
import Title from "../common/title";
import { toast } from "react-toastify";
import Stepper from "../common/stepper";
import AddUserRole from "../addUserRole";
import { history } from "../../utils/history";
import AddUserCustomer from "../addUserCustomer";
import { useLogin } from "../../store/auth/useLogin";
import AddUserCredentials from "../addUserCredentials";
import AddUserPermissions from "../addUserPermissions";
import * as constants from "../../constants/admin.constants";
import * as roles from "../../store/role/types";
import { can } from "../../store/role";

import styles from "./addUser.module.scss";

export interface AddUserProps {}

const AddUser: FC<AddUserProps> = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const { auth } = useLogin();

    const handleNextPage = () => {
        setCurrentStep(currentStep + 1);
    };

    const steps = [
        {
            label: constants.CREDENTIALS,
            roles: roles.setCredentials,
            component: <AddUserCredentials onNextPage={handleNextPage}></AddUserCredentials>
        },
        {
            label: constants.CUSTOMER,
            roles: roles.setCustomer,
            component: <AddUserCustomer onNextPage={handleNextPage}></AddUserCustomer>
        },
        {
            label: constants.ROLE,
            roles: roles.setRole,
            component: <AddUserRole onNextPage={handleNextPage}></AddUserRole>
        },
        {
            label: constants.PERMISSIONS,
            roles: roles.setPermissions,
            component: <AddUserPermissions onNextPage={handleNextPage}></AddUserPermissions>
        }
    ];

    const filteredSteps = steps.filter((step) => auth.user && can(auth.user?.role, step.roles));

    useEffect(() => {
        if (currentStep === filteredSteps.length) {
            history.push("/admin");
            toast.success(constants.USER_SUCCESSFULLY_ADDED);
        }
    }, [currentStep, filteredSteps]);

    const renderStepper = () => {
        return steps
            .filter((step) => auth.user && can(auth.user?.role, step.roles))
            .map((step) => {
                return step.label;
            });
    };

    const renderStep = () => {
        if (filteredSteps.length === 0) return;
        if (currentStep < filteredSteps.length) {
            return filteredSteps[currentStep].component;
        }
    };

    return (
        <div className={styles.parentContainer}>
            <Title label={constants.ADD_USER_TITLE} classes={"page-title"}></Title>
            <Stepper currentStep={currentStep} steps={renderStepper()} classes={styles.stepper}></Stepper>
            <div className={styles.content}>{renderStep()}</div>
        </div>
    );
};

export default AddUser;
