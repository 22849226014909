import { FC, FormEvent, useMemo } from "react";
import * as constants from "../../constants/fixture.constants";
import { Angles } from "../../utils/hooks/useFixtureAngles";
import { Item } from "../common/autoComplete";
import { FileMetaData } from "../fileUpload";
import DropZone from "../common/dropZone";
import Button from "../common/button";
import Select from "../common/select";
import Title from "../common/title";
import Input from "../common/input";
import File from "../file";

import styles from "./fileUploadContentDropZone.module.scss";

export interface FileUploadContentDropZoneProps {
    file: File | undefined;
    angles: { id: Angles; name: string }[];
    fileMetaData: FileMetaData;
    fileMetaDataErrors: object;
    selectedSubTypeExists: boolean;
    onFileSelection: (file: File) => void;
    onFileRemoval: () => void;
    onFileNameChange: (event: FormEvent) => void;
    onFileTypeChange: (selectedItem: Item | null) => void;
    onMetaDataBlur: (event: FormEvent) => void;
    onCancel: () => void;
    onUpload: () => void;
}

const FileUploadContentDropZone: FC<FileUploadContentDropZoneProps> = ({
    file,
    angles,
    fileMetaData,
    fileMetaDataErrors,
    selectedSubTypeExists,
    onFileSelection,
    onFileRemoval,
    onFileNameChange,
    onFileTypeChange,
    onMetaDataBlur,
    onCancel,
    onUpload
}) => {
    const getError = (prop: string): string => {
        const errors: { [index: string]: any } = fileMetaDataErrors;
        if (errors[prop]) {
            return errors[prop]["message"];
        }
        return "";
    };

    const fileTypeError = useMemo(() => {
        if (!selectedSubTypeExists) {
            return "";
        }

        const selectedFileType = constants.VIDEO_TYPES.find((x) => x.id === fileMetaData.videoType);
        return constants.FILE_WITH_SUB_TYPE_EXISTS.replace("{angle}", selectedFileType?.name || "");
    }, [fileMetaData.videoType, selectedSubTypeExists]);

    const handleDisabled = (): boolean => {
        return file === undefined || Object.values(fileMetaData).some((element) => element === null || element === "");
    };

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <Title label={constants.UPLOAD_FILE} classes={`subtitle ${styles.title}`}></Title>
                <div className={styles.dropZone}>
                    <DropZone onFileSelection={onFileSelection} fileType={constants.FILE_FORMATS} />
                    {file && (
                        <div className={styles.file}>
                            <File name={file.name} size={file.size} onClick={onFileRemoval}></File>
                        </div>
                    )}
                </div>
                {file && (
                    <div className={styles.formGroup}>
                        <Title label={"File Details"} classes={`heading ${styles.heading}`}></Title>
                        <div className={styles.formFields}>
                            <Input
                                id={constants.FILE_NAME_ID}
                                label={constants.FILE_NAME}
                                value={fileMetaData.fileName}
                                classes={styles.inputContainer}
                                onBlur={onMetaDataBlur}
                                onChange={onFileNameChange}
                                error={getError(constants.FILE_NAME_ID)}
                            />
                            <Select
                                id={constants.FILE_TYPE}
                                label={constants.FILE_TYPE}
                                classes={styles.inputContainer}
                                error={fileTypeError}
                                items={angles}
                                selectedItem={
                                    constants.VIDEO_TYPES.find((x) => x.id === fileMetaData.videoType) || null
                                }
                                onSelect={onFileTypeChange}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.footer}>
                <Button
                    id={constants.CANCEL}
                    name={constants.CANCEL}
                    classes={`btn-outline-green m-0 ${styles.cancelButton}`}
                    onClick={onCancel}
                ></Button>
                <Button
                    id={constants.UPLOAD}
                    name={constants.UPLOAD}
                    classes={"btn-green m-0"}
                    disabled={handleDisabled()}
                    onClick={onUpload}
                ></Button>
            </div>
        </div>
    );
};

export default FileUploadContentDropZone;
