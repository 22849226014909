import React, { FC, useEffect, ElementType } from "react";
import { useLogin } from "../../../store/auth/useLogin";
import { Redirect, Route, useLocation } from "react-router-dom";
import PreloadPage from "../../pages/preloadPage";
import { Role } from "../../../store/role/types";
import { can } from "../../../store/role";
import { toast } from "react-toastify";

export interface ProtectedRouteProps {
    exact?: boolean;
    path: string | string[];
    redirect?: string;
    roles?: Role[];
    component: ElementType;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ exact, path, redirect, roles, component: Component, ...rest }) => {
    const { auth, getUser } = useLogin();
    let location = useLocation();

    const ValidateUser = () => {
        useEffect(() => {
            if (!auth.user) getUser();
        }, []);
    };

    ValidateUser();

    return (
        <Route
            exact={exact}
            path={path}
            {...rest}
            render={(props) => {
                if (auth.renewError) {
                    return <Redirect to={{ pathname: "/login", state: { from: location.pathname } }} />;
                } else if (redirect) {
                    return <Redirect to={{ pathname: redirect, state: { from: location.pathname } }} />;
                } else if (auth.user && roles && !can(auth.user.role, roles)) {
                    toast.error("You do not have permission to access this page");
                    return <Redirect to={{ pathname: "/fixtures", state: { from: location.pathname } }} />;
                } else if (!auth.user) {
                    return <PreloadPage></PreloadPage>;
                } else {
                    return <Component {...props} />;
                }
            }}
        />
    );
};

export default ProtectedRoute;
