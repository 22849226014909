import React, { memo, FormEvent, Fragment, useState, useEffect } from "react";
import * as constants from "../../constants/admin.constants";
import useDeleteUser from "../../utils/hooks/useDeleteUser";
import ConfirmationModal from "../common/confirmationModal";
import { usersData } from "../../utils/hooks/useUsers";
import { history } from "../../utils/history";
import Dropdown from "../common/dropdown";
import UserRole from "../userRole";

import styles from "./user.module.scss";

export interface UserProps extends usersData {
    onUserDelete: (userId: string) => void;
}

const User = memo<UserProps>(({ id, username, role, permissions, onUserDelete }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [active, setActive] = useState(false);

    const { deleteUser, success } = useDeleteUser();

    const userClasses = `${styles.userContainer} ${active ? styles.active : ""}`;

    useEffect(() => {
        if (success) onUserDelete(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, success]);

    const handleClick = (event: FormEvent<EventTarget>) => {
        const { id: action } = event.target as HTMLSelectElement;
        if (action === constants.EDIT_USER) {
            history.push(`admin/edit-user/${id}`);
        } else if (action === constants.DELETE_USER) {
            handleShowModal();
        }
    };

    const handleHideModal = () => setIsOpen(false);

    const handleShowModal = () => setIsOpen(true);

    const handleOpen = () => setActive(true);

    const handleClose = () => setActive(false);

    const handleDeleteUser = () => {
        setIsOpen(false);
        deleteUser(id);
    };

    return (
        <Fragment>
            <div className={userClasses}>
                <div className={styles.username}>{username}</div>
                <UserRole role={role} />
                {/*TODO: Permissions*/}
                {permissions &&
                    Object.keys(permissions).map((permission, index) => {
                        return <div key={index}></div>;
                    })}
                <div className={styles.dropdown}>
                    <Dropdown
                        id={constants.MANAGE_USER}
                        icon={{ icon: { icon: ["fas", "ellipsis-v"], type: "FontAwesomeIcon", size: "1x" } }}
                        classes={`btn btn-circle-sm icon btn-white ${styles.button}`}
                        onClick={handleClick}
                        rippleColor={"black"}
                        onOpen={handleOpen}
                        onClose={handleClose}
                        tooltip={constants.MANAGE_USER}
                        suggestions={[constants.EDIT_USER, constants.DELETE_USER]}
                    ></Dropdown>
                </div>
            </div>
            <ConfirmationModal
                title={constants.DELETE_USER_CONFIRMATION.replace("{user}", username)}
                icon={{ icon: ["far", "trash-alt"], type: "FontAwesomeIcon", size: "2x" }}
                isOpen={isOpen}
                onCancel={handleHideModal}
                onConfirm={handleDeleteUser}
                onRequestClose={handleHideModal}
            ></ConfirmationModal>
        </Fragment>
    );
});

export default User;
