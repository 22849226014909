import { FC, MutableRefObject } from "react";
import ReactDOM from "react-dom";
import Plyr from "@sbgsportssoftware/plyr";
import EditorControls from "../editorControls";
import { SyncPoints } from "../../../store/editor/types";
import { useEditor } from "../../../store/editor/useEditor";
import * as constants from "../../../constants/fixture.constants";

import "./editor.scss";

export interface EditorProps {
    id: string;
    sourceId: string;
    playerRef: MutableRefObject<Plyr | undefined>;
    onCancel?: () => void;
    onSave?: () => void;
}

const Editor: FC<EditorProps> = ({ id, sourceId, playerRef, onCancel, onSave }) => {
    const portalContainer = document.getElementById("plyr__editor__controls");

    /* Store and dispatch video editor changes */
    const { tool: editorTool, clearTool, addSyncPoint } = useEditor();

    const handleToolSelection = (id: SyncPoints, name: string, option: string) => {
        const syncTools = constants.SYNC_TOOLS;
        switch (option) {
            case syncTools.ADD_SYNC_POINT:
                addSyncPoint({ id, name });
                break;
            case syncTools.MOVE_SYNC_POINT:
                playerRef.current?.markers.moveMarker(id);
                break;
            case syncTools.GO_TO_SYNC_POINT:
                playerRef.current?.markers.goToMarker(id);
        }
    };

    const handleCancel = () => {
        clearTool();
        if (onCancel) onCancel();
    };

    const handleSave = () => {
        if (onSave) onSave();
        clearTool();
    };

    return (
        <div id={id}>
            {portalContainer &&
                ReactDOM.createPortal(
                    <EditorControls
                        tool={editorTool}
                        onToolSelection={handleToolSelection}
                        onCancel={handleCancel}
                        onSave={handleSave}
                    ></EditorControls>,
                    portalContainer
                )}
        </div>
    );
};

export default Editor;
