import React, { FC } from "react";
import Login from "../../login";

import Img from "../../common/img";
import logo from "../../../assets/images/common/logo.svg";

import styles from "./loginPage.module.scss";

export interface LoginPageProps {}

const LoginPage: FC<LoginPageProps> = () => {
    return (
        <div className={`${styles.parentContainer}`}>
            <div className={styles.childContainer}>
                <Img src={logo} alt={"Hub Logo"} classes={styles.logo}></Img>
                <Login></Login>
            </div>
        </div>
    );
};

export default LoginPage;
