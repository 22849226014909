import { useEffect, useState } from "react";
import { SyncPoints } from "./useSync";
import { IncidentFiles } from "./useFixtureIncidents";
import { fixturesService } from "../../services/fixtures.service";

export interface fixture {
    id: string;
    name: string;
    fixture_id: number;
    collection_type: "USER" | "KMD" | "CLIP" | "PROVIDER_FOOTAGE";
    files: FixtureFiles[];
}

export interface Metadata {
    filename: string;
    incident?: string;
    sync_points: SyncPoints;
    transcode_progress?: number;
    upload_progress?: number;
}

export interface FixtureFiles {
    id: string;
    object_id: string;
    name: string;
    fixture_id: string;
    metadata: Metadata;
    collection_id: string | undefined;
    uploaded_to_hudl?: boolean;
    file_type: "VIDEO" | "PDF";
    status: "UPLOADING" | "TRANSCODING" | "MARKUP" | "AVAILABLE" | "ARCHIVED" | "ERROR";
    sub_type:
        | "VIDEO_BROADCAST"
        | "VIDEO_TACTICAL"
        | "VIDEO_HIGH_BEHIND_ONE"
        | "VIDEO_HIGH_BEHIND_TWO"
        | "VIDEO_EIGHTEEN_YARD_ONE"
        | "VIDEO_EIGHTEEN_YARD_TWO"
        | "VIDEO_HIGHLIGHTS"
        | "VIDEO_PANORAMIC"
        | "VIDEO_CLIP"
        | "VIDEO_BROADCAST_COMMS"
        | "AUDIO_COMMS";
}

export interface UseFixtureFootage {
    footage: FixtureFiles[];
    loading: boolean;
    error: any;
}

const useFixtureFootage = (fixtureId: string, refresh?: boolean): UseFixtureFootage => {
    const [footage, setFootage] = useState<FixtureFiles[] | IncidentFiles[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const getFixtures = async () => {
            setLoading(true);
            setError(false);

            try {
                const [footage = [], incidents = []] = await Promise.all([
                    fixturesService.getFixtureFootage(fixtureId),
                    fixturesService.getFixtureIncidents(fixtureId)
                ]).then((x) => x.map((result) => result.files));

                setFootage([...footage, ...(incidents || [])]);
            } catch (error: any) {
                setError(error);
            }
            setLoading(false);
        };

        if (refresh || refresh === undefined) {
            getFixtures();
        }

        const getFixturesInterval = setInterval(() => getFixtures(), 10000);

        return () => clearInterval(getFixturesInterval);
    }, [fixtureId, refresh]);

    return { footage, loading, error };
};

export default useFixtureFootage;
