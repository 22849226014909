import * as types from "./types";

const progressBarReducer = (state: types.State, action: types.Action) => {
    switch (action.type) {
        case "SET_ACTIVE": {
            return { ...state, active: action.active };
        }
        case "SET_PROGRESS": {
            return { ...state, progress: action.progress };
        }
        default: {
            throw new Error("Unhandled action type");
        }
    }
};

export default progressBarReducer;
