import { ReactNode } from "react";

export const SET_ACTIVE = "SET_ACTIVE";
export const SET_PROGRESS = "SET_PROGRESS";

interface SetActiveAction {
    type: typeof SET_ACTIVE;
    active: boolean;
}

interface SetProgressAction {
    type: typeof SET_PROGRESS;
    progress: number;
}

export type Action = SetActiveAction | SetProgressAction;
export type Dispatch = (action: Action) => void;
export type State = { active: SetActiveAction["active"]; progress: SetProgressAction["progress"] };
export type ProgressBarProviderProps = { children: ReactNode };
