import { ReactNode } from "react";

export const ADD_ITEM = "ADD_ITEM";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const CLEAR_QUEUE = "CLEAR_QUEUE";
export const SET_STATS = "SET_STATS";
export const SET_ACTIVE = "SET_ACTIVE";

export interface Item {
    id: string;
    name: string;
    active: boolean;
    stats?: {
        progress?: number;
        secondsLeft?: number;
    };
}

interface addItem {
    type: typeof ADD_ITEM;
    item: Item;
}

interface removeItem {
    type: typeof REMOVE_ITEM;
    id: Item["id"];
}

interface clearQueue {
    type: typeof CLEAR_QUEUE;
}

interface setStats {
    type: typeof SET_STATS;
    id: Item["id"];
    stats?: {
        progress?: number;
        secondsLeft?: number;
    };
}

interface setActive {
    type: typeof SET_ACTIVE;
    id: Item["id"];
    active: boolean;
}

export type Action = addItem | removeItem | clearQueue | setStats | setActive;
export type Dispatch = (action: Action) => void;
export type State = { items: Item[] };
export type ProgressQueueProviderProps = { children: ReactNode };
