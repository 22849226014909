import { useState } from "react";
import { SetPermissions } from "../../store/role/types";
import { User } from "../../store/register/types";
import { competitionPermissionsService } from "../../services/competitionPermissions.service";

export interface UserDetails {
    permissions: SetPermissions[];
}

export interface UseCompetitionPermissions {
    setCompetitionPermissions: (permissions: SetPermissions[]) => void;
    user: User | undefined;
    loading: boolean;
    errors: any;
}

const useCompetitionPermissions = (): UseCompetitionPermissions => {
    const [loading, setLoading] = useState(true);
    const [errors, setError] = useState(null);
    const [user, setuser] = useState<User>();

    const setCompetitionPermissions = async (permissions: SetPermissions[]) => {
        setLoading(true);
        setError(null);

        try {
            const response = await competitionPermissionsService.setCompetitionPermissions(permissions);
            if (response) setuser(response);
        } catch (error: any) {
            setError(error);
        }
        setLoading(false);
    };

    return { setCompetitionPermissions, user, loading, errors };
};

export default useCompetitionPermissions;
