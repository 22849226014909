import React, { FC, useState } from "react";
import Nav from "../../common/nav";
import { useScroll } from "../../../store/scroll/useScroll";
import CompetitionsListFilters from "../../dashboardFilters";
import CompetitionsList from "../../dashboard";

import styles from "./dashboardPage.module.scss";

export interface DashboardProps {}

const DashboardPage: FC<DashboardProps> = () => {
    const [filtersOpen, setFiltersOpen] = useState(false);

    const { setEnabled } = useScroll();

    const handleToggleFiltersOpen = () => {
        setFiltersOpen(!filtersOpen);
        setEnabled(filtersOpen);
    };
    return (
        <div className={styles.parentContainer}>
            <div className={styles.childContainer}>
                <Nav classes={styles.nav} />
                <div className={styles.content}>
                    <CompetitionsListFilters
                        onToggleFiltersOpen={handleToggleFiltersOpen}
                        open={filtersOpen}
                    ></CompetitionsListFilters>
                    <CompetitionsList onToggleFiltersOpen={handleToggleFiltersOpen}></CompetitionsList>
                </div>
            </div>
        </div>
    );
};

export default DashboardPage;
