import React, { FC, useEffect, MouseEvent as ReactMouseEvent } from "react";
import Logo from "../logo";
import { kebabCase } from "lodash";
import NavItem from "../../common/navItem";
import { history } from "../../../utils/history";
import * as access from "../../../store/role/types";
import { useLogin } from "../../../store/auth/useLogin";
import ProtectedComponent from "../protectedComponent";
import useWindowDimensions from "../../../utils/hooks/useWindowDimensions";
import { NAV_ITEMS as constants } from "../../../constants/global.constants";
import styles from "./nav.module.scss";

export interface NavProps {
    classes?: string;
}

export interface NavElementProps extends NavProps {
    onClick: (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    selected: string;
}

const BrowserNav: FC<NavElementProps> = ({ selected, classes, onClick }) => {
    return (
        <div className={`${styles.nav} ${classes ? classes : ""}`}>
            <Logo id={constants.FIXTURES} onClick={onClick} classes={styles.logo}></Logo>
            <div className={styles.divider}></div>
            <NavItem
                id={constants.FIXTURES}
                label={constants.FIXTURES}
                selected={selected.includes(constants.FIXTURES.toLocaleLowerCase())}
                onClick={onClick}
            ></NavItem>
            <ProtectedComponent roles={access.admin}>
                <NavItem
                    id={constants.DASHBOARD}
                    label={constants.DASHBOARD}
                    selected={selected.includes(constants.DASHBOARD.toLocaleLowerCase())}
                    onClick={onClick}
                ></NavItem>
            </ProtectedComponent>
            <ProtectedComponent roles={access.admin}>
                <NavItem
                    id={constants.ADMIN}
                    label={constants.ADMIN}
                    selected={selected.includes(constants.ADMIN.toLocaleLowerCase())}
                    onClick={onClick}
                ></NavItem>
            </ProtectedComponent>
            <NavItem
                id={constants.LOG_OUT}
                classes={styles.logOut}
                label={constants.LOG_OUT}
                onClick={onClick}
            ></NavItem>
        </div>
    );
};

const MobileNav: FC<NavElementProps> = ({ onClick, selected, classes }) => {
    return (
        <div className={`${styles.nav} ${classes ? classes : ""}`}>
            <NavItem
                id={constants.FIXTURES}
                label={constants.FIXTURES}
                selected={selected.includes(constants.FIXTURES.toLocaleLowerCase())}
                onClick={onClick}
            ></NavItem>
            <NavItem
                id={constants.DASHBOARD}
                label={constants.DASHBOARD}
                selected={selected.includes(constants.DASHBOARD.toLocaleLowerCase())}
                onClick={onClick}
            ></NavItem>
            <ProtectedComponent roles={access.admin}>
                <NavItem
                    id={constants.ADMIN}
                    label={constants.ADMIN}
                    selected={selected.includes(constants.ADMIN.toLocaleLowerCase())}
                    onClick={onClick}
                ></NavItem>
            </ProtectedComponent>
        </div>
    );
};

const Nav: FC<NavProps> = ({ classes }) => {
    const { logout } = useLogin();
    const { width } = useWindowDimensions();

    useEffect(() => {
        return () => {
            if (history.location.pathname === `/${kebabCase(constants.LOG_OUT)}`) logout();
        };
    }, [logout]);

    const handleClick = (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();

        const { id } = event.currentTarget;

        const path = `/${kebabCase(id)}`;
        if (history.location.pathname !== path) history.push(path);
    };

    const selected = () => history.location.pathname.toLocaleLowerCase();

    return width >= 768 ? (
        <BrowserNav onClick={handleClick} selected={selected()} classes={classes} />
    ) : (
        <MobileNav onClick={handleClick} selected={selected()} classes={classes} />
    );
};

export default Nav;
