import { useEffect, useState } from "react";
import { filesService } from "../../services/files.service";

export interface UsePreviewThumbnails {
    vttFiles: string[];
    loading: boolean;
    error: any;
}

const usePreviewThumbnails = (fileId: string): UsePreviewThumbnails => {
    const [vttFiles, setVttFile] = useState<string[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const getFile = async () => {
            if (!fileId || parseInt(fileId) <= 0) {
                return;
            }

            setLoading(true);
            setError(false);
            setVttFile([]);

            try {
                const response = await Promise.allSettled([
                    filesService.getPreviewThumbnails(fileId),
                    filesService.getLargePreviewThumbnails(fileId)
                ]).then((x) =>
                    x
                        .filter((result) => result.status === "fulfilled")
                        .map((result) => (result as PromiseFulfilledResult<string>).value)
                );

                if (response) setVttFile(response);
            } catch (error: any) {
                setError(error);
            }
            setLoading(false);
        };

        getFile();
    }, [fileId]);

    return { vttFiles, loading, error };
};

export default usePreviewThumbnails;
