import React, { FC, useState, useRef, ChangeEvent, DragEvent } from "react";
import * as constants from "../../../constants/global.constants";
import Icon from "../icon";

import styles from "./dropZone.module.scss";

export interface DropZoneProps {
    onFileSelection: (file: File) => void;
    onChange?: (file: File) => void;
    fileType?: string;
    classes?: string;
}

const DropZone: FC<DropZoneProps> = ({ onFileSelection, fileType, classes, onChange }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [active, setActive] = useState(false);

    const onDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setActive(true);
    };

    const onDragLeave = () => {
        setActive(false);
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setActive(false);
        if (event.dataTransfer.files.length) onFileSelection(event.dataTransfer.files[0]);
    };

    const handleClick = () => {
        if (inputRef.current) inputRef.current.click();
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        event.preventDefault();
        if (event.target.files && event.target.files.length > 0) onFileSelection(event.target.files[0]);
    };

    return (
        <div
            className={`${styles.dropZone} ${active ? styles.active : ""} ${classes || ""}`}
            onDrop={handleDrop}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onClick={handleClick}
        >
            <div className={styles.content}>
                <Icon icon={{ icon: ["fas", "upload"], type: "FontAwesomeIcon", size: "2x" }} classes={styles.icon} />
                <label>{constants.CHOOSE_YOUR_FILE}</label>
                <input
                    type="file"
                    accept={fileType}
                    ref={inputRef}
                    className={styles.fileInput}
                    onChange={handleChange}
                    value={""}
                />
            </div>
        </div>
    );
};

export default DropZone;
