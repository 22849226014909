import React, { createContext, useReducer, useContext } from "react";
import { Dispatch, State, ProgressBarProviderProps } from "./types";
import progressBarReducer from "./reducer";

const ProgressBarStateContext = createContext<State | undefined>(undefined);
const ProgressBarDispatchContext = createContext<Dispatch | undefined>(undefined);

const ProgressBarProvider = ({ children }: ProgressBarProviderProps) => {
    const [state, dispatch] = useReducer(progressBarReducer, { active: false, progress: 0 });
    return (
        <ProgressBarStateContext.Provider value={state}>
            <ProgressBarDispatchContext.Provider value={dispatch}>{children}</ProgressBarDispatchContext.Provider>
        </ProgressBarStateContext.Provider>
    );
};

const useProgressBarState = () => {
    const context = useContext(ProgressBarStateContext);
    if (context === undefined) {
        throw new Error("useProgressBarState must be used within a ProgressBarProvider");
    }
    return context;
};

const useProgressBarDispatch = () => {
    const context = useContext(ProgressBarDispatchContext);
    if (context === undefined) {
        throw new Error("useProgressBarDispatch must be used within a ProgressBarProvider");
    }
    return context;
};

export { ProgressBarProvider, useProgressBarState, useProgressBarDispatch };
