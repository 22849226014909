import { ReactNode } from "react";

export const UPDATE_DATES = "UPDATE_DATES";
export const UPDATE_TEAMS = "UPDATE_TEAMS";
export const UPDATE_COMPETITIONS = "UPDATE_COMPETITIONS";
export const UPDATE_STATUSES = "UPDATE_STATUSES";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const CLEAR_ALL_BUT_DATE_FILTERS = "CLEAR_ALL_BUT_DATE_FILTERS";
export const UPDATE_DATES_ORDER = "UPDATE_DATES_ORDER";
export const UPDATE_TEAMS_ORDER = "UPDATE_TEAMS_ORDER";
export const UPDATE_COMPETITIONS_ORDER = "UPDATE_COMPETITIONS_ORDER";
export const UPDATE_STATUSES_ORDER = "UPDATE_STATUSES_ORDER";

export type Sort = "ASC" | "DESC";

export interface Item {
    id: string;
    name: string;
}

interface UpdateDatesAction {
    type: typeof UPDATE_DATES;
    minDate: Date | undefined;
    maxDate: Date | undefined;
}

interface UpdateTeamsAction {
    type: typeof UPDATE_TEAMS;
    teams: Item[] | undefined;
}

interface UpdateCompetitionsAction {
    type: typeof UPDATE_COMPETITIONS;
    competitions: Item[] | undefined;
}

interface UpdateStatusesAction {
    type: typeof UPDATE_STATUSES;
    statuses: Item[] | undefined;
}

interface UpdateDatesSort {
    type: typeof UPDATE_DATES_ORDER;
    dateOrder: Sort | undefined;
}

interface UpdateTeamsSort {
    type: typeof UPDATE_TEAMS_ORDER;
    nameOrder: Sort | undefined;
}

interface UpdateCompetitionsSort {
    type: typeof UPDATE_COMPETITIONS_ORDER;
    competitionOrder: Sort | undefined;
}

interface UpdateStatusesSort {
    type: typeof UPDATE_STATUSES_ORDER;
    statusesOrder: Sort | undefined;
}

interface ClearFiltersAction {
    type: typeof CLEAR_FILTERS;
}

interface ClearAllButDateFiltersAction {
    type: typeof CLEAR_ALL_BUT_DATE_FILTERS;
}

export type Action =
    | UpdateDatesAction
    | UpdateTeamsAction
    | UpdateCompetitionsAction
    | UpdateStatusesAction
    | ClearFiltersAction
    | ClearAllButDateFiltersAction
    | UpdateDatesSort
    | UpdateTeamsSort
    | UpdateCompetitionsSort
    | UpdateStatusesSort;
export type Dispatch = (action: Action) => void;
export type State = {
    minDate?: Date;
    maxDate?: Date;
    dateOrder?: Sort;
    nameOrder?: Sort;
    statusesOrder?: Sort;
    teams?: Item[];
    competitions?: Item[];
    competitionOrder?: Sort;
    statuses?: Item[];
    [index: string]: any;
};
export type CompetitionsListFiltersProviderProps = { children: ReactNode };
