import React, {FC, useState, useEffect, FormEvent} from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { remove } from "lodash";
import Title from "../common/title";
import Error from "../common/error";
import Button from "../common/button";
import { can } from "../../store/role";
import { toast } from "react-toastify";
import EditUserRole from "../editUserRole";
import { history } from "../../utils/history";
import { Item } from "../common/autoComplete";
import useUser from "../../utils/hooks/useUser";
import * as roles from "../../store/role/types";
import EditUserCredentials from "../editUserCredentials";
import EditUserPermissions from "../editUserPermissions";
import * as constants from "../../constants/admin.constants";
import useUpdateUser from "../../utils/hooks/useUpdateUser";
import UserFanId from "../userFanId/userFanId";
import styles from "./editUser.module.scss";

interface EditUserParams {
    userId: string;
}

interface User {
    id: number;
    customer: number;
    email: string;
    role: roles.Role;
    permissions: roles.Permissions[];
    hasCommsAccess: boolean;
    fan_id: string;
}

export interface EditUserProps extends RouteComponentProps<EditUserParams> {}

const EditUser: FC<EditUserProps> = ({ match: { params } }) => {
    /* Retrieve the users current username, role and permissions */
    const { user: currentUser } = useUser(Number(params.userId));
    const [user, setUser] = useState<User>();
    const [fanIdErrors, setFanIdErrors] = useState({});
    const [credentialErrors, setCredentialErrors] = useState({});

    /* Update user */
    const { user: updatedUser, errors, updateUser } = useUpdateUser();

    useEffect(() => {
        if (!updatedUser) return;
        toast.success(constants.USER_SUCCESSFULLY_UPDATED);
        history.push("/admin");
    }, [updatedUser]);

    useEffect(() => {
        if (currentUser)
            setUser({
                ...currentUser,
                email: currentUser?.username,
                customer: currentUser.customer_id,
                permissions: currentUser.permissions,
                hasCommsAccess: currentUser.hasCommsAccess,
                fan_id: currentUser.fan_id === null ? "" : currentUser.fan_id.toString()
            });
    }, [currentUser]);

    const setError = (): string => {
        if (errors?.data) {
            return errors?.data?.errors[0];
        }
        return "";
    };
    const handleInputChange = (event: FormEvent<any>) => {
        const target = event.target as HTMLSelectElement;
        if (user) setUser({ ...user, [target.id]: target.value });
    };

    const handleSelectRole = (item: Item | null) => {
        if (user && item?.id) setUser({ ...user, role: item?.id as roles.Role });
    };

    const handleSelectCompetition = (item: Item | null, index: number) => {
        if (!user) return;

        let permissionsClone = [...user.permissions];
        permissionsClone[index].competition_id = (item?.id as string) || "";
        permissionsClone[index].competition_name = item?.name || "";

        setUser({ ...user, permissions: permissionsClone });
    };

    const handleSelectPermission = (item: Item | null, index: number) => {
        if (!user || !item?.id) return;

        let permissionsClone = [...user.permissions];
        permissionsClone[index].permission = item.id as roles.Permission;

        setUser({ ...user, permissions: permissionsClone });
    };

    const handleCommsPermission = (hasCommsAccess: boolean) => {
        if (!user) return;
        setUser({ ...user, hasCommsAccess });
    };

    const handleAddRow = () => {
        if (user)
            setUser({
                ...user,
                permissions: [...user.permissions, { competition_id: "", competition_name: "", permission: ""}]
            });
    };

    const handleSubmit = () => {
        if (!user) return;

        let permissions: roles.SetPermissions[] = [];
        /* Append user Id to the permissions */
        if (can(user.role, roles.setPerCompetitionPermissions)) {
            permissions = user.permissions.map((permission) => {
                return {
                    user_id: user.id,
                    competition_id: permission.competition_id,
                    permission: permission.permission
                };
            });

            /* Remove empty rows */
            remove(permissions, (permission) => !permission.competition_id);
        }

        let fanIdToReturn = user.fan_id === "" || isNaN(Number(user.fan_id)) ? null : Number(user.fan_id);

        updateUser(
            {
                id: user.id,
                username: user.email,
                role: user.role,
                has_comms_access: user.hasCommsAccess,
                fan_id: fanIdToReturn,
            }, permissions.length ? permissions : []);
    };

    const buttonDisabled = Object.keys(credentialErrors).length > 0 || Object.keys(fanIdErrors).length > 0

    return (
        <div className={styles.parentContainer}>
            <Title label={constants.EDIT_USER_TITLE} classes={`page-title ${styles.pageTitle}`}></Title>
            {user && (
                <form className={styles.form}>
                    <EditUserCredentials
                        credentials={{ email: user.email }}
                        onChange={handleInputChange}
                        credentialErrors={credentialErrors}
                        setCredentialErrors={setCredentialErrors}
                    ></EditUserCredentials>
                    <EditUserRole
                        role={user.role}
                        hasCommsAccess={user.hasCommsAccess}
                        onSelect={handleSelectRole}
                        onChangeCommsAccess={handleCommsPermission}
                    ></EditUserRole>
                    <UserFanId
                        fanId={user.fan_id}
                        onChange={handleInputChange}
                        fanIdErrors={fanIdErrors}
                        setFanIdErrors={setFanIdErrors}
                    ></UserFanId>
                    <EditUserPermissions
                        permissions={user.permissions}
                        role={user.role}
                        onAddRow={handleAddRow}
                        onSelectCompetition={handleSelectCompetition}
                        onSelectPermission={handleSelectPermission}
                    ></EditUserPermissions>
                    <Error error={setError()} classes={styles.error}></Error>
                </form>
            )}
            <div className={styles.updateButton}>
                <Button
                    name={constants.UPDATE_USER}
                    tooltip={constants.UPDATE_USER}
                    classes={"btn-outline-green m-0"}
                    rippleColor={"black"}
                    onClick={handleSubmit}
                    disabled={buttonDisabled}
                ></Button>
            </div>
        </div>
    );
};

export default withRouter(EditUser);
