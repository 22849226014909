import http from "./http.services";
import { User, LogOut } from "../store/auth/types";
import { UserDetails } from "../utils/hooks/useRegister";
import * as constants from "../constants/resources.constants";
import { User as UserRegister } from "../store/register/types";
import { UserDetails as UpdateUserDetails } from "../utils/hooks/useUpdateUser";
import { SetPassword, SetPasswordDetails } from "../utils/hooks/useSetPassword";
import { ForgotPassword, ForgotPasswordDetails } from "../utils/hooks/useForgotPassword";

const login = async (username: string, password: string): Promise<User> => {
    const { data: user } = await http.post(
        constants.LOGIN,
        {
            username,
            password
        },
        {
            withCredentials: true
        }
    );
    return user;
};

const logout = async (): Promise<LogOut> => {
    const { data: response } = await http.post(
        constants.LOG_OUT,
        {},
        {
            withCredentials: true
        }
    );
    return response;
};

const renew = async (): Promise<User> => {
    const { data: user } = await http.post(
        constants.RENEW,
        {},
        {
            withCredentials: true
        }
    );
    return user;
};

const getUserToken = async (): Promise<User> => {
    const { data: user } = await http.get(constants.VALIDATE_TOKEN, {
        withCredentials: true
    });
    return user;
};

const register = async (userDetails: UserDetails): Promise<UserRegister> => {
    const { data: user } = await http.post(
        constants.REGISTER,
        {
            ...userDetails
        },
        {
            withCredentials: true
        }
    );
    return user;
};

const getUser = async (userId: string): Promise<UserRegister> => {
    const { data: user } = await http.get(constants.USER.replace("{id}", userId), {
        withCredentials: true
    });
    return user;
};

const updateUser = async (userDetails: UpdateUserDetails): Promise<UserRegister> => {
    const { data: user } = await http.patch(
        constants.USERS,
        {
            ...userDetails
        },
        {
            withCredentials: true
        }
    );
    return user;
};

const deleteUser = async (userId: string): Promise<object> => {
    const { data: response } = await http.delete(constants.USER.replace("{id}", userId), { withCredentials: true });
    return response;
};

const setPassword = async (setPasswordDetails: SetPasswordDetails): Promise<SetPassword> => {
    const { data: response } = await http.post(
        constants.SET_PASSWORD,
        {
            ...setPasswordDetails
        },
        {
            withCredentials: true
        }
    );
    return response;
};

const forgotPassword = async (forgotPasswordDetails: ForgotPasswordDetails): Promise<ForgotPassword> => {
    const { data: response } = await http.post(constants.FORGOT_PASSWORD, {
        ...forgotPasswordDetails
    });
    return response;
};

export const authService = {
    login,
    logout,
    renew,
    getUserToken,
    register,
    getUser,
    updateUser,
    deleteUser,
    setPassword,
    forgotPassword
};
