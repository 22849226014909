import React, { FC } from "react";
import { startCase } from "lodash";
import { FixtureStatus as FixtureStatusType } from "../../utils/hooks/useFixtures";

import styles from "./fixtureState.module.scss";

export interface FixtureStateProps {
    state: FixtureStatusType;
    endAdornment?: string;
    classes?: string;
}

const FixtureState: FC<FixtureStateProps> = ({ state, endAdornment, classes }) => {
    const fixtureStateClasses = `${styles.fixtureState} ${classes || ""}`;

    return (
        <div className={fixtureStateClasses} title={state.toLowerCase()}>
            <div className={`${styles.icon} ${styles[state.toLocaleLowerCase()]}`}></div>
            <div className={styles.label}>{startCase(state.toLowerCase())}</div>
            <div className={styles.endAdornment}> {endAdornment}</div>
        </div>
    );
};

export default FixtureState;
