import http from "./http.services";
import * as constants from "../constants/resources.constants";
import { fixture as fixtureType } from "../utils/hooks/useFixture";
import { fixtures as fixturesType } from "../utils/hooks/useFixtures";
import { Angles as AnglesType } from "../utils/hooks/useFixtureAngles";
import { fixture as fixtureFootageType } from "../utils/hooks/useFixtureFootage";
import { Incident as IncidentFootageType } from "../utils/hooks/useFixtureIncidents";

const getFixtures = async (pageNumber: number = 1, pageSize = 20, filters?: object): Promise<fixturesType> => {
    const { data: fixture } = await http.get(constants.FIXTURES, {
        withCredentials: true,
        params: {
            page_number: pageNumber,
            page_size: pageSize,
            ...filters
        }
    });
    return fixture;
};

const getFixture = async (fixtureId: string): Promise<fixtureType> => {
    const { data: fixture } = await http.get(constants.FIXTURE.replace("{id}", fixtureId), {
        withCredentials: true
    });
    return fixture;
};

const getFixtureIncidents = async (fixtureId: string): Promise<IncidentFootageType> => {
    const { data: fixture } = await http.get(constants.FIXTURE_INCIDENTS.replace("{id}", fixtureId), {
        withCredentials: true
    });
    return fixture;
};

const getFixtureFootage = async (fixtureId: string): Promise<fixtureFootageType> => {
    const { data: fixture } = await http.get(constants.FIXTURE_FOOTAGE.replace("{id}", fixtureId), {
        withCredentials: true
    });
    return fixture;
};

const getFixtureAngles = async (fixtureId: string): Promise<AnglesType[]> => {
    const { data: angles } = await http.get(constants.FIXTURE_ANGLES.replace("{id}", fixtureId), {
        withCredentials: true
    });
    return angles;
};

const setNotExpected = async (fixtureId: string) => {
    const { data: response } = await http.put(
        constants.FIXTURE_NOT_EXPECTED.replace("{id}", fixtureId),
        {},
        {
            withCredentials: true
        }
    );
    return response;
};

const setExpected = async (fixtureId: string) => {
    const { data: response } = await http.put(
        constants.FIXTURE_EXPECTED.replace("{id}", fixtureId),
        {},
        {
            withCredentials: true
        }
    );
    return response;
};

export const fixturesService = {
    getFixtures,
    getFixture,
    getFixtureIncidents,
    getFixtureFootage,
    getFixtureAngles,
    setNotExpected,
    setExpected
};
