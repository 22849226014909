import { useCallback } from "react";
import { useProgressBarDispatch, useProgressBarState } from "./context";
import progressBarReducer from "./reducer";
import * as types from "./types";

const useProgressBar = ({ reducer = progressBarReducer } = {}) => {
    const dispatch = useProgressBarDispatch();
    const { active, progress } = useProgressBarState();

    const setActive = useCallback(
        (active: boolean) => {
            dispatch({ type: types.SET_ACTIVE, active: active });
        },
        [dispatch]
    );

    const setProgress = (progress: number) => {
        dispatch({ type: types.SET_PROGRESS, progress: progress });
    };

    return { active, progress, setActive, setProgress };
};

export { useProgressBar };
